import Pricing from './Pricing/Pricing';
import Hero from './Hero/Hero';
import Empower from './Empower/Empower';
import Services from './Services/Services';
import Sensi from './Sensi/Sensi';
import CTA from './CTA/CTA';
import Footer from './Footer/Footer';

export {
    CTA,
    Pricing,
    Hero,
    Empower,
    Services,
    Sensi,
    Footer
}