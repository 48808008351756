import {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { useAuth } from "./AuthContext";
import io from "socket.io-client";

const SocketContext = createContext();

export function useSocket() {
  return useContext(SocketContext);
}

export function SocketIoProvider({ children }) {
  const { currentUser } = useAuth();
  const uid = currentUser.uid;
  const socketRef = useRef();

  if (!socketRef.current) {
    socketRef.current = io("https://sensi-api.siftlink.com", {
      auth: {
        uid: uid,
      },
      // forceNew: true,
      transports: ["websocket"],
      secure: true,
    });
  }

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const value = useMemo(() => ({ socket: socketRef.current, uid }), [uid]);

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
}
// import { createContext, useContext, useEffect, useState } from "react";
// import { useAuth } from "./AuthContext";
// import io from "socket.io-client";

// const socketContext = createContext();

// export function useSocket() {
//   return useContext(socketContext);
// }

// export function SocketIoProvider({ children }) {
//   const { currentUser } = useAuth();
//   const uid = currentUser.uid;

//   const socket = io("https://sensi-api.siftlink.com", {
//     auth: {
//       uid: uid,
//     },
//     forceNew: true,
//     transports: ["polling"],
//     secure: true,
//   });

//   return (
//     <socketContext.Provider value={{ socket, uid }}>
//       {children}
//     </socketContext.Provider>
//   );
// }
