import { Container } from "react-bootstrap";

function Survey() {
  return (
    <Container style={{ marginTop: "1rem" }}>
      <div style={{ height: "92vh" }}>
        <iframe
          style={{ width: "100%", height: "100%" }}
          src="https://forms.office.com/e/hDCvy0dg14"
          title="feedback"
        />
      </div>
    </Container>
  );
}

export default Survey;
