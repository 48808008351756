// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "footer {\n  background-color: #322b2b;\n}\n\n.Footer_logo-siftlink__3Pdlc > img,\nfooter img {\n  width: 240px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,YAAY;AACd","sourcesContent":["footer {\n  background-color: #322b2b;\n}\n\n.logo-siftlink > img,\nfooter img {\n  width: 240px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo-siftlink": "Footer_logo-siftlink__3Pdlc"
};
export default ___CSS_LOADER_EXPORT___;
