import { Container } from "react-bootstrap";

function PrivacyPolicy() {
  return (
    <Container>
      <h1 className="text-center p-4">PRIVACY POLICY</h1>
      <h6 className="text-center">Last updated: Monday, January 30, 2021</h6>
      <h2 className="text-center p-4">TABLE OF CONTENTS</h2>
      <ol style={{ margin: "2rem 0" }}>
        <li>
          <a href="#introduction">INTRODUCTION</a>
        </li>
        <li>
          <a href="#acceptance">DEFINITIONS</a>
        </li>
        <li>
          <a href="#the-service">NAME AND ADDRESS OF THE CONTROLLER</a>
        </li>
        <li>
          <a href="#login-information-access">
            COLLECTION OF GENERAL DATA AND INFORMATION
          </a>
        </li>
        <li>
          <a href="#end-users">CONTACT POSSIBILITY VIA THE PLATFORM</a>
        </li>
        <li>
          <a href="#eligibility">CCPA PROVISO</a>
        </li>
        <li>
          <a href="#data-collection">SECURITY</a>
        </li>
        <li>
          <a href="#term">ROUTINE ERASURE AND BLOCKING OF PERSONAL DATA</a>
        </li>
        <li>
          <a href="#account-registration">RIGHTS OF THE DATA SUBJECT</a>
        </li>
        <li>
          <a href="#subscription-terms">LEGAL BASIS FOR THE PROCESSING</a>
        </li>
        <li>
          <a href="#subscription-authorization">
            THE LEGITIMATE INTERESTS PURSUED BY THE CONTROLLER OR BY A THIRD
            PARTY
          </a>
        </li>
        <li>
          <a href="#acceptable-use-policy">
            PERIOD FOR WHICH THE PERSONAL DATA WILL BE STORED
          </a>
        </li>
        <li>
          <a href="#interactive-services">
            PROVISION OF PERSONAL DATA AS STATUTORY OR CONTRACTUAL REQUIREMENT;
            REQUIREMENT NECESSARY TO ENTER INTO A CONTRACT; OBLIGATION OF THE
            DATA SUBJECT TO PROVIDE THE PERSONAL DATA; POSSIBLE CONSEQUENCES OF
            FAILURE TO PROVIDE SUCH DATA
          </a>
        </li>
      </ol>
      <div id="introduction">
        <h3>1 INTRODUCTION</h3>
        <p>
          We are delighted that you have shown interest in the services of
          Siftlink SA (hereinafter referred to as “Siftlink”, “we”, “us”, “our”)
          which are provided via our software, APIs, or otherwise, and mobile
          website and mobile applications related, linked, or otherwise
          connected thereto (collectively the “Platform”). Data protection is a
          particularly high priority for Siftlink. The use of the Platform of
          Siftlink is possible without any indication of personal data; however,
          if a data subject wants to use special services via our Platform,
          processing of personal data may become necessary. If the processing of
          personal data is necessary, and there is no statutory basis for such
          processing, we generally obtain consent from the data subject. The
          processing of personal data, such as the name, address, email address,
          or telephone number of a data subject shall always be in line with the
          General Data Protection Regulation (GDPR), and in accordance with the
          country-specific data protection regulations applicable to Siftlink.
          Utilizing this data protection declaration, our enterprise would like
          to inform the general public of the nature, scope, and purpose of the
          personal data we collect, use and process. Furthermore, data subjects
          are informed through this data protection declaration, of the rights
          to which they are entitled. As the controller, Siftlink has
          implemented numerous technical and organizational measures to ensure
          the complete protection of personal data processed through this
          Platform. However, Internet-based data transmissions may, in
          principle, have security gaps, so absolute protection may not be
          guaranteed. For this reason, every data subject is free to transfer
          personal data to us via alternative means, e.g. by telephone.
        </p>
      </div>
      <div id="acceptance">
        <h3>2 DEFINITIONS</h3>
        <p>
          The data protection declaration of Siftlink is based on the terms used
          by the European legislator for the adoption of the General Data
          Protection Regulation (GDPR). Our data protection declaration should
          be legible and understandable for the general public, as well as our
          customers and business partners. To ensure this, we would like to
          first explain the terminology used. In this data protection
          declaration, we use, among other things, the following terms:
          <ol type="A">
            <li>
              Personal data means any information relating to an identified or
              identifiable natural person (“data subject”). An identifiable
              natural person is one who can be identified, directly or
              indirectly, in particular by reference to an identifier such as a
              name, an identification number, location data, an online
              identifier or to one or more factors specific to the physical,
              physiological, genetic, mental, economic, cultural or social
              identity of that natural person.
            </li>
            <li>
              Data subject is any identified or identifiable natural person,
              whose personal data is processed by the controller responsible for
              the processing.
            </li>
            <li>
              Processing is any operation or set of operations which is
              performed on personal data or on sets of personal data, whether or
              not by automated means, such as collection, recording,
              organization, structuring, storage, adaptation or alteration,
              retrieval, consultation, use, disclosure by transmission,
              dissemination or otherwise making available, alignment or
              combination, restriction, erasure or destruction.
            </li>
            <li>
              Restriction of processing is the marking of stored personal data
              with the aim of limiting their processing in the future.
            </li>
            <li>
              Profiling means any form of automated processing of personal data
              consisting of the use of personal data to evaluate certain
              personal aspects relating to a natural person, in particular to
              analyze or predict aspects concerning that natural person’s
              performance at work, economic situation, health, personal
              preferences, interests, reliability, behavior, location or
              movements.
            </li>
            <li>
              Pseudonymization is the processing of personal data in such a
              manner that the personal data can no longer be attributed to a
              specific data subject without the use of additional information,
              provided that such additional information is kept separately and
              is subject to technical and organizational measures to ensure that
              the personal data are not attributed to an identified or
              identifiable natural person.
            </li>
            <li>
              Controller or controller responsible for the processing is the
              natural or legal person, public authority, agency or other body
              which, alone or jointly with others, determines the purposes and
              means of the processing of personal data; where the purposes and
              means of such processing are determined by Union or Member State
              law, the controller or the specific criteria for its nomination
              may be provided for by Union or Member State law.
            </li>
            <li>
              Processor is a natural or legal person, public authority, agency,
              or other body which processes personal data on behalf of the
              controller.
            </li>
            <li>
              Recipient is a natural or legal person, public authority, agency,
              or another body, to which the personal data are disclosed, whether
              a third party or not. However, public authorities which may
              receive personal data in the framework of a particular inquiry in
              accordance with Union or Member State law shall not be regarded as
              recipients; the processing of those data by those public
              authorities shall be in compliance with the applicable data
              protection rules according to the purposes of the processing.
            </li>
            <li>
              Third-party is a natural or legal person, public authority,
              agency, or body other than the data subject, controller,
              processor, and persons who, under the direct authority of the
              controller or processor, are authorized to process personal data.
            </li>
            <li>
              Consent of the data subject is any freely given, specific,
              informed and unambiguous indication of the data subject’s wishes
              by which he or she, by a statement or by a clear affirmative
              action, signifies agreement to the processing of personal data
              relating to him or her.
            </li>
          </ol>
        </p>
      </div>
      <div id="the-service">
        <h3>3 NAME AND ADDRESS OF THE CONTROLLER</h3>
        <p>
          Controller for the General Data Protection Regulation (GDPR), other
          data protection laws applicable in Member states of the European Union
          and other provisions related to data protection is: <br />
          Siftlink SA Biopôle, Phenyl Building
          <br /> Route de la Corniche 3<br /> 1066 Epalinges
          <br />
          Switzerland
          <br />
          <a href="mailto:info@siftlink.com">info@siftlink.com</a>
        </p>
      </div>
      <div id="login-information-access">
        <h3>4 COLLECTION OF GENERAL DATA AND INFORMATION</h3>
        <p>
          The Platform of Siftlink collects a series of general data and
          information when a data subject or automated system calls up the
          Platform. This general data and information are stored in the server
          log files. The general data collected may be (1) the browser types and
          versions used, (2) the operating system used by the accessing system,
          (3) the website from which an accessing system reaches our Platform
          (so-called referrers), (4) the sub-websites, (5) the date and time of
          access to the Platform, (6) an Internet protocol address (IP address),
          (7) the Internet service provider of the accessing system, (8) names,
          (9) email address, (10) Payment method details such as credit card
          details and, (11) any other similar data and information that may be
          used in the event of attacks on our information technology systems.
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information. When using these general data and information, Siftlink
          does not draw any conclusions about the data subject. Rather, this
          information is needed to (1) deliver the content of our Platform
          correctly, (2) optimize the content of our Platform as well as its
          advertisement, (3) ensure the long-term viability of our information
          technology systems and website technology, (4) for analytics purposes,
          (5) to facilitate account creation and logon process, (6) to send
          automatic notifications (7) to request feedback (8) as necessary for
          basic functionality of the Platform and account management (billing
          our clients) and, (9) provide law enforcement authorities with the
          information necessary for criminal prosecution in case of a
          cyber-attack. Therefore, Siftlink analyses anonymously collected data
          and information statistically, with the aim of increasing the data
          protection and data security of our enterprise and to achieve an
          optimal level of protection for the personal data we process. The
          anonymous data of the server log files are stored separately from all
          personal data provided by a data subject.
        </p>
      </div>
      <div id="end-users">
        <h3>5 CONTACT POSSIBILITY VIA THE PLATFORM</h3>
        <p>
          The Platform of Siftlink contains information that enables a quick
          electronic contact to our enterprise, as well as direct communication
          with us, which also includes a general address of the so-called
          electronic mail (email address). If a data subject contacts the
          controller by email or via a contact form, the personal data
          transmitted by the data subject are automatically stored. Such
          personal data transmitted on a voluntary basis by a data subject to
          the data controller are stored for the purpose of processing or
          contacting the data subject. There is no transfer of this personal
          data to third parties.
        </p>
      </div>
      <div id="eligibility">
        <h3>6 CCPA PROVISO</h3>
        <p>
          The California Code of Regulations defines a "resident" as:
          <p>
            6.1 every individual who is in the State of California for other
            than a temporary or transitory purpose and
          </p>
          <p>
            6.2 every individual who is domiciled in the State of California who
            is outside the State of California for a temporary or transitory
            purpose All other individuals are defined as "non-residents." If
            this definition of "resident" applies to you, certain rights and
            obligations apply regarding your personal information. if you are a
            resident of California, you are granted specific rights regarding
            access to your personal information.
            <b>Your California Privacy Rights</b> If you are a California
            resident, you have the following rights specifically under the
            California Consumer Privacy Act: <b>Right to know.</b> You have the
            right to know and see what data we have collected about you over the
            past 12 months, including: (1) The categories of personal
            information we have collected about you; (2) The categories of
            sources from which the personal information is collected; (3) The
            business or commercial purpose for collecting your personal
            information; (4) The categories of third parties with whom we have
            shared your personal information; and (5) The specific pieces of
            personal information we have collected about you.{" "}
            <b>Right to Delete.</b> You have the right to request that we delete
            the personal information we have collected from you (and direct our
            service providers to do the same). If you are under 18 years of age,
            reside in California, and have a registered account with the
            Platform, you have the right to request removal of unwanted data
            that you publicly post on the Platform. To request removal of such
            data, please contact us using the contact information provided
            below, and include the email address associated with your account
            and a statement that you reside in California. We will make sure the
            data is not publicly displayed on the Platform, but please be aware
            that the data may not be completely or comprehensively removed from
            all our systems (e.g. backups, etc.). <b>Other Rights</b> California
            Civil Code Section 1798.83, also known as the “Shine The Light” law,
            permits our users who are California residents to request and obtain
            from us, once a year and free of charge, information about
            categories of personal information (if any) we disclosed to third
            parties for direct marketing purposes and the names and addresses of
            all third parties with which we shared personal information in the
            immediately preceding calendar year. If you are a California
            resident and would like to make such a request, please submit your
            request in writing to us using the contact information provided in
            this document. Certain information may be exempt from such requests
            under applicable law. For example, we need certain types of
            information so that we can provide the Platform to you. If you ask
            us to delete it, you may no longer be able to access or use the
            Platform. To exercise your rights under the California Consumer
            Privacy Act, please contact us by either sending us an e-mail at
            info@siftlink.com. Please provide your full name and e-mail address
            so that we may respond to your request as quickly as possible. You
            may be required to verify your identify before we fulfill your
            request. To do so, you will need to provide us with certain account
            information, such as your full name and email address. You can also
            designate an authorized agent to make a request on your behalf. To
            do so, you must provide us with written authorization for the agent
            to act on your behalf. You will still need to verify your identity
            directly with us.
          </p>
        </p>
      </div>
      <div id="data-collection">
        <h3>7 SECURITY</h3>
        <p>
          This Platform ensures that data is encrypted when leaving the
          Platform. This process involves the converting of information or data
          into a code to prevent unauthorised access. This Platform follows this
          process and employs secure methods to ensure the protection of all
          credit and debit card transactions. Encryption methods such as SSL are
          utilised to protect customer data when in transit to and from this
          Platform over a secure communications channel. Whilst we do everything
          within our power to ensure that personal data is protected at all
          times from our Platform, we cannot guarantee the security and
          integrity of the information that has been transmitted to our
          Platform.
        </p>
      </div>
      <div id="term">
        <h3>8 ROUTINE ERASURE AND BLOCKING OF PERSONAL DATA</h3>
        <p>
          The data controller shall process and store the personal data of the
          data subject only for the period necessary to achieve the purpose of
          storage, or as far as this is granted by the European legislator or
          other legislators in laws or regulations to which the controller is
          subject. If the storage purpose is not applicable, or if a storage
          period prescribed by the European legislator or another competent
          legislator expires, the personal data are routinely blocked or erased
          in accordance with legal requirements.
        </p>
      </div>
      <div id="account-registration">
        <h3>9 RIGHTS OF THE DATA SUBJECT</h3>
        <ol type="A">
          <li>
            Right of confirmation. Each data subject shall have the right
            granted by the European legislator to obtain from the controller the
            confirmation as to whether or not personal data concerning him or
            her are being processed. If a data subject wishes to avail himself
            of this right of confirmation, he or she may, at any time, contact
            us or another employee of the controller.
          </li>
          <li>
            Right of access. Each data subject shall have the right granted by
            the European legislator to obtain from the controller free
            information about his or her personal data stored at any time and a
            copy of this information. Furthermore, the European directives and
            regulations grant the data subject access to the following
            information: the purposes of the processing; the categories of
            personal data concerned; the recipients or categories of recipients
            to whom the personal data have been or will be disclosed, in
            particular recipients in third countries or international
            organizations; where possible, the envisaged period for which the
            personal data will be stored, or, if not possible, the criteria used
            to determine that period; the existence of the right to request from
            the controller rectification or erasure of personal data, or
            restriction of processing of personal data concerning the data
            subject, or to object to such processing; the existence of the right
            to file a complaint with a supervisory authority; where the personal
            data are not collected from the data subject, any available
            information as to their source; the existence of automated
            decision-making, including profiling, referred to in Article 22(1)
            and (4) of the GDPR and, at least in those cases, meaningful
            information about the logic involved, as well as the significance
            and envisaged consequences of such processing for the data subject.
            Furthermore, the data subject shall have a right to obtain
            information as to whether personal data are transferred to a third
            country or an international organization. Where this is the case,
            the data subject shall have the right to be informed of the
            appropriate safeguards relating to the transfer. If a data subject
            wishes to avail himself of this right of access, he or she may at
            any time contact our us or another employee of the controller.
          </li>
          <li>
            Right to rectification. Each data subject shall have the right
            granted by the European legislator to obtain from the controller
            without undue delay the rectification of inaccurate personal data
            concerning him or her. Taking into account the purposes of the
            processing, the data subject shall have the right to have incomplete
            personal data completed, including by means of providing a
            supplementary statement. If a data subject wishes to exercise this
            right to rectification, he or she may, at any time, contact us or
            another employee of the controller.
          </li>
          <li>
            Right to erasure (Right to be forgotten). Each data subject shall
            have the right granted by the European legislator to obtain from the
            controller the erasure of personal data concerning him or her
            without undue delay, and the controller shall have the obligation to
            erase personal data without undue delay where one of the following
            grounds applies, as long as the processing is not necessary: The
            personal data is no longer necessary in relation to the purposes for
            which they were collected or otherwise processed. The data subject
            withdraws consent to which the processing is based according to
            point (a) of Article 6(1) of the GDPR, or point (a) of Article 9(2)
            of the GDPR, and where there is no other legal ground for the
            processing. The data subject objects to the processing in accordance
            with Article 21(1) of the GDPR, and there are no overriding
            legitimate grounds for the processing, or the data subject objects
            to the processing pursuant to Article 21(2) of the GDPR. The
            personal data have been unlawfully processed. The personal data must
            be erased for compliance with a legal obligation in Union or Member
            State law to which the controller is subject. The personal data have
            been collected in relation to the offer of information society
            services referred to in Article 8(1) of the GDPR. If one of the
            aforementioned reasons applies, and a data subject wishes to request
            the erasure of personal data stored by Siftlink, he or she may at
            any time contact our us or another employee of the controller.
            Siftlink or another employee shall promptly ensure that the erasure
            request is complied with immediately. Where the controller has made
            personal data public and is obliged pursuant to Article 17(1) to
            erase the personal data, the controller, taking account of available
            technology and the cost of implementation, shall take reasonable
            steps, including technical measures, to inform other controllers
            processing the personal data that the data subject has requested
            erasure by such controllers of any links to, or copy or replication
            of, those personal data, as far as processing is not required.
            Siftlink or another employee will arrange the necessary measures in
            individual cases.
          </li>
          <li>
            Right of restriction of processing. Each data subject shall have the
            right granted by the European legislator to obtain from the
            controller restriction of processing where one of the following
            applies: The accuracy of the personal data is contested by the data
            subject, for a period enabling the controller to verify the accuracy
            of the personal data. The processing is unlawful, and the data
            subject opposes the erasure of the personal data and requests the
            restriction of their use instead. The controller no longer needs the
            personal data for the processing, but they are required by the data
            subject for the establishment, exercise or Defense of legal claims.
            The data subject has objected to processing pursuant to Article
            21(1) of the GDPR pending the verification whether the legitimate
            grounds of the controller override those of the data subject. If one
            of the conditions as mentioned above is met, and a data subject
            wishes to request the restriction of the processing of personal data
            stored by Siftlink, he or she may at any time contact us or another
            employee of the controller. Siftlink or another employee will
            arrange the restriction of the processing.
          </li>
          <li>
            Right to data portability. Each data subject shall have the right
            granted by the European legislator, to receive the personal data
            concerning him or her, which was provided to a controller, in a
            structured, commonly used, and machine-readable format. He or she
            shall have the right to transmit those data to another controller
            without hindrance from the controller to which the personal data
            have been provided. As long as the processing is based on consent
            pursuant to point (a) of Article 6(1) of the GDPR or point (a) of
            Article 9(2) of the GDPR, or on a contract pursuant to point (b) of
            Article 6(1) of the GDPR, and the processing is carried out by
            automated means, as long as the processing is not necessary for the
            performance of a task carried out in the public interest or in the
            exercise of official authority vested in the controller.
            Furthermore, in exercising his or her right to data portability
            pursuant to Article 20(1) of the GDPR, the data subject shall have
            the right to have personal data transmitted directly from one
            controller to another, where technically feasible and when doing so
            does not adversely affect the rights and freedoms of others. In
            order to assert the right to data portability, the data subject may
            at any time contact Siftlink or another employee.
          </li>
          <li>
            Right to object. Each data subject shall have the right granted by
            the European legislator to object, on grounds relating to his or her
            particular situation, at any time, to processing of personal data
            concerning him or her, which is based on point (e) or (f) of Article
            6(1) of the GDPR. This also applies to profiling based on these
            provisions. Siftlink shall no longer process the personal data in
            the event of the objection, unless we can demonstrate compelling
            legitimate grounds for the objection, unless we can demonstrate
            compelling legitimate grounds for the processing which override the
            interests, rights and freedoms of the data subject, or for the
            establishment, exercise or Defense of legal claims. If Siftlink
            processes personal data for direct marketing purposes, the data
            subject shall have the right to object at any time to the processing
            of personal data concerning him or her for such marketing. This
            applies to profiling to the extent that it is related to such direct
            marketing. If the data subject objects to Siftlink to the processing
            for direct marketing purposes, Siftlink will no longer process the
            personal data for these purposes. In addition, the data subject has
            the right, on grounds relating to his or her particular situation,
            to object to processing of personal data concerning him or her by
            Siftlink for scientific or historical research purposes, or for
            statistical purposes pursuant to Article 89(1) of the GDPR, unless
            the processing is necessary for the performance of a task carried
            out for reasons of public interest. In order to exercise the right
            to object, the data subject may directly contact Siftlink or another
            employee. In addition, the data subject is free in the context of
            the use of information society services, and notwithstanding
            Directive 2002/58/EC, to use his or her right to object by automated
            means using technical specifications.
          </li>
          <li>
            Automated individual decision-making, including profiling. Each data
            subject shall have the right granted by the European legislator not
            to be subject to a decision based solely on automated processing,
            including profiling, which produces legal effects concerning him or
            her, or similarly significantly affects him or her, as long as the
            decision (1) is not is necessary for entering into, or the
            performance of, a contract between the data subject and a data
            controller, or (2) is not authorized by Union or Member State law to
            which the controller is subject and which also lays down suitable
            measures to safeguard the data subject’s rights and freedoms and
            legitimate interests, or (3) is not based on the data subject’s
            explicit consent. If the decision (1) is necessary for entering
            into, or the performance of, a contract between the data subject and
            a data controller, or (2) it is based on the data subject’s explicit
            consent, Siftlink shall implement suitable measures to safeguard the
            data subject’s rights and freedoms and legitimate interests, at
            least the right to obtain human intervention on the part of the
            controller, to express his or her point of view and contest the
            decision. If the data subject wishes to exercise the rights
            concerning automated individual decision-making, he or she may at
            any time directly contact Siftlink or another employee of the
            controller.
          </li>
          <li>
            Right to withdraw data protection consent. Each data subject shall
            have the right granted by the European legislator to withdraw his or
            her consent to the processing of his or her personal data at any
            time. If the data subject wishes to exercise the right to withdraw
            the consent, he or she may at any time directly contact Siftlink or
            another employee of the controller.
          </li>
        </ol>
      </div>
      <div id="subscription-terms">
        <h3>10 LEGAL BASIS FOR THE PROCESSING</h3>
        <p>
          Art. 6(1) lit. a GDPR serves as the legal basis for processing
          operations for which we obtain consent for a specific processing
          purpose. If the processing of personal data is necessary for the
          performance of a contract to which the data subject is party, as is
          the case, for example, when processing operations are necessary for
          the supply of goods or to provide any other service, the processing is
          based on Article 6(1) lit. b GDPR. The same applies to such processing
          operations which are necessary for carrying out pre-contractual
          measures, for example, in the case of inquiries concerning our
          services. If we are subject to a legal obligation by which processing
          of personal data is required, such as for the fulfilment of tax
          obligations, the processing is based on Art. 6(1) lit. c GDPR. In rare
          cases, the processing of personal data may be necessary to protect the
          vital interests of the data subject or another natural person. Then
          the processing would be based on Art. 6(1) lit. d GDPR. Finally,
          processing operations could be based on Article 6(1) lit. f GDPR. This
          legal basis is used for processing operations which are not covered by
          any of the abovementioned legal grounds, if the processing is
          necessary for the purposes of the legitimate interests pursued by us
          or by a third party, except where such interests are overridden by the
          interests or fundamental rights and freedoms of the data subject which
          require protection of personal data. Such processing operations are
          particularly permissible because they have been specifically mentioned
          by the European legislator. He considered that a legitimate interest
          could be assumed if the data subject is a client of the controller
          (Recital 47 Sentence 2 GDPR).
        </p>
      </div>
      <div id="subscription-authorization">
        <h3>
          11 THE LEGITIMATE INTERESTS PURSUED BY THE CONTROLLER OR BY A THIRD
          PARTY
        </h3>
        <p>
          Where the processing of personal data is based on Article 6(1) lit. f
          GDPR our legitimate interest is to carry out our business in favor of
          the well-being of all our employees and the shareholders.
        </p>
      </div>
      <div id="acceptable-use-policy">
        <h3>12 PERIOD FOR WHICH THE PERSONAL DATA WILL BE STORED</h3>
        <p>
          The criteria used to determine the period of storage of personal data
          is the respective statutory retention period. After expiration of that
          period, the corresponding data is routinely deleted, as long as it is
          no longer necessary for the fulfilment of the contract or the
          initiation of a contract. If the data subject requests for termination
          or terminates his Siftlink Account, the personal data shall be
          retained for a period of 1 year from date of termination.
        </p>
      </div>
      <div id="interactive-services">
        <h3>
          13 PROVISION OF PERSONAL DATA AS STATUTORY OR CONTRACTUAL REQUIREMENT;
          REQUIREMENT NECESSARY TO ENTER INTO A CONTRACT; OBLIGATION OF THE DATA
          SUBJECT TO PROVIDE THE PERSONAL DATA; POSSIBLE CONSEQUENCES OF FAILURE
          TO PROVIDE SUCH DATA
        </h3>
        <p>
          We clarify that the provision of personal data is partly required by
          law (e.g. tax regulations) or can also result from contractual
          provisions (e.g. information on the contractual partner). Sometimes it
          may be necessary to conclude a contract that the data subject provides
          us with personal data, which must subsequently be processed by us. The
          data subject is, for example, obliged to provide us with personal data
          when we sign a contract with him or her. The non-provision of the
          personal data would have the consequence that the contract with the
          data subject could not be concluded. Before personal data is provided
          by the data subject, the data subject must contact us. We will clarify
          to the data subject whether the provision of the personal data is
          required by law, contract or is necessary for the conclusion of the
          contract, whether there is an obligation to provide the personal data
          and the consequences of non-provision of the personal data.
        </p>
      </div>
    </Container>
  );
}

export default PrivacyPolicy;
