import { Fragment } from "react";
import { motion } from "framer-motion";
import styles from "./LandingPage.module.css";

function LandingPage() {
  let h3Animation = {
    offscreen: { y: -70, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
      },
    },
  };

  let titleCardAnimation = {
    offscreen: { y: 50, opacity: 0 },
    onscreen: { y: 0, opacity: 1, transition: { duration: 0.6 } },
  };
  return (
    <Fragment>
      <h1 className="p-4 text-center">
        Welcome to Sensi, your personal virtual assistant
      </h1>
      <blockquote className="blockquote text-center">
        {/* <div className="d-flex flex-column">
          <div style={{margin: "1rem"}}>
            <img style={{height: "12rem"}} src="/static/img/sensibot-round.png" />
          </div>
          <p style={{padding: "2rem"}}>
            Sensi is a conversational AI assistant that saves time for you and
            your team by breaking down long and complex business workflows into
            quick and simple discussions.
          </p>
        </div> */}
        <div className="row m-5">
          <div className="col-lg-3 mb-5">
            <img
              style={{ height: "12rem" }}
              src="/static/img/sensibot-round.png"
            />
          </div>
          <p
            style={{ fontSize: "1.8rem", marginTop: "1rem" }}
            className="col-lg-9 text-center"
          >
            Sensi integrates AI with human expertise to boost productivity,
            employee satisfaction and engagement while accelerating business
            processes.
          </p>
        </div>
      </blockquote>
      <div className="row justify-content-center">
        <div className="col-sm-12 col-lg-4">
          <div style={{ textAlign: "center" }}>
            <i
              style={{ fontSize: "3rem", color: "#5136a9" }}
              class="bi bi-1-square-fill"
            ></i>
          </div>
          <div
            style={{
              textAlign: "center",
              width: "200px",
              margin: "0 auto",
              padding: "0.5rem",
              fontSize: "1.2rem",
            }}
          >
            <b>Fact-based responses</b> based on <b>real time</b> public or
            private data streams
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div style={{ textAlign: "center" }}>
            <i
              style={{ fontSize: "3rem", color: "#5136a9" }}
              class="bi bi-2-square-fill"
            ></i>
          </div>
          <div
            style={{
              textAlign: "center",
              width: "200px",
              margin: "0 auto",
              padding: "0.5rem",
              fontSize: "1.2rem",
            }}
          >
            <b>Create content</b> optimized for your needs, as document
            summaries, emails, and promotional material
          </div>
        </div>
        <div className="col-sm-12 col-lg-4">
          <div style={{ textAlign: "center" }}>
            <i
              style={{ fontSize: "3rem", color: "#5136a9" }}
              class="bi bi-3-square-fill"
            ></i>
          </div>
          <div
            style={{
              textAlign: "center",
              width: "200px",
              margin: "0 auto",
              padding: "0.5rem",
              fontSize: "1.2rem",
            }}
          >
            <b>Work safely</b> in a secured environment
          </div>
        </div>
      </div>
      <div style={{ margin: "3rem 0" }}>
        <hr />
      </div>
      <h2 style={{ fontSize: "1.8rem" }} className="text-center mt-3">
        Sensi optimizations for Innovation Intelligence in Life Sciences and
        Pharma
      </h2>
      <div className="d-lg-flex flex-row justify-content-between mt-5">
        <motion.div
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ staggerChildren: 0.5 }}
          className={styles.description}
        >
          <motion.h3
            variants={h3Animation}
            style={{
              textAlign: "center",
              padding: "1rem",
              color: "#5136a9",
              height: "130px",
            }}
          >
            Big Data
            {/* <br /> knowledge */}
          </motion.h3>
          <div className={styles["cards"]}>
            <motion.div
              variants={titleCardAnimation}
              style={{ fontWeight: "bold", margin: "0.5rem 0" }}
            >
              20+ databases integrated on Life sciences, patents, businesses and
              products
            </motion.div>

            <motion.ul variants={titleCardAnimation}>
              <li>
                Ask questions about chemicals, patents, conditions or companies
                <ul>
                  <li>Give me the summary of quercetin</li>
                  <li>What are the uses of Quinic acid</li>
                  <li>Summarize patent EP123455A1 for me</li>
                  <li>From where can I extract Urolithin A</li>
                </ul>
              </li>
            </motion.ul>
          </div>
        </motion.div>
        <motion.div
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ staggerChildren: 0.5 }}
          className={styles.description}
        >
          <motion.h3
            variants={h3Animation}
            style={{
              textAlign: "center",
              padding: "1rem",
              color: "#5136a9",
              height: "130px",
            }}
          >
            Predictive models
          </motion.h3>
          <div className={styles["cards"]}>
            <motion.div
              variants={titleCardAnimation}
              style={{ fontWeight: "bold", margin: "0.5rem 0" }}
            >
              1000+ (peer-reviewed) predictive models and data at your disposal
            </motion.div>

            <motion.ul variants={titleCardAnimation}>
              <li>
                Predict properties like bioavailability, blood brain barrier
                permeability, taste / aroma
              </li>
              <li>
                Identify novel application areas for given ingredients and
                vice-versa
              </li>
              <li>Give me the bioavailability of Quercetin</li>
              <li>What is the taste of curcumin</li>
            </motion.ul>
          </div>
        </motion.div>
        <motion.div
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={{ once: true, amount: 0.5 }}
          transition={{ staggerChildren: 0.5 }}
          className={styles.description}
        >
          <motion.h3
            variants={h3Animation}
            style={{
              textAlign: "center",
              padding: "1rem",
              color: "#5136a9",
              height: "130px",
            }}
          >
            Workflow assistance
          </motion.h3>
          <div className={styles["cards"]}>
            <motion.div
              variants={titleCardAnimation}
              style={{ fontWeight: "bold", margin: "0.5rem 0" }}
            >
              Innovation workflow expertise
            </motion.div>

            <motion.ul variants={titleCardAnimation}>
              <li>
                Patent related workflows
                <ul>
                  <li>Prior art searching</li>
                  <li>Freedom to operate analysis</li>
                </ul>
              </li>
              <li>Research</li>
              <li>
                Find for me patents on the use of quercetin in metabolic health
              </li>
            </motion.ul>
          </div>
        </motion.div>
      </div>
    </Fragment>
  );
}

export default LandingPage;
