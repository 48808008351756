import { Swiper, SwiperSlide } from "swiper/react";
import { useParams } from "react-router-dom";
import { Navigation, EffectFade } from "swiper";
import { Container } from "react-bootstrap";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import LineWithContent from "./LineWithContent";
import BarWithContent from "./BarWithContent";
import PieWithContent from "./PieWithContent";
import StreamChart from "./StreamChart";
import SectionTitle from "./SectionTitle";
import TextWithImage from "./TextWithImage";
import SingleBar from "./SingleBar";

function Slider({ compound }) {
  let { compound: c } = useParams();
  if (c) {
    compound = c;
  }

  return (
    <Container style={{ height: "95vh" }}>
      <Swiper
        style={{ height: "90%" }}
        modules={[Navigation, EffectFade]}
        navigation
        effect
        speed={1000}
        slidesPerView={1}
        loop
      >
        <SwiperSlide className="d-flex align-items-center">
          <SectionTitle
            text={`${
              compound.charAt(0).toUpperCase() + compound.slice(1)
            } Summary: Science, IP, Regulatory and Supply`}
          />
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: "100%", height: "50vh" }}>
            <TextWithImage compound={compound} />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div style={{ width: "100%", height: "100%" }}>
            <LineWithContent
              apiData={{
                url: `clinical-trials/${compound}`,
                xAxis: "clinical_trial_years",
                yAxis: "total_clinical_trials",
              }}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: "100%", height: "100%" }}>
            <LineWithContent
              apiData={{
                url: `patent-information/${compound}`,
                xAxis: "patent_years",
                yAxis: "patents_per_year",
              }}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: "100%", height: "100%" }}>
            <BarWithContent
              apiData={{
                url: `patents/players/${compound}`,
                xAxis: "top_10_players",
                yAxis: "patents_per_player",
              }}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div style={{ width: "100%", height: "100%" }}>
            <SingleBar
              apiData={{
                url: `source-organisms/${compound}`,
                xAxis: "source_organisms",
                yAxis: "source_organisms_sum",
              }}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: "100%", height: "100%" }}>
            <StreamChart
              apiData={{
                compound: compound,
                url: `plots/bioavailability`,
                stream1: "concentration_lower",
                stream2: "concentration",
                stream3: "concentration_upper",
              }}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ width: "100%", height: "100%" }}>
            <SingleBar
              apiData={{
                url: `slc-compound/${compound}`,
                xAxis: "x_axis",
                yAxis: "y_axis",
              }}
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
}

export default Slider;
