import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="landingfooter">
      <p className="py-2 my-2">
        © Copyright {new Date().getFullYear()} | All right are reserved by
        Siftlink
      </p>
    </div>
  );
};

export default Footer;
