import convertTimestamp from "../../utils/convertTimestamp";

function DisplayDate({ timestamp }) {
  return (
    <div className="text-center">
      {convertTimestamp(parseInt(timestamp)).toDate}
    </div>
  );
}

export default DisplayDate;
