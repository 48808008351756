import styles from "./TypingAnimation.module.css";

function TypingAnimation() {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          backgroundColor: "#E4E6EB",
          maxWidth: "70%",
          borderRadius: "10px",
          margin: "1rem 0.5rem",
          //   padding: "5px 15px",
        }}
      >
        <div className={styles["chat-bubble"]}>
          <div className={styles.typing}>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
            <div className={styles.dot}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TypingAnimation;
