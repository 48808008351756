// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StreamChart_titleHr__NK5Z3 {\n  background-color: red;\n  height: 5px;\n  width: 10%;\n  opacity: 1;\n  border: none;\n  margin-left: 1.4rem;\n}\n\n@media screen and (max-width: 576px) {\n  .StreamChart_titleHr__NK5Z3 {\n    width: 15%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/StreamChart.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,UAAU;EACV,UAAU;EACV,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".titleHr {\n  background-color: red;\n  height: 5px;\n  width: 10%;\n  opacity: 1;\n  border: none;\n  margin-left: 1.4rem;\n}\n\n@media screen and (max-width: 576px) {\n  .titleHr {\n    width: 15%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleHr": "StreamChart_titleHr__NK5Z3"
};
export default ___CSS_LOADER_EXPORT___;
