import React from "react";
import { Headings } from "../../components";
import { data } from "../../constants";

import "./Services.css";

const Services = () => {
  return (
    <div id="works" className="d-block pt-md-5">
      <div className="container pt-5">
        <h3 style={{ color: "#c8102e", fontSize: "36px", fontWeight: "bold" }}>
          Join the community of professionals using Sensi
        </h3>
        <Headings title="How it works" />
        <div className="row">
          {data.ServicesData.map(
            ({ titleone, text, itemclass, imgURL }, index) => (
              <div className="col-lg-4 col-12 mt-4 mb-4" key={index}>
                <div className={`row ${itemclass}`}>
                  <div className="box">
                    <img
                      src={imgURL}
                      alt={titleone}
                      className="img-fluid img-services"
                    />
                    <div>
                      <span style={{ fontSize: "26px", fontWeight: "600" }}>
                        {titleone}{" "}
                      </span>
                    </div>
                    <p style={{ fontSize: "14px" }}>{text}</p>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Services;
