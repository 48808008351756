import { useState } from "react";
import BackToHome from "./BackToHome";
import DisplayDate from "./DisplayDate";
import convertTimestamp from "../../utils/convertTimestamp";

function SessionTitle({ session }) {
  let [titleValue, setTitleValue] = useState(
    session ? session.sessionTitle : "New note"
  );

  function handleOnChange(event) {
    setTitleValue(event.target.value);
    fetch(`https://sensi-api.siftlink.com/sessions/update-title`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: session.uid,
        timestamp: parseInt(session.timestamp),
        sessionTitle: event.target.value,
        modifiedAt: convertTimestamp(Date.now()).toDateAndHour,
      }),
    })
      .then(function (res) {
        console.log(res);
      })
      .catch(function (res) {
        console.log(res);
      });
  }

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div style={{ height: "36px" }}>
        <BackToHome />
      </div>
      <div>
        <textarea
          style={{
            resize: "none",
            border: "0px",
            borderBottom: "1px solid black",
            outline: "none",
            textAlign: "center",
            fontSize: "25px",
            maxWidthidth: "400px",
          }}
          value={titleValue}
          onChange={handleOnChange}
          maxLength={30}
          name="sessionTitle"
          rows={1}
          col={45}
        />
        <DisplayDate timestamp={session.timestamp} />
      </div>
      <div></div>
    </div>
  );
}

export default SessionTitle;
