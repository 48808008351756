import { images } from "../../constants";
import { Button } from "react-bootstrap";
import "./Hero.css";
import TrialPeriodBanner from "../../../TrialPeriodBanner";

const Hero = ({
  currentUser,
  trialExpired,
  trialExpiredAt,
  subscriptionStatus,
}) => {
  return (
    <div>
      <div className="hero ">
        <TrialPeriodBanner
          currentUser={currentUser}
          subscriptionStatus={subscriptionStatus}
          trialExpired={trialExpired}
          trialExpiredAt={trialExpiredAt}
        />
        <div className="container py-5">
          <div className="row align-items-center ">
            <div className="col-md-5 col-12 align-self-start pt-4 p-2">
              <h1 className="title">
                1-click AI assistants from your data and content
              </h1>
              <p className="py-4">
                Generate fact-based reports and receive real-time, high quality
                insights for your meeting, due dilligence or while drafting your
                document.
              </p>
              <Button
                href="/sign-up"
                variant="primary"
                size="lg"
                className="btn-hero"
              >
                Start now your free 7-day trial
              </Button>
            </div>
            <div className="col-md-7 col-12 mt-md-0 mt-4">
              <img className="img-fluid" src={images.hero} alt="design" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
