import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { collection, query, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import LoadingAnimation from "../compoundOverview/LoadingAnimation";
import { getDatabase, ref, child, get } from "firebase/database";
import { Pricing, Empower, Hero, Services, Sensi, CTA } from "./container";
import { Menu } from "./components";
import "./Landing.module.css";
import Footer from "../NewHomePage/footer/Footer";

function Landing() {
  let { currentUser } = useAuth();
  let [subscriptionStatus, setSubscriptionStatus] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const dbRef = ref(getDatabase());
  let [trialExpiredAt, setTrialExpiredAt] = useState(null);

  useEffect(
    function getSubscriptionStatus() {
      async function getUserSubscription() {
        if (!currentUser) {
          setLoading(false);
          return;
        }
        let q = query(
          collection(firestore, "customers", currentUser.uid, "subscriptions")
        );

        let querySnapshot = await getDocs(q);
        let temp = [];
        querySnapshot.forEach(function (document) {
          temp.push(document.data());
        });
        setSubscriptionStatus([temp[temp.length - 1]]);
        setLoading(false);
      }

      getUserSubscription();
    },
    [currentUser]
  );

  useEffect(function getTrialExpiration() {
    if (!currentUser) {
      setLoading(false);
      return;
    }
    get(child(dbRef, `users/${currentUser.uid}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setTrialExpiredAt(snapshot.val().trialExpireAt);
          setLoading(false);
        } else {
          console.log("No data available");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function trialExpired() {
    return Date.now() > trialExpiredAt;
  }

  if (loading) {
    return <LoadingAnimation />;
  }
  return (
    <div>
      <Menu />
      <Hero
        currentUser={currentUser}
        subscriptionStatus={subscriptionStatus}
        trialExpired={trialExpired}
        trialExpiredAt={trialExpiredAt}
      />
      <Services />
      <Empower />
      <CTA />
      <Sensi />
      <Pricing />
      <Footer />
    </div>
  );
}

export default Landing;
