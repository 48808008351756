import { useParams } from "react-router-dom";
import { Row, Col, Table, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import LoadingAnimation from "./LoadingAnimation";
import NoData from "./NoData";
import Slider from "../Slider";

function CompoundOverview() {
  const { cid } = useParams();
  let [cidInfo, setCidInfo] = useState(undefined);
  let [compound, setCompound] = useState(undefined);
  let [isLoading, setIsLoading] = useState(true);
  let [hasData, setHasData] = useState(false);

  let titles = [
    "General",
    "Chemical structure",
    "Physicochemical",
    "ADME+",
    "Toxicology",
    "Medical chemistry",
    "Disease",
  ];

  useEffect(function fetchCidInfo() {
    fetch(`https://api-02.siftlink.com/slc/compound/${cid}`, {
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZmY4MzVmM2M2MGFlY2MzYjNmYWVhNjZjNGJmNTVhNjFjYTljZWI2MmYzMDQ3MzBkM2YyNzBkMzE0ZGI1NTg0OGVmYjc1ZTljN2Y0ZjBmZDk4MWIyMjdjNTg2MTg0YTg4OTFlOGY3YzY0ZTgyZTU3OTVlMzdkYzY5NWM3M2Y2ZDAiLCJleHBpcmVzIjozMTcyNDUzMDUwMjIuOTU4OH0.78TIGmQt4XTDsMVbhfKxo6VLazsdw13pqrTUDGD46K4",
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then(function (responseData) {
        if (responseData) {
          if (responseData.data.length > 0) {
            setHasData(true);
            setCidInfo(responseData.data);
          }
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(function cidToCompound() {
    fetch(
      `https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/cid/${cid}/synonyms/JSON`
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then(function (responseData) {
        if (responseData) {
          setCompound(responseData.InformationList.Information[0].Synonym[0]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (hasData === false) {
    return <NoData />;
  }

  return (
    <Container
      style={{ minHeight: "70vh", marginBottom: "1rem" }}
      className="mt-5"
    >
      <Row>
        {cidInfo.map(function (d, index) {
          let k = Object.keys(d);
          if (!k.length) {
            return undefined;
          }
          return (
            <>
              <Col lg={6}>
                <h3
                  style={{
                    backgroundColor: "#def3d6",
                    fontWeight: "lighter",
                  }}
                  className="text-center mt-4 mb-2 p-3 rounded bg-opacity-50"
                >
                  {titles[index]}
                </h3>
                <Table striped bordered hover responsive>
                  <tbody>
                    {k.map(function (a) {
                      return (
                        <>
                          {a === "chemical_structure" && (
                            <img
                              src={cidInfo[index][a]}
                              alt="compound"
                              width="100%"
                              height="350px"
                            />
                          )}
                          {a === "Cid" && (
                            <tr>
                              <th>{a}</th>
                              <td>
                                <a
                                  href={`https://pubchem.ncbi.nlm.nih.gov/compound/${cidInfo[index][a]}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {cidInfo[index][a]}
                                </a>
                              </td>
                            </tr>
                          )}
                          {a !== "Cid" && a !== "chemical_structure" && (
                            <tr>
                              <th>{a}</th>
                              <td style={{ wordBreak: "break-all" }}>
                                {cidInfo[index][a] === null
                                  ? "-"
                                  : cidInfo[index][a]}
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </>
          );
        })}
      </Row>
      {compound !== undefined ? <Slider compound={compound} /> : <></>}
    </Container>
  );
}

export default CompoundOverview;
