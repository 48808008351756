import { Row, Col, Table } from "react-bootstrap";

function CompoundOverview({ data1, cid }) {
  let titles = [
    "General",
    "Chemical info",
    "Organism info",
    "Physicochemical",
    "Predictive toxicology",
  ];
  return (
    <Row>
      <Col lg={6}>
        {data1.map(function (d, index) {
          if (index === 4) return;
          let k = Object.keys(d);
          return (
            <>
              <h3
                style={{ backgroundColor: "#5d20d3", color: "white" }}
                className="text-center mt-4 mb-2 p-3 rounded bg-opacity-50"
              >
                {titles[index]}
              </h3>
              <Table striped bordered hover responsive>
                <tbody>
                  {k.map(function (a) {
                    return (
                      <>

                      {a === "cid" ?                      
                      <tr>
                        <td>{a}</td>
                        <td>
                        <a href={`https://pubchem.ncbi.nlm.nih.gov/compound/${data1[index][a]}`} target="_blank" rel="noreferrer">{data1[index][a]}</a>
                          
                        </td>
                      </tr> :
                      <tr>
                        <td>{a}</td>
                        <td>
                          {data1[index][a] === null ? "-" : data1[index][a]}
                        </td>
                      </tr>}
                      
                      </>

                    );
                  })}
                </tbody>
              </Table>
            </>
          );
        })}
      </Col>
      <Col lg={6}>
        <div
          style={{
            width: "100%",
            margin: "24px 0",
          }}
        >
          <img
            src={`https://pubchem.ncbi.nlm.nih.gov/image/imgsrv.fcgi?cid=${cid}&t=l`}
            alt="compound"
            width="100%"
          />
        </div>
        {data1.map(function (d, index) {
          if (index === 4) {
            let k = Object.keys(d);
            return (
              <>
                <h3
                  style={{
                    backgroundColor: "#5d20d3",
                    color: "white",
                  }}
                  className="text-center mt-4 mb-2 p-3 rounded bg-opacity-50"
                >
                  {titles[index]}
                </h3>
                <Table striped bordered hover responsive>
                  <tbody>
                    {k.map(function (a) {
                      return (
                        <tr>
                          <td>{a}</td>
                          {a === "positive toxicity tests" ? (
                            data1[index][a].map(function (b) {
                              return (
                                <Table striped bordered hover responsive>
                                  <tbody>
                                    <tr>
                                      <td>{b}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              );
                            })
                          ) : (
                            <td>
                              {data1[index][a] === null ? "-" : data1[index][a]}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            );
          }
        })}
      </Col>
    </Row>
  );
}

export default CompoundOverview;
