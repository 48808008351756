function convertTimestamp(timestamp) {
  let date = new Date(parseInt(timestamp));
  const toHour = date.getHours() + ":" + getFullMinutes(date.getMinutes());
  const month = date.getMonth() + 1;
  const toDate = date.getDate() + "-" + month + "-" + date.getFullYear();
  const toDateAndHour = toDate + " " + toHour;

  return {
    toHour,
    toDate,
    toDateAndHour,
  };
}

function getFullMinutes(minutes) {
  if (minutes.toString().length === 1) {
    return "0" + minutes;
  }
  return minutes;
}

export default convertTimestamp;
