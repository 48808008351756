import { useState, useEffect, Fragment } from "react";

import { ResponsiveLine } from "@nivo/line";
import { ThreeDots } from "react-loader-spinner";
import styles from "./LineWithContent.module.css";

function LineWithContent({ apiData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(function () {
    fetch(`https://api-02.siftlink.com/${apiData.url}`, {
      headers: {
        Authorization: process.env.REACT_APP_NOT_SECRET_CODE,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then(function (responseData) {
        if (responseData) {
          setData(constructData(responseData));
        }
        setIsLoading(false);
      });
  }, []);

  function constructData(responseData) {
    const chartData = [];
    for (let i = 0; i < responseData.data[`${apiData.xAxis}`].length; i++) {
      chartData.push({
        x: responseData.data[`${apiData.xAxis}`][i],
        y: responseData.data[`${apiData.yAxis}`][i],
      });
    }
    return {
      chartData,
      title: responseData.data["slide_title"],
      paragraph_title: responseData.data["paragraph_title"],
      html_text: responseData.data["html_text"],
      message: responseData["message"],
    };
  }

  if (isLoading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "70vh", textAlign: "center" }}
      >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    );
  } else {
    if (data.message === "success") {
      return (
        <Fragment>
          <div className="row" style={{ height: "15%" }}>
            <h1 className="text-capitalize mt-4 ms-2">{data.title}</h1>
            <hr className={`${styles.titleHr}`} />
          </div>
          <div
            className="row"
            style={{ height: "85%", width: "95%", margin: "0 auto" }}
          >
            <div className="col-12">
              <ResponsiveLine
                data={[
                  {
                    id: "0",
                    color: "hsl(171, 70%, 50%)",
                    data: data.chartData,
                  },
                ]}
                axisBottom={{
                  tickRotation: 45,
                }}
                margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
                curve="cardinal"
                enablePoints={false}
              />
            </div>
            <div className="ms-5 mt-4 w-75">
              <p className="text-danger text-capitalize">
                {data.paragraph_title}
              </p>
              <div dangerouslySetInnerHTML={{ __html: data.html_text }} />
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="row" style={{ height: "15%" }}>
            <h1 className="text-capitalize mt-4 ms-2">{data.title}</h1>
            <hr className={`${styles.titleHr}`} />
          </div>

          <div className="d-flex align-items-center justify-content-center h-75">
            <p className="mb-4 fs-3">No data found</p>
          </div>
        </Fragment>
      );
    }
  }
}

export default LineWithContent;
