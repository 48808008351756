import { useState, useRef, useEffect } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import styles from "./SpecificAssistant.module.css";

function SpecificAssistant({ editorRef, showAlertComponent }) {
  const [messages, setMessages] = useState([
    { text: "Test message (styling is under construction)", sender: "user" },
  ]);
  const [newMessage, setNewMessage] = useState("");
  const [label, setLabel] = useState("Editor");

  let inputRef = useRef(null);
  const handleClick = () => {
    setLabel(label === "Editor" ? "Message" : "Editor");
  };

  useEffect(function setFocusOnInput() {
    inputRef.current.focus();
  }, []);

  function handleReferncizerButtonClick() {
    const editor = editorRef.current;

    if (!editor) {
      showAlertComponent("An error occur. No editor found");
      return;
    }

    const selectedText = editor.selection.getContent({ format: "text" });
    const bookmark = editor.selection.getBookmark(2, true);

    // if (!selectedText) {
    //   showAlertComponent("Please highlight a text.");
    //   return;
    // }

    axios
      .post(
        `https://sensi-api.siftlink.com/editor/references/`,
        {
          highlightedText: selectedText,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          timeout: 300000, // Timeout in milliseconds
        }
      )
      .then((response) => {
        // setIsWaitAnswer(false);
        if (!response.status === 200) {
          throw new Error(
            "An error occurred. Response status: " + response.status
          );
        }
        return response.data;
      })
      .then((req2) => {
        if (req2.error) {
          showAlertComponent(req2.error);
          return;
        }
        editor.selection.moveToBookmark(bookmark);
        editor.selection.setContent(`${req2.references}`);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          showAlertComponent("Request timed out");
        } else {
          showAlertComponent("An error occurred: " + error.message);
        }
        // setIsWaitAnswer(false);
      });
  }

  const handleMessageSend = () => {
    if (newMessage.trim() !== "") {
      setMessages(function (previousMessages) {
        return [...previousMessages, { text: newMessage, sender: "user" }];
      });
      const editor = editorRef.current;
      const selectedText = editor.selection.getContent({ format: "text" });
      const bookmark = editor.selection.getBookmark(2, true);
      if (label === "Editor") {
        axios
          .post(
            `https://sensi-api.siftlink.com/editor/manipulate-content/`,
            {
              highlightedText: selectedText,
              userQuestion: newMessage,
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              timeout: 300000, // Timeout in milliseconds
            }
          )
          .then((response) => {
            // setIsWaitAnswer(false);
            // setIsFormDisabled(false);
            // setShowModal(false);
            // setModalText("");
            if (!response.status === 200) {
              throw new Error(
                "An error occurred. Response status: " + response.status
              );
            }
            return response.data;
          })
          .then((req2) => {
            editor.selection.moveToBookmark(bookmark);
            console.log("classification + answer");
            console.log(req2);
            if (req2.error) {
              showAlertComponent(req2.error);
              return;
            }

            if (req2.classification === "replacement") {
              editor.selection.setContent(`${req2.answer}`);
            } else {
              editor.selection.setContent(selectedText + `${req2.answer}`);
            }
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              showAlertComponent("Request timed out");
            } else {
              showAlertComponent("An error occurred: " + error.message);
            }
            // setIsWaitAnswer(false);
            // setIsFormDisabled(false);
          });
      } else {
        axios
          .post(
            `https://sensi-api.siftlink.com/editor/manipulate-content/`,
            {
              highlightedText: selectedText,
              userQuestion: newMessage,
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              timeout: 300000, // Timeout in milliseconds
            }
          )
          .then((response) => {
            // setIsWaitAnswer(false);
            // setIsFormDisabled(false);
            // setShowModal(false);
            // setModalText("");
            if (!response.status === 200) {
              throw new Error(
                "An error occurred. Response status: " + response.status
              );
            }
            return response.data;
          })
          .then((req2) => {
            if (req2.error) {
              showAlertComponent(req2.error);
              return;
            }

            setMessages(function (previousMessages) {
              return [
                ...previousMessages,
                { text: req2.answer, sender: "bot" },
              ];
            });
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              showAlertComponent("Request timed out");
            } else {
              showAlertComponent("An error occurred: " + error.message);
            }
            // setIsWaitAnswer(false);
            // setIsFormDisabled(false);
          });
      }
    }
  };

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      handleMessageSend();
    }
  }

  return (
    <Container className={styles.chatBox}>
      <Row className={`${styles.messageRow} d-flex flex-column`}>
        <Col>
          <div className={`${styles.messageContainer} d-flex flex-column`}>
            {messages.map((message, index) => (
              <div
                key={index}
                className={`${styles.message} ${
                  message.sender === "bot" ? styles.bot : styles.user
                }`}
              >
                <div dangerouslySetInnerHTML={{ __html: message.text }} />
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex justify-content-evenly">
            <Button onClick={handleReferncizerButtonClick}>
              Find referencess
            </Button>
            <Button onClick={handleReferncizerButtonClick}>
              Find referencess
            </Button>
            <Form.Check
              type="switch"
              id="custom-switch"
              label={label}
              onClick={handleClick}
            />
          </div>
          <Form>
            <Form.Group
              controlId="formMessage"
              className={`d-flex align-items-center ${styles.formGroup}`}
            >
              <Form.Control
                type="text"
                placeholder="Type your message here..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={handleKeyPress}
                ref={inputRef}
                className={styles.input}
              />
              <Button variant="outline-light" onClick={handleMessageSend}>
                <i
                  className={`bi bi-send ${styles.sendIcon}`}
                  style={{ color: "black", fontSize: "2rem" }}
                ></i>
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default SpecificAssistant;
