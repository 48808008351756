import { Link } from "react-router-dom";
import styles from "./Footer.module.css";
import "bootstrap-icons/font/bootstrap-icons.css";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className="container  d-flex flex-column align-items-center flex-sm-row justify-content-sm-around">
        <div className="footer-logo">
          <img
            className="p-5"
            src="https://siftlink.com/wp-content/uploads/2021/07/loog-siftlink-petit.png"
            alt=""
          />
        </div>
        <div className="text-center informations text-light fs-6">
          <strong>Siftlink SA</strong>
          <span className="d-block">Biopôle, Phenyl Building</span>
          <span className="d-block">Route de la Corniche 3</span>
          <span className="d-block">1066 Epalinges</span>
          <span className="d-block">Switzerland</span>
          <span className="d-block">info@siftlink.com</span>
        </div>
        <div>
          <a
            className="d-block fs-1 text-center p-3"
            href="https://www.linkedin.com/company/siftlink/"
          >
            <i className="bi bi-linkedin"></i>
          </a>
          <span className="d-block text-center text-light">
            © siftlink 2021
          </span>
          <span className="d-block text-center text-light p-2">
            <Link to="/privacy-policy" className="text-light">
              privacy policy
            </Link>
          </span>
          <span className="d-block text-center text-light">
            <Link to="/terms-of-service" className="text-light">
              terms of service
            </Link>
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
