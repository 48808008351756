import { getAuth } from "firebase/auth";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import styles from "./NavBar.module.css";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function NavBar() {
  const { logout } = useAuth();
  const auth = getAuth();

  function handleLogout(auth) {
    logout(auth);
  }

  return (
    <div className={styles.navWrapper}>
      <Navbar className={`container ${styles.navContainer}`} expand="lg">
        <Container style={{ padding: "0" }} className={styles.navBackground}>
          <Navbar.Brand className={styles.siftlinkNavLogo}>
            <Link to="/">
              <img
                className=""
                src="/log-siflink-03-yk-cropped.svg"
                alt="Bootstrap"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            className={styles.burger}
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse>
            <Nav
              className={`${styles.navLinks} ms-auto  d-flex align-items-lg-center`}
            >
              <Nav.Link href="/" className={styles.navigationLink}>
                Home
              </Nav.Link>
              <Nav.Link className={styles.navigationLink} href="/account">
                Account
              </Nav.Link>
              <Nav.Link
                className={styles.navigationLink}
                href="mailto:sensi@siftlink.com"
              >
                Contact
              </Nav.Link>
              <Nav.Link onClick={() => handleLogout(auth)} role="button">
                Log out
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default NavBar;
