import { Fragment, useState } from "react";
import { Col, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteNoteModal from "./DeleteNoteModal";

function SessionBox({ session, setIsDeletingSession }) {
  let [showModal, setShowModal] = useState(false);

  function handleShow() {
    setShowModal(true);
  }

  return (
    <Fragment>
      <DeleteNoteModal
        showModal={showModal}
        setShowModal={setShowModal}
        setIsDeletingSession={setIsDeletingSession}
        uid={session.uid}
        timestamp={session.timestamp}
        title={session.sessionTitle}
      />
      <Col style={{ height: "100%" }}>
        <div
          style={{ borderRadius: "10px", height: "100%" }}
          className=" bg-white"
        >
          <div
            style={{
              height: "50px",
              border: "2px solid rgba(220,220,220, 0.3)",
              borderRadius: "15px 15px px 0px",
              textAlign: "center",
            }}
            className=" d-flex justify-content-between align-items-center"
          >
            <div
              className=""
              style={{
                borderRadius: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "200px",
                whiteSpace: "nowrap",
                textAlign: "left",
              }}
            >
              <Link
                style={{ color: "#282c33" }}
                className="p-2"
                to={`/editor/${session.timestamp}`}
              >
                <i
                  className="bi bi-journal-richtext"
                  style={{
                    color: "#800080",
                    marginRight: "20px",
                    fontSize: "20px",
                  }}
                ></i>
                {session.sessionTitle}
              </Link>
            </div>
            <Button onClick={handleShow} variant="link">
              {/* <i className="bi bi-trash" style={{ fontSize: "1.3rem" }}></i> */}
              <i
                className="bi bi-x-circle"
                style={{ fontSize: "1.3rem", color: "#404951" }}
              ></i>
            </Button>
          </div>
          <div
            className=""
            style={{
              height: "300px",
              // border: "2px solid rgba(220,220,220, 0.3)",
              borderRadius: "0px 0px 15px 15px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                height: "100%",
                overflowY: "hidden",
                overflowX: "hidden",
                margin: "0 5rem",
                fontSize: "0.6rem",
                textAlign: "initial",
                zoom: "60%",
                padding: "0.5rem",
                borderRadius: "15px",
              }}
              dangerouslySetInnerHTML={{ __html: session.sessionText }}
            />
          </div>
        </div>
      </Col>
    </Fragment>
  );
}

export default SessionBox;
