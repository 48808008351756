import { Button } from "react-bootstrap";
import logo from "../../../assets/logo.png";
import doc from "../../../assets/doc.png";
import report from "../../../assets/report.png";
import clock from "../../../assets/clock.png";
import note from "../../../assets/note.png";
import { useState } from "react";
import NewSeesionButton from "../../homePage/NewSeesionButton";
import "./sidebar.css";

function Sidebar() {
  const [subDocShow, setSubDocShow] = useState(false);
  const [docShow, setDocShow] = useState(false);
  const [favShow, setFavShow] = useState(false);

  return (
    <div className="bg-white rounded-3 rounded-lg-top-0 h-100">
      <div className="logo-container d-none d-lg-block">
        <img src={logo} alt="" />
      </div>
      <div className="doc-container">
        <div className="d-none d-lg-block">
          <p className="mb-2 font-400 text-sm fw-medium">
            Create a New Document
          </p>
          <NewSeesionButton />
          {/* <Button
            onClick={() => setSubDocShow(!subDocShow)}
            className="w-75 fw-bold font-600"
            variant="danger"
          >
            + New
          </Button> */}
          <div className={`${!subDocShow && "d-none"} pink-bg`}>
            <div className="pink-btn py-2 d-flex gap-2 align-items-center my-auto">
              <img src={doc} alt="" />
              <p className="font-300 text-sm my-auto">Empty document</p>
            </div>

            <div className="pink-btn py-2 d-flex gap-2 align-items-center my-auto">
              <img src={report} alt="" />
              <p className="font-300 text-sm my-auto">Report</p>
            </div>
          </div>
        </div>
        <div className="d-lg-none">
          <div
            onClick={() => setDocShow(!docShow)}
            className="d-flex justify-content-between align-items-center"
          >
            <p className="mb-0 font-400 text-sm fw-medium">
              Create a New Document
            </p>
            {docShow ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-up"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-down"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                />
              </svg>
            )}
          </div>
          <div className={`${!docShow && "d-none"}`}>
            <Button
              onClick={() => setSubDocShow(!subDocShow)}
              className="w-75 fw-bold font-600 my-2"
              variant="danger"
            >
              + New
            </Button>
            <div className={`${!subDocShow && "d-none"} pink-bg`}>
              <div className="pink-btn py-2 d-flex gap-2 align-items-center my-auto">
                <img src={doc} alt="" />
                <p className="font-300 text-sm my-auto">Empty document</p>
              </div>

              <div className="pink-btn py-2 d-flex gap-2 align-items-center my-auto">
                <img src={report} alt="" />
                <p className="font-300 text-sm my-auto">Report</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="doc-border d-lg-none" />
      {/* <div className="fav-container">
        <div className="d-none d-lg-block">
          <div className="d-flex gap-2 align-items-center">
            <img src={clock} alt="" />
            <p className="font-600 text-sm my-auto fw-bold">Favorites</p>
          </div>
          <div className="mt-3 ms-4 d-flex flex-column gap-1">
            <div className="d-flex gap-2 align-items-center ps-1 pt-2">
              <img src={note} alt="" />
              <p className="font-300 text-sm my-auto">New Note</p>
            </div>
            <div className="d-flex gap-2 align-items-center ps-1 pt-2">
              <img src={note} alt="" />
              <p className="font-300 text-sm my-auto">New Note</p>
            </div>
            <div className="d-flex gap-2 align-items-center ps-1 pt-2">
              <img src={note} alt="" />
              <p className="font-300 text-sm my-auto">New Note</p>
            </div>
            <div className="d-flex gap-2 align-items-center ps-1 pt-2">
              <img src={note} alt="" />
              <p className="font-300 text-sm my-auto">New Note</p>
            </div>
            <div className="d-flex gap-2 align-items-center ps-1 pt-2">
              <img src={note} alt="" />
              <p className="font-300 text-sm my-auto">New Note</p>
            </div>
          </div>
        </div>
        <div className="d-lg-none">
          <div
            onClick={() => setFavShow(!favShow)}
            className="d-flex align-items-center justify-content-between"
          >
            <span className="d-flex gap-2 align-items-center">
              <img src={clock} alt="" />{" "}
              <p className="font-600 text-sm my-auto fw-bold">Favorites</p>
            </span>
            {favShow ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-up"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-down"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                />
              </svg>
            )}
          </div>
          <div
            className={`mt-3 ms-4 d-flex flex-column gap-1 ${
              !favShow && "d-none"
            }`}
          >
            <div className="d-flex gap-2 align-items-center ps-1 pt-2">
              <img src={note} alt="" />
              <p className="font-300 text-sm my-auto">New Note</p>
            </div>
            <div className="d-flex gap-2 align-items-center ps-1 pt-2">
              <img src={note} alt="" />
              <p className="font-300 text-sm my-auto">New Note</p>
            </div>
            <div className="d-flex gap-2 align-items-center ps-1 pt-2">
              <img src={note} alt="" />
              <p className="font-300 text-sm my-auto">New Note</p>
            </div>
            <div className="d-flex gap-2 align-items-center ps-1 pt-2">
              <img src={note} alt="" />
              <p className="font-300 text-sm my-auto">New Note</p>
            </div>
            <div className="d-flex gap-2 align-items-center ps-1 pt-2">
              <img src={note} alt="" />
              <p className="font-300 text-sm my-auto">New Note</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Sidebar;
