import { useState, useEffect, Fragment } from "react";
import { ResponsiveStream } from "@nivo/stream";
import { ThreeDots } from "react-loader-spinner";
import styles from "./StreamChart.module.css";

function StreamChart({ apiData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(function () {
    fetch(`https://api-02.siftlink.com/${apiData.url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_NOT_SECRET_CODE,
      },
      body: JSON.stringify({ compound: apiData.compound }),
    })
      .then(function (response) {
        if (response.status == 200) {
          return response.json();
        }
      })
      .then(function (responseData) {
        if (responseData) {
          setData(constructData(responseData));
        }
        setIsLoading(false);
      });
  }, []);

  function constructData(responseData) {
    const chartData = [];
    for (let i = 0; i < responseData.data[`${apiData.stream1}`].length; i++) {
      chartData.push({
        [apiData.stream1]: responseData.data[`${apiData.stream1}`][i],
        [apiData.stream2]: responseData.data[`${apiData.stream2}`][i],
        [apiData.stream3]: responseData.data[`${apiData.stream3}`][i],
      });
    }
    return {
      chartData,
      title: responseData.data["slide_title"],
    };
  }
  if (isLoading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "70vh", textAlign: "center" }}
      >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-isloading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    );
  } else {
    if (Object.keys(data).length) {
      return (
        <Fragment>
          <div className="row" style={{ height: "15%" }}>
            <h1 className="text-capitalize mt-4 ms-2">
              Predicted Bioavailability
            </h1>
            <hr className={`${styles.titleHr}`} />
          </div>
          <div
            className="row"
            style={{ height: "85%", width: "95%", margin: "0 auto" }}
          >
            <ResponsiveStream
              data={data.chartData}
              keys={[
                "concentration_lower",
                "concentration",
                "concentration_upper",
              ]}
              margin={{ top: 10, right: 40, bottom: 60, left: 40 }}
              offsetType="silhouette"
              colors={{ scheme: "nivo" }}
              fillOpacity={0.5}
              borderColor={{ theme: "background" }}
            />
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="row" style={{ height: "15%" }}>
            <h1 className="text-capitalize mt-4 ms-2">
              Predicted Bioavailability
            </h1>
            <hr className={`${styles.titleHr}`} />
          </div>

          <div className="d-flex align-items-center justify-content-center h-75">
            <p className="mb-4 fs-3">No data found</p>
          </div>
        </Fragment>
      );
    }
  }
}

export default StreamChart;
