import { Container, Button } from "react-bootstrap";
import { Navigate, Link } from "react-router-dom";
import {
  getProducts,
  createCheckoutSession,
} from "@stripe/firestore-stripe-payments";
// import { payments } from "../firebase";
import { payments } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import styles from "./Subscription.module.css";
import { Fragment, useEffect, useState } from "react";
import { collection, query, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase";
import { ThreeDots } from "react-loader-spinner";
import LandingPage from "../LandingPage";
import NavBar from "../NavBar";
import Footer from "../Footer";
import sLogo from "../../assets/bgWhiteLogo.png";

function Subscription() {
  let { currentUser } = useAuth();
  let [isLoading, setIsLoading] = useState(false);
  let [productPrices, setProductPrices] = useState(undefined);
  let [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(function getProductPrices() {
    async function getPrices() {
      const products = await getProducts(payments, {
        includePrices: true,
        activeOnly: true,
      });
      console.log("products");
      console.log(products);
      setProductPrices(products);
    }

    getPrices();
  }, []);

  useEffect(
    function getSubscriptionStatus() {
      async function getUserSubscription() {
        if (!currentUser) {
          return;
        }
        let q = query(
          collection(firestore, "customers", currentUser.uid, "subscriptions")
        );

        let querySnapshot = await getDocs(q);
        querySnapshot.forEach(function (document) {
          if (
            document.data().status === "trialing" ||
            document.data().status === "active"
          ) {
            setSubscriptionStatus(true);
          }
        });
        setLoading(false);
      }

      getUserSubscription();
    },
    [subscriptionStatus, currentUser]
  );

  async function checkout(priceId) {
    if (subscriptionStatus) {
      alert("Already subscribed");
      return;
    }
    setIsLoading(true);
    const session = await createCheckoutSession(payments, {
      price: priceId,
      success_url: `${window.location.origin}/home`,
      cancel_url: `${window.location.origin}/subscription`,
      allow_promotion_codes: true,
    });
    window.location.assign(session.url);
  }

  if (!currentUser) {
    return <Navigate to="/" />;
  }
  if (loading || isLoading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "70vh", textAlign: "center" }}
      >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    );
  }
  return (
    <Fragment>
      {subscriptionStatus && <NavBar />}
      <Container className={styles.container}>
        {!subscriptionStatus && (
          <div className="p-2 d-flex justify-content-between">
            <div style={{ height: "60px", width: "120px" }}>
              <img
                style={{ height: "100%", width: "100%" }}
                src={sLogo}
                alt="Bootstrap"
              />
            </div>
            <div>
              Back to <Link to="/">login</Link>
            </div>
          </div>
        )}
        <LandingPage />
        <div className="d-flex flex-column align-items-center h-100">
          <h1 style={{ padding: "1rem" }}>Subscription options</h1>
          <hr style={{ width: "100%%", color: "black" }} />
          <h2 style={{ color: "#c00000" }}>
            Benefit from our 75% early bird promotion - offer valid till end of
            June
          </h2>
          <div className="d-flex flex-column w-100 align-items-center flex-xl-row">
            {productPrices &&
              productPrices.map(function (product, id) {
                if (id === 0) {
                  return undefined;
                }
                return (
                  <div className={styles["subscription-card"]} key={id}>
                    <p className={styles["subscription-cost"]}>
                      {product.name}
                    </p>
                    <p style={{ fontWeight: "bold" }}>3 days free, and then</p>
                    <p style={{ color: "#ff0606", fontSize: "1.5rem" }}>
                      {id === 1 ? <del>from $72.99</del> : <del>from $792</del>}
                    </p>
                    <p style={{ fontSize: "2rem" }}>
                      {product?.description ? product.description : "No price"}
                    </p>
                    <p style={{ color: "grey" }}>
                      subscription renews automatically till cancellation
                    </p>
                    <Button
                      className={styles["subscription-button"]}
                      onClick={() => checkout(product.prices[0].id)}
                    >
                      {isLoading ? "Loading" : "Subscribe"}
                    </Button>
                    <div>
                      <ul>
                        <li
                          style={{
                            padding: "0.2rem",
                            listStyleType: "none",
                            textAlign: "left",
                          }}
                        >
                          + personal notes
                        </li>
                        <li
                          style={{
                            padding: "0.2rem",
                            listStyleType: "none",
                            textAlign: "left",
                          }}
                        >
                          + reminders/notifications
                        </li>
                        <li
                          style={{
                            padding: "0.2rem",
                            listStyleType: "none",
                            textAlign: "left",
                          }}
                        >
                          + access to predictive models
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            <div className={styles["subscription-card"]}>
              <p className={styles["subscription-cost"]}>Enterprise</p>
              <p style={{ color: "#f2f2f2" }}>h</p>
              <p style={{ fontSize: "2rem" }}>Get in touch</p>
              <div>
                <a style={{ color: "black" }} href="mailto: sensi@siftlink.com">
                  <i
                    style={{ fontSize: "3rem", color: "#5136a9" }}
                    class="bi bi-envelope-fill"
                  ></i>
                </a>
              </div>
              <Button
                style={{ visibility: "hidden" }}
                className={styles["subscription-button"]}
                disabled
              >
                Subscribe
              </Button>
              <div>
                <ul>
                  <li
                    style={{
                      padding: "0.2rem",
                      listStyleType: "none",
                      textAlign: "left",
                    }}
                  >
                    + Machine Learning as a Service
                  </li>
                  <li
                    style={{
                      padding: "0.2rem",
                      listStyleType: "none",
                      textAlign: "left",
                    }}
                  >
                    + Organization-wide access
                  </li>
                  <li
                    style={{
                      padding: "0.2rem",
                      listStyleType: "none",
                      textAlign: "left",
                    }}
                  >
                    + Customized applications
                  </li>
                  <li
                    style={{
                      padding: "0.2rem",
                      listStyleType: "none",
                      textAlign: "left",
                    }}
                  >
                    + On-premises installation
                  </li>
                  <li
                    style={{
                      padding: "0.2rem",
                      listStyleType: "none",
                      textAlign: "left",
                    }}
                  >
                    + Integration with internal data
                  </li>
                  <li
                    style={{
                      padding: "0.2rem",
                      listStyleType: "none",
                      textAlign: "left",
                    }}
                  >
                    + Support
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </Fragment>
  );
}

export default Subscription;
