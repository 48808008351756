import { Fragment } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";

function NotFound() {
  return (
    <Fragment>
      <NavBar />
      <div
        className="page-wrap d-flex flex-row align-items-center"
        style={{ height: "68vh" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <span className="display-1 d-block">404</span>
              <div className="mb-4 lead">
                The page you are looking for was not found.
              </div>
              <a href="/home" className="btn">
                Back to Home
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default NotFound;
