import { Container } from "react-bootstrap";

function TermsOfService() {
  return (
    <Container>
      <h1 className="text-center p-4">TERMS OF SERVICE</h1>
      <h6 className="text-center">Last updated: Friday, January 27, 2023</h6>
      <h2 className="text-center p-4">TABLE OF CONTENTS</h2>
      <ol style={{ margin: "2rem 0" }}>
        <li>
          <a href="#introduction">INTRODUCTION</a>
        </li>
        <li>
          <a href="#acceptance">ACCEPTANCE</a>
        </li>
        <li>
          <a href="#the-service">THE SERVICE</a>
        </li>
        <li>
          <a href="#login-information-access">LOGIN INFORMATION ACCESS</a>
        </li>
        <li>
          <a href="#end-users">END-USERS</a>
        </li>
        <li>
          <a href="#eligibility">ELIGIBILITY</a>
        </li>
        <li>
          <a href="#data-collection">DATA COLLECTION</a>
        </li>
        <li>
          <a href="#term">TERM</a>
        </li>
        <li>
          <a href="#account-registration">ACCOUNT REGISTRATION</a>
        </li>
        <li>
          <a href="#subscription-terms">SUBSCRIPTION TERMS</a>
        </li>
        <li>
          <a href="#subscription-authorization">SUBSCRIPTION AUTHORIZATION</a>
        </li>
        <li>
          <a href="#acceptable-use-policy">ACCEPTABLE USE POLICY</a>
        </li>
        <li>
          <a href="#interactive-services">INTERACTIVE SERVICES</a>
        </li>
        <li>
          <a href="#user-contribution-standards">USER CONTRIBUTION STANDARDS</a>
        </li>
        <li>
          <a href="#monitoring-and-enforcement">MONITORING AND ENFORCEMENT</a>
        </li>
        <li>
          <a href="#license">LICENSE</a>
        </li>
        <li>
          <a href="#intellectual-property-rights">
            INTELLECTUAL PROPERTY RIGHTS
          </a>
        </li>
        <li>
          <a href="#third-party-services">THIRD-PARTY SERVICES</a>
        </li>
        <li>
          <a href="#termination">TERMINATION</a>
        </li>
        <li>
          <a href="#release">RELEASE</a>
        </li>
        <li>
          <a href="#warranty-disclaimer">WARRANTY DISCLAIMER</a>
        </li>
        <li>
          <a href="#indemnification">INDEMNIFICATION</a>
        </li>
        <li>
          <a href="#limitation-of-liability">LIMITATION OF LIABILITY</a>
        </li>
        <li>
          <a href="#governing-law">GOVERNING LAW</a>
        </li>
        <li>
          <a href="#dispute-resolution">DISPUTE RESOLUTION</a>
        </li>
        <li>
          <a href="#modification">MODIFICATION</a>
        </li>
        <li>
          <a href="#entire-agreement-and-severability">
            ENTIRE AGREEMENT AND SEVERABILITY
          </a>
        </li>
        <li>
          <a href="#relationship-of-the-parties">RELATIONSHIP OF THE PARTIES</a>
        </li>
        <li>
          <a href="#force-majeure">FORCE MAJEURE</a>
        </li>
        <li>
          <a href="#assignment">ASSIGNMENT</a>
        </li>
        <li>
          <a href="#notices">NOTICES</a>
        </li>
        <li>
          <a href="#no-waiver">NO WAIVER</a>
        </li>
        <li>
          <a href="#interpretation">INTERPRETATION</a>
        </li>
        <li>
          <a href="#contact">CONTACT</a>
        </li>
      </ol>
      <div id="introduction">
        <h3>1 INTRODUCTION</h3>
        <p>
          Welcome to Siftlink SA. These Terms of Service regulate the use and
          access of our media form, media channel, chatbots, and applications
          related, linked, or otherwise connected thereto (collectively the
          “Platform”). For the sake of convenience, the term “Services” shall be
          included in the term “Platform” wherever used throughout this document
          unless specified otherwise. Our Services are intended for the purpose
          of solving global innovation challenges towards improving quality of
          lives by unifying humans with artificial intelligence. To this end, we
          provide Services including without limits, chatbot integration, and
          other related services. These Terms of Service, our Privacy Policy,
          Cancellation Policy, Disclaimer, Data Processing Addendum, and other
          additional terms we make available on the Platform from time to time
          (collectively “Terms”) set out the legal obligation vis-à-vis terms
          and conditions for your interaction and usage of the Platform. This
          document includes a mandatory arbitration provision and regulates the
          resolution of any dispute which may arise between you and us as a
          result of using our Platform. By clicking “Accept” when prompted or
          making an Account or, using or accessing the Platform, you understand
          that you will adhere to these Terms and all other operating rules,
          policies, and procedures that may be issued periodically on the
          Platform by us, each of which is incorporated by reference
          periodically by us. If you do not agree to any of these Terms,
          including the compulsory arbitration provision, you must stop using
          the Platform.
        </p>
      </div>
      <div id="acceptance">
        <h3>2 ACCEPTANCE</h3>
        <p>
          By accepting these Terms, you agree that the Terms constitute a
          binding contract, effective as of the date of first acceptance by you,
          between Siftlink SA (hereinafter referred to as “Siftlink SA”, “we”,
          “us”, or “our”) and you the user (hereinafter referred to as (“you”,
          “your”) (each a “Party” to the contract and collectively, the
          “Parties”).
        </p>
      </div>
      <div id="the-service">
        <h3>3 THE SERVICE</h3>
        <p>
          Our Services are exclusively provided for business use and must be
          used only in line with their intended purpose, aim, and the Terms.
          More detailed guidelines concerning the proper use of the Services are
          set out in the Section 13 (Acceptable Use Policy) below.
        </p>
      </div>
      <div id="login-information-access">
        <h3>4 LOGIN INFORMATION ACCESS</h3>
        <p>
          4.1 We provide you with login information upon purchase of our
          Services provided through the Platform which is needed for
          identification and authentication purposes. You are not permitted to
          transfer login credentials to third parties other than as specifically
          defined. New and/or additional users will be notified by you to us and
          such new users’ access to the Platform shall be subject to our
          explicit approval. You may be notified of extra charges by us in the
          event that you wish to transfer login credentials to third parties
          other than as specifically defined.
        </p>{" "}
        <p>
          4.2 You are assigned a particular password and login (or other
          credentials) which must not be used by third parties without your
          explicit consent. You are solely responsible for the proper protection
          and storage of your password and login (credentials).
        </p>{" "}
        <p>
          4.3 The login credentials for accessing and utilizing the Platform
          shall not be passed on or used by more than the allocated persons or
          entities at the same time. Access to, and use of, the Platform is
          restricted to the specified number of individuals or entities
          permitted under the Customer subscription to the Services. The
          Customer acknowledges and agrees to solely provide access to their
          Platform login credentials to person(s) or entities permitted and
          notified to us in writing (“Agents”). The Agents shall not be
          permitted to share the login credentials with other persons or
          entities without our explicit permission provided that an Agent’s
          credentials may be reassigned to new individuals replacing former
          individuals who no longer require ongoing use of the Services. You and
          your Agents are liable for maintaining the confidentiality of all of
          the Agent credential information for your Account (Defined below).
        </p>
      </div>
      <div id="end-users">
        <h3>5 END-USERS</h3>
        <p>
          For the purposes of these Terms (“End-User”) means any person with
          whom you or your Agents interact with while using Platform Services.
          You are responsible for complying with the provisions of the Terms by
          Agents and End-Users and for any and all activities that occur under
          Your Account. Notwithstanding the foregoing, You are responsible to
          ensure that the use of the Services is compliant with all applicable
          laws and regulations, as well as any and all privacy policies,
          agreements, or other obligations you may maintain or enter into with
          Agents or End-Users.
        </p>
      </div>
      <div id="eligibility">
        <h3>6 ELIGIBILITY</h3>
        <p>
          6.1 To use our Platform, you must:
          <p>
            6.1.1 Be at least of age the age of majority and not disqualified
            from entering into contracts under any law;{" "}
          </p>
          <p>6.1.2 complete the registration process;</p>
          <p>6.1.3 agree to our</p>
          Terms; and
          <p>
            6.1.4 provide true, complete, and up to date legal and contact
            information{" "}
          </p>
          <p>
            6.1.5 You represent and warrant that you have the authority to
            accept these Terms on behalf of the company you may be affiliated
            with.{" "}
          </p>
          <p>
            6.1.6 By using Platform, you represent and warrant that you meet all
            the requirements listed above and that you will not use Platform in
            a way that violates any laws or regulations. Siftlink SA may refuse
            Service, close Accounts of any users, and change eligibility
            requirements at any time.{" "}
          </p>
          <p>
            6.1.7 By accepting these terms, you represent and warrant that you
            are qualified concerning the conditions stated herein, therefore,
            are permitted to use the Platform. If you do not meet any of the
            conditions stated herein, you shall not access/use the Platform and
            must cease to be a user.
          </p>
        </p>
      </div>
      <div id="data-collection">
        <h3>7 DATA COLLECTION</h3>
        <p>
          To provide you with the Platform and Services as mentioned in these
          Terms it is required that we collect your basic information as
          specified in our Privacy Policy (“Customer Data”). You agree that the
          Customer Data is collected by us through your consent.
        </p>
      </div>
      <div id="term">
        <h3>8 TERM</h3>
        <p>
          The Term begins as soon as you access the Platform and continues as
          long as you use the Service. Clicking the button and entering your
          username means that you’ve officially “signed” these Terms.
        </p>
      </div>
      <div id="account-registration">
        <h3>9 ACCOUNT REGISTRATION</h3>
        <p>
          9.1 To access the Platform, you will be provided a user account on the
          Platform (“Account”). For continuous access to our Platform, it is
          suggested that you provide us with accurate, complete, and updated
          information wherever applicable. Failing to meet the aforesaid
          condition may result in the suspension of the respective user Account.
        </p>
        <p>
          9.2 You agree not to (1) misrepresent yourself as someone else by
          selecting or using a username a name, email, or phone number of
          another person; (2) use, as a username, an offensive, vulgar, or
          obscene name; (3) use as a username a fictitious name or pseudonym.
        </p>
        <p>
          9.3 You are solely liable and responsible for any activity that occurs
          on your Account. You agree and understand that you shall not share
          your user Account password with anybody or do any such act which
          promotes unauthorized use of your user Account. You shall take all
          measures to protect your password, including but not limited to,
          restricting the use of your personal device.{" "}
        </p>
        <p>9.4 You must notify us</p>
        immediately on our Platform of any change in your eligibility to use the
        Platform, breach of security, or unauthorized use of your Account. You
        shall have the ability to delete your Account, either through the
        Platform or through a request made on our Platform.
        <p>
          9.5 You understand and agree that by creating a user Account, you
          agree to receive communication concerning marketing emails and SMS
          from us. You understand and agree that any communication or
          notification you receive from us electronically shall qualify as legal
          notice and meet all the legal notice requirements.
        </p>
      </div>
      <div id="subscription-terms">
        <h3>10 SUBSCRIPTION TERMS</h3>
        <p>
          The term of your subscription to the Platform Services
          (“Subscription”) shall commence on the date as notified by us in
          writing upon receipt of requisite payments from you and shall subsist
          for the duration of the particular subscription as selected by you.
          The details of the Subscription, inclusions/ exclusions shall be as
          specified by us in writing (“Subscription Provisions”). The
          Subscription Provisions are hereby through this reference construed as
          a part of these Terms. In the event of any conflict between these
          Terms of Service and the Subscription Provisions, the Subscription
          Provisions shall prevail. All recurring amounts shall be paid by you
          in a timely manner. In the event that we do not receive amounts
          requested from you in writing, we reserve the right to suspend
          Platform Services until all dues are cleared. If you do not clear your
          dues within sixty (60) days of notification regarding any amounts, we
          retain the right to terminate your Subscription without any liability
          to us. We reserve the right to increase Subscription rates at any
          time. Changes to standard Subscription rates may be informed through
          e-mail communications. Increases in Subscription rates will apply to
          existing accounts at the time of their renewal. Either party shall
          have the right to terminate the Subscription at any time by providing
          thirty (30) day prior notice of termination to the other. In the event
          of termination of your Subscription by either party, you shall have no
          claims against us or our affiliates. If we terminate your
          Subscription, other than as a result of your breach of these Terms Of
          Service, we may at our sole discretion refund toyou a pro rata portion
          of the Subscription fee paid to us, if any, for the unexpired portion
          of your Subscription. You must contact us to request changes from one
          Subscription to another. If you acquired a monthly Subscription and
          you wish to change to another Subscription offering, contact us
          through the details provided below at the end of this page. The change
          will be made effective when your current Subscription term ends.
          Please read our Cancellation Policy prior to purchasing our Services.
        </p>
      </div>
      <div id="subscription-authorization">
        <h3>11 SUBSCRIPTION AUTHORIZATION</h3>
        <p>
          You agree that you will provide a major credit (e.g., MasterCard,
          Visa, American Express) that we may charge for all Subscription fees
          or other amounts payable to us. With respect to such charges, you give
          the following authorization: You authorize automatic credit or charge
          card billing by Siftlink SA. You agree that the charges described
          above will be billed by Siftlink SA to the credit or charge card that
          you have provided to Siftlink SA in your Account information, or
          otherwise when you applied for the Subscription. You understand that
          all fees and charges are non-refundable. You agree that Siftlink SA
          will not be responsible for any expenses that you may incur resulting
          from exceeding my credit limit as a result of an automatic charge made
          under these Terms of Service.
        </p>
      </div>
      <div id="acceptable-use-policy">
        <h3>12 ACCEPTABLE USE POLICY</h3>
        <p>12.1 As a user of the Platform, you agree not to:</p>
        <p>
          12.1.1 systematically retrieve data or other Content from the Platform
          to create or compile, directly or indirectly, a collection,
          compilation, database, or directory without written permission from
          us.
        </p>
        <p>
          12.1.2 make any unauthorized use of the Platform, including collecting
          usernames and/or email addresses of users by electronic or other means
          for the purpose of sending unsolicited email, or creating user
          Accounts by automated means or under false pretenses.
        </p>
        <p>
          12.1.3 use a buying agent or purchasing agent to make purchases on the
          Platform.
        </p>
        <p>
          12.1.4 use the Platform for advertising or offering to sell goods and
          services.
        </p>
        <p>
          12.1.5 circumvent, disable, or otherwise interfere with
          security-related features of the Platform, including features that
          prevent or restrict the use or copying of any Platform Content or
          enforce limitations on the use of the Platform and/or the Platform
          Content or Content contained therein.
        </p>
        <p>
          12.1.6 engage in unauthorized framing of or linking to the Platform.
        </p>
        <p>
          12.1.7 trick, defraud, or mislead us and other users of the Platform,
          especially in any attempt to learn sensitive account information such
          as user passwords;
        </p>
        <p>
          12.1.8 make improper use of our support services or submit false
          reports of abuse or misconduct.
        </p>
        <p>
          12.1.9 engage in any automated use of the system, such as using
          scripts to send comments or messages, or using any data mining,
          robots, or similar data gathering and extraction tools.
        </p>
        <p>
          12.1.10 interfere with, disrupt, or create an undue burden on the
          Platform or the networks or services connected to the Platform.
        </p>
        <p>
          12.1.11 attempt to impersonate another user or person or use the
          username of another user.
        </p>
        <p></p>12.1.12 sell or otherwise transfer your profile.
        <p>
          12.1.13 use any information obtained from the Platform in order to
          harass, abuse, or harm another person.
        </p>
        <p>
          12.1.14 use the Platform as part of any effort to compete with us or
          otherwise use the Platform and/or the Platform Content for any
          revenue-generating endeavor or commercial enterprise.{" "}
        </p>
        <p>
          12.1.15 attempt to bypass any measures of the Platform designed to
          prevent or restrict access to the Platform, or any portion of the
          Platform.
        </p>
        <p>
          12.1.16 harass, annoy, intimidate, or threaten any of our employees or
          agents engaged in providing any portion of the Platform to you.
        </p>
        <p>
          12.1.17 delete the copyright or other proprietary rights notice from
          any Platform Content.
        </p>
        <p>
          12.1.18 copy or adapt the Platform’s software, including but not
          limited to Flash, PHP, HTML, JavaScript, or other code.
        </p>
        <p>
          12.1.19 upload or transmit (or attempt to upload or to transmit)
          viruses, Trojan horses, or other material, including excessive use of
          capital letters and spamming (continuous posting of repetitive text),
          that interferes with any party’s uninterrupted use and enjoyment of
          the Platform or modifies, impairs, disrupts, alters, or interferes
          with the use, features, functions, operation, or maintenance of the
          Platform.
        </p>
        <p>
          12.1.20 upload or transmit (or attempt to upload or to transmit) any
          material that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other
          similar devices (sometimes referred to as “spyware” or “passive
          collection mechanisms” or “pcms”).
        </p>
        <p>
          12.1.21 except as may be the result of a standard search engine or
          Internet browser usage, use, launch, develop, or distribute any
          automated system, including without limitation, any spider, robot,
          cheat utility, scraper, or offline reader that accesses the Platform,
          or using or launching any unauthorized script or other software.
        </p>
        <p>
          12.1.22 disparage, tarnish, or otherwise harm, in our opinion, us
          and/or the Platform.{" "}
        </p>
        <p>
          12.1.23 use the Platform in a manner inconsistent with any applicable
          laws or regulations.
        </p>
        <p>
          12.2 For the purposes of these Terms (“Personal Data”/”Personal
          Information”) means personal data within the meaning of the Regulation
          (EU) 2016/679 of the European Parliament and of the Council of April
          27, 2016 on the protection of natural persons with regard to the
          processing of personal data and on the free movement of such data and
          repealing Directive 95/46/EC (The General Data Protection Regulation –
          GDPR), processed by us, (or a Sub-Processor) on your behalf pursuant
          to, or in connection with, the Terms. You shall be responsible for the
          quality, accuracy, and legality of Personal Data and the means by
          which you acquired Personal Data. The Customer shall (a) as required
          by applicable law, provide notice to its customers (End-Users) and
          Agents, as well as obtain consent (if required) for processing and
          transferring Agents and End-Users Personal Data to you and your Third
          Party Service Providers; (b) be responsible for its employees,
          representatives, End-Users, and Agents that have access and use the
          Platform and Services; (c) comply with any limitations or restrictions
          set forth in the Terms. You acknowledge and agree that we will engage
          Sub-Processors to Process the Personal Data, including, and without
          limits, any Personal Data pursuant to the Terms within the European
          Economic Area, and in other countries and territories. Any third-party
          service providers utilized by us shall only be given access to the
          Account as is reasonably necessary to provide the Platform Services
          and will be subject to (a) confidentiality obligations which are
          commercially reasonable and substantially consistent with the industry
          standards; and (b) their agreement to comply with the data transfer
          restrictions applicable to us. The names of all current SubProcessors
          used for the Processing of Personal Data are specified in the Privacy
          Policy and, the Data Processing Addendum as may be applicable to
          you.All information, data, text, software, graphics, commentary,
          video, messages, or any other materials submitted by using the
          Platform Service is the property of the Customer. The Customer is
          solely responsible for the data collected, submitted, and generated by
          the Platform Services. We are not responsible for the way the Customer
          uses the Platform, Services, and such data. All Interactive Services
          (defined below) of the Platform shall be subject to the Section 13.
          You are responsible for the procurement and maintenance of the network
          connections that connect your network to the Platform Services,
          including, without limits, browser software that supports protocols
          utilized by us and to follow our standard operating procedure for
          accessing and utilizing the Platform Services. We shall not be liable
          to notify you, Agents, or End-Users of any upgrades, fixes, or
          enhancements to any such software, or for any compromise of data,
          transmitted across computer networks or telecommunications facilities
          which are not owned, operated, or controlled by us. We assume no
          responsibility for the reliability or performance of any network
          connections as described in this section.
        </p>
      </div>
      <div id="interactive-services">
        <h3>13 INTERACTIVE SERVICES</h3>
        <p>
          13.1 The Platform Service is a chatbot which we integrate as per your
          reasonable requirements and it provides services and updates which may
          added over time and such it contains interactive features
          (“Interactive Services”) that may allow End-Users to post, submit,
          publish, display, or transmit (hereinafter, “post”) content or
          materials (collectively, “User Contributions”) on or through the
          Platform Service. All User Contributions shall comply with the User
          Contribution Standards set out below in Section 14.
        </p>
        <p>
          13.2 You represent and warrant to cause the End-Users to adhere to the
          following terms by entering into a written agreement with them:
        </p>
        <p>
          13.2.1 End-Users own or control all rights in and to the User
          Contributions; and
        </p>
        <p>
          13.2.2 All of User Contributions do and will comply with these Terms
          of Service and User Contribution Standards.
        </p>
        <p>
          13.2.3 End-Users understand and acknowledge that they are responsible
          for any User Contributions they submit or contribute, and they, not
          you and us, have full responsibility for such content, including its
          legality, reliability, accuracy, and appropriateness.
        </p>
        <p>
          13.3 We are not responsible or liable to any third party for the
          content or accuracy of any User Contributions posted.
        </p>
      </div>
      <div id="user-contribution-standards">
        <h3>14 USER CONTRIBUTION STANDARDS</h3>
        <p>
          14.1 These User Contribution Standards apply to any and all User
          Contributions and use of Interactive Services. User Contributions must
          in their entirety comply with all applicable central, federal, state,
          local, and international laws, and regulations. Without limiting the
          foregoing, User Contributions must not:
        </p>
        <p>
          14.1.1 contain any material that is defamatory, obscene, indecent,
          abusive, offensive, harassing, violent, hateful, hurtful,
          inflammatory, or otherwise objectionable;
        </p>
        <p>
          14.1.2 promote sexually explicit or pornographic material, violence,
          or discrimination based on race, sex, religion, nationality,
          disability, sexual orientation, or age;
        </p>
        <p>
          14.1.3 infringe any patent, trademark, trade secret, copyright, or
          other intellectual property or other rights of any other person;
        </p>
        <p>
          14.1.4 violate the legal rights (including the rights of publicity and
          privacy) of others or contain any material that could give rise to any
          civil or criminal liability under applicable laws or regulations or
          that otherwise may be in conflict with these Terms.
        </p>
        <p>
          14.1.5 contain images or videos of persons without such person’s
          consent;
        </p>
        <p>14.1.6 be likely to deceive any person</p>
        <p>
          14.1.7 involve drugs, narcotics, steroids, controlled substances,
          pharmaceuticals or similar products or therapies that are either
          illegal, prohibited, or enjoined by an applicable regulatory body;
          legal substances that provide the same effect as an illegal drug; or
          other products, medical practices, or any related equipment or
          paraphernalia that have been found by an applicable regulatory body to
          cause consumer harm;
        </p>
        <p>
          14.1.8 promote any illegal activity, or advocate, promote, or assist
          any unlawful act;
        </p>
        <p>
          14.1.9 cause annoyance, inconvenience, or needless anxiety or be
          likely to upset, embarrass, alarm, or annoy any other person;
        </p>
        <p>
          14.1.10 funding a ransom, human trafficking or exploitation,
          vigilantism, bribes, or bounty;
        </p>
        <p>
          14.1.11 impersonate any person or misrepresent your identity or
          affiliation with any person or organization;
        </p>
        <p>
          14.1.12 involve or promote terrorism or, any activity that promote
          war, genocide, knives, explosives, ammunition, firearms, or other
          weaponry or accessories;
        </p>
        <p>
          14.1.13 involve annuities, investments, loans, equity or lottery
          contracts, lay-away systems, off-shore banking or similar
          transactions, money service businesses (including currency exchanges,
          check cashing or the like), pyramid schemes, “get rich quick schemes”
          (i.e., Investment opportunities or other services that promise high
          rewards), network marketing and referral marketing programs, debt
          collection or crypto-currencies;
        </p>
        <p>
          14.1.14 involve circumventing the designated method of payment as
          provided by us;
        </p>
        <p>14.1.15 involve credit repair or debt settlement services</p>
        <p>
          14.1.16 involve products or services that directly infringe or
          facilitate infringement upon the trademark, patent, copyright, trade
          secrets, or proprietary or privacy rights of any third party
        </p>
        <p>
          14.1.17 involve illegal sale or resale of brand name or designer
          products or services;
        </p>
        <p>
          14.1.18 any other activity that we may deem in our sole discretion to
          be in support of individuals and/or entities associated with alleged
          financial crimes including but not limited to corruption, bribery, tax
          evasion, fraud, and activities of a similar nature; or
        </p>
        <p>
          14.1.19 involve any election campaigns that are not run by a
          registered organization within the supported country;
        </p>
        <p>
          14.1.20 give the impression that they emanate from or are endorsed by
          us or any other person or entity if this is not the case.
        </p>
        <p>
          14.1.21 involve any other activity that we may deem in our sole
          discretion to be unacceptable
        </p>
      </div>
      <div id="monitoring-and-enforcement">
        <h3>15 MONITORING AND ENFORCEMENT</h3>
        <p>15.1 We have the right to:</p>
        <p>
          15.1.1 remove any User Contributions for any or no reason in our sole
          discretion;
        </p>
        <p>
          15.1.2 take any action with respect to any User Contribution that we
          deem necessary or appropriate in our sole discretion, including if we
          believe that such User Contribution violates the Terms, including the
          User Contribution Standards, infringes any intellectual property right
          or other rights of any person or entity, threatens the personal safety
          of users of the Platform or the public, or could create liability for
          us;
        </p>
        <p>
          15.1.3 disclose your identity or other information about you to any
          third party who claims that material posted by you violates their
          rights, including their intellectual property rights or their right to
          privacy;
        </p>
        <p>
          15.1.4 take appropriate legal action, including without limitation,
          referral to law enforcement, for any illegal or unauthorized use of
          the Platform; or
        </p>
        <p>
          15.1.5 terminate or suspend access to all or part of the Platform
          Service for any or no reason, including without limitation, any
          violation of these Terms.
        </p>
        <p>
          15.2 Without limiting the foregoing, we have the right to cooperate
          fully with any law enforcement authorities or court order requesting
          or directing us to disclose the identity or other information of
          anyone posting any materials on or through the Platform or otherwise
          accessing or using our Platform.
        </p>
        <p>
          15.3 YOU HEREBY IRREVOCABLY AND UNCONDITIONALLY WAIVE AND HOLD US AND
          OUR AFFILIATES, LICENSEES, AND SERVICE PROVIDERS HARMLESS FROM ANY AND
          ALL CLAIMS, DAMAGES, LIABILITIES AND COSTS RESULTING FROM ANY ACTION
          TAKEN BY US/ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A
          CONSEQUENCE OF, INVESTIGATIONS BY EITHER US/SUCH PARTIES OR LAW
          ENFORCEMENT AUTHORITIES.
        </p>
        <p>
          15.4 We have no liability or responsibility to anyone for performance
          or nonperformance of the activities described in this section.
        </p>
      </div>
      <div id="license">
        <h3>16 LICENSE</h3>
        <p>
          Subject to the Terms, Siftlink SA gives you a limited, revocable,
          nonsublicensable, non-exclusive, and non-transferable license to the
          Platform only for purposes of using the Platform in accordance with
          these Terms. It is expressly prohibited without the prior express
          permission from Siftlink SA to use, reproduce, modify, distribute, or
          store any Content for purposes other than using the Platform
          consistent with these Terms.
        </p>
      </div>
      <div id="intellectual-property-rights">
        <h3>17 INTELLECTUAL PROPERTY RIGHTS</h3>
        <p>
          17.1 The Platform contains Intellectual Property of Siftlink SA in the
          form of content, graphics, videos, audios, text, and any other digital
          content (“Platform Content”). This is an agreement for the use of
          Platform, and you are not granted a license to any Platform Content
          under this Terms. Except to the extent that applicable laws prevent us
          from doing so, you will not, directly or indirectly: (i) reverse
          engineer, decompile, disassemble, or otherwise attempt to discover the
          source code, object code, or underlying structure, ideas, or
          algorithms of, or found at or through the Platform; (ii) remove any
          proprietary notices or labels from the Platform Content; reproduce or
          copy the Platform Content or any part thereof; (iii) modify,
          translate, or create derivative works based on the Platform Content;
          (iv) copy, distribute, pledge, assign, or otherwise transfer or
          encumber rights to the Platform Content; (v) create any derivative
          product from any of the foregoing; (vi) without our express written
          permission, introduce automated agents or scripts to the Platform so
          as to produce multiple accounts, generate automated searches, requests
          and queries, or to strip or mine data from the Platform; or (vii)
          allow third parties to gain access to the Platform or to Platform
          Content in any manner other than as expressly permitted in this Terms
          of Service.
        </p>
        <p>
          17.2 You acknowledge and agree that the Platform, the names and logos
          and all related product and names, design marks and slogans, and all
          other material comprising the Platform, are the property of the
          Siftlink SA or its affiliates (collectively, the “Marks”). Unless
          stated otherwise, all Marks are protected as the copyright, trade
          dress, trademarks and/or other intellectual properties owned by us or
          by other parties that have licensed their material to us. You are not
          authorized to use any of the Marks in any advertising, publicity, or
          any other commercial manner without the prior written consent of
          Siftlink SA. Your use of the Platform confers no title or ownership in
          the Platform or the Marks and is not a sale of any rights in the
          Platform or the Marks. All ownership rights remain in Siftlink SA or
          its thirdparty suppliers, as the case may be.
        </p>
        <p>
          17.3 You are solely responsible for any content and other material
          that you submit, publish, transmit, or display on, though, or with our
          Platform (“Content”). You grant us a non-exclusive, worldwide,
          royalty-free, and fully paid license to use the Content, as necessary,
          for purposes of providing the Platform to you and other users of the
          Platform. All rights in and to the Content not expressly granted to us
          in these Terms are reserved by you.
        </p>
        <p>
          17.4 You acknowledge and agree that any comments, ideas and/or reports
          provided to us (“Feedback”) shall be the property of Siftlink SA and
          you hereby irrevocably transfer and assign to Siftlink SA such
          Feedback, and all associated intellectual property rights, provided
          however that you shall be free to use such Feedback in the ordinary
          conduct of your business.
        </p>
        <p>
          17.5 It is our policy to limit access to our Platform of users who
          infringe the intellectual property rights of others, as a consequence
          of which we shall terminate your Account. If you find that anything on
          our Platform infringes any copyright that you own or control, please
          contact us using the information provided in section 34.
        </p>
      </div>
      <div id="third-party-services">
        <h3>18 THIRD-PARTY SERVICES</h3>
        <p>
          The Platform provided by us may permit you to link to other websites,
          services, or resources on the Internet, and other websites, services
          or resources may contain links to the Platform. Also, Content may
          contain links to other websites, services, or resources on the
          Internet. When you access third party resources on the Internet, you
          shall do so at your own risk. These other resources are not controlled
          by us, and you agree that we shall not be responsible or liable for
          including but not limited to the content, functions, accuracy,
          legality, appropriateness or any other aspect of such websites or
          resources. The inclusion of any such link shall not imply our
          endorsement or any association in any way between their operators and
          us. You also agree that we will not be responsible or liable in any
          case, either directly or indirectly, for any damage or loss caused or
          alleged to be caused by or in connection with the use of or reliance
          on any such content, goods, or services available on or through any
          such website or resource.
        </p>
      </div>
      <div id="termination">
        <h3>19 TERMINATION</h3>
        <p>
          We reserve the right to terminate your access to all or any part of
          the Platform at any point of time, without providing any cause, with
          or without notice, effective immediately, which may result in the
          forfeiture and destruction of all information associated with your
          membership. You may terminate your Account if you wish to do so by
          placing a request on our Platform. Any such termination shall
          immediately revoke the license granted under Section 16, and you shall
          effective immediately be prohibited from accessing or using the
          Platform or Content for any reason. The provisions of these Terms
          which by their nature should survive termination shall survive
          termination, including but not limited to Licenses, warranty
          disclaimers, ownership provisions, limitations of liability and
          indemnification.
        </p>
      </div>
      <div id="release">
        <h3>20 RELEASE</h3>
        <p>
          To the maximum extent permissible by applicable law, you hereby
          absolutely release Siftlink SA and its affiliates as well as all other
          users of the Platform from responsibilities including but not limited
          to, claims, causes of action, liability, expenses, demands, and/or
          damages (actual and consequential) of all kinds and nature, known and
          unknown and claims of negligence that may arise from the use of or
          inability to use, or in relation to your use of and/or reliance on the
          Platform, including any disputes which may arise between users and the
          acts or omissions of third parties.
        </p>
      </div>
      <div id="warranty-disclaimer">
        <h3>21 WARRANTY DISCLAIMER</h3>
        <p>
          THE PLATFORM IS PROVIDED “AS IS”, “AS AVAILABLE” BASIS. THE USE OF
          PLATFORM IS AT THE USER’S SOLE RISK. THE PLATFORM IS PROVIDED WITHOUT
          WARRANTY, REPRESENTATION, OR GUARANTEE OF ANY KIND WHATSOEVER, EITHER
          EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF
          TITLE OR ACCURACY AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT AND ANY
          WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL
          OF WHICH ARE EXPRESSLY DISCLAIMED, WITH THE SOLE EXCEPTION OF
          WARRANTIES (IF ANY) WHICH CANNOT BE EXPRESSLY EXCLUDED UNDER
          APPLICABLE LAW. SIFTLINK SA, OUR DIRECTORS, EMPLOYEES, AGENTS,
          SUPPLIERS, PARTNERS, AND CONTENT PROVIDERS DO NOT WARRANT THAT: (I)
          THE PLATFORM IS OR WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME,
          INSTANCE OR LOCATION; (II) ANY DEFECTS MATERIAL OR NOT, OR ERRORS WILL
          BE CORRECTED; (III) ANY/ALL CONTENT OR SOFTWARE AVAILABLE AT OR
          THROUGH THE PLATFORM IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS;
          (IV) ANY/ALL INFORMATION IS COMPLETE, ACCURATE, UP-TO-DATE, OR
          RELIABLE; (V) ANY PARTICULAR SERVICE, CONTENT, OR PRODUCT REFERRED TO
          IN THE PLATFORM IS SAFE, APPROPRIATE, OR EFFECTIVE FOR YOUR AND/OR
          YOUR EMPLOYEES; (VI) THAT RESULTS OF USING THE SERVICES PROVIDED BY US
          WILL MEET YOUR REQUIREMENTS(VII) THE USE OF THE PLATFORM PROVIDED BY
          US SHALL COMPLY WITH ANY LAWS, RULES, REGULATIONS, REQUIREMENTS,
          POLICIES, QUALIFICATIONS, OR BEST PRACTICES, INCLUDING BUT NOT LIMITED
          TO PRIVACY LAWS, PROFESSIONAL LICENSURE, OR REIMBURSEMENT; (VIII) THE
          USE OF THE PLATFORM SHALL NOT RESULT IN LEGAL DUTIES OR LIABILITY. WE
          DO NOT GUARANTEE IN ANY INSTANCE THAT ANY PARTICULAR CONTENT OR
          MATERIAL SHALL BE MADE AVAILABLE THROUGH THE PLATFORM.
        </p>
      </div>
      <div id="indemnification">
        <h3>22 INDEMNIFICATION</h3>
        <p>
          You acknowledge and agree that you shall at all times defend,
          indemnify, and hold harmless us, our affiliates and each of our and
          our affiliates’ including but not limited to, respective officers,
          directors, contractors, employees, agents, suppliers, and
          representatives against all liabilities, claims, fees, costs,
          penalties or sanctions, losses, expenses, and interest of any nature,
          including reasonable attorneys’ fees, arising out of or which may
          relate to: (a) your use or misuse of, or access to, the Platform;
          (b)your violation of any privacy, professional, ethics, licensing, or
          consumer protection laws, rules, or regulations; (c) your misuse of
          anyone’s private, proprietary, or Personal data; (d) infringement by
          you (or any third party using your Account or identity in the
          Platform) of any intellectual property or other rights of any person
          or entity; or (e) otherwise in violation of these Terms in any way. It
          is our right to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by you, in which event you shall
          assist and cooperate with us in asserting any available defenses at
          your expense, including reasonable attorneys’ fees incurred by us.
        </p>
      </div>
      <div id="limitation-of-liability">
        <h3>23 LIMITATION OF LIABILITY</h3>
        <p>
          THE USE OF THE PLATFORM OFFERED BY US IS ENTIRELY AT YOUR OWN RISK. IN
          NO CASE SHALL WE, NOR OUR OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS,
          AGENTS, PARTNERS, SUPPLIERS, CONTENT PROVIDERS, OR ANY USERS BE LIABLE
          TO YOU UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE, OR ANY
          OTHER LEGAL OR EQUITABLE THEORY WITH REGARDS TO THE PLATFORM FOR: (I)
          ANY LOST PROFITS, LOSS IN REVENUE, LOSS OF GOODWILL, ANY DATA LOSS,
          LOSS OF USE, COST OF PROCURING SUBSTITUTE GOODS OR SERVICES, OTHER
          INTANGIBLE LOSSES, OR INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE,
          COMPENSATORY, EXEMPLARY, RELIANCE, PUNITIVE, LIQUIDATED, OR ANY
          SIMILAR CONSEQUENTIAL DAMAGES OF ANY TYPE WHATSOEVER (HOWEVER
          ARISING), (II) ANY, VIRUSES, BUGS, TROJAN HORSES, OR THE LIKE
          (REGARDLESS OF THE SOURCE OF ORIGIN), (III) ANY PERSONAL INJURY OR
          HARM, INCLUDING DEATH, WHICH IS CAUSED BY YOUR USE OR MISUSE OF THE
          PLATFORM, (IV) ANY CLAIMS, DEMANDS, OR DAMAGES ARISING OUT OF OR
          RELATING TO ANY DISPUTE BETWEEN YOU AND ANY OTHER USER OF THE
          PLATFORM, OR (V) ANY DIRECT DAMAGES IN EXCESS OF OR GREATER THAN ANY
          FEES PAID BY YOU FOR USING OF PARTICULAR SERVICES DURING THE
          IMMEDIATELY PREVIOUS THREE (3) MONTH PERIOD. REMEDIES UNDER THESE
          TERMS OF SERVICE ARE EXCLUSIVE AND ARE LIMITED TO THOSE EXPRESSLY
          PROVIDED FOR IN THESE TERMS OF SERVICE. NOTHING IN THESE TERMS OF
          SERVICE SHALL BE DEEMED TO EXCLUDE OR LIMIT YOUR LIABILITY IN RESPECT
          OF ANY INDEMNITY GIVEN BY YOU UNDER THESE TERMS.
        </p>
      </div>
      <div id="governing-law">
        <h3>24 GOVERNING LAW</h3>
        <p>
          The parties agree that the validity, operation, and performance of
          these Terms shall be governed by and interpreted in accordance with
          the laws of Switzerland applicable therein (notwithstanding conflict
          of law rules). The Parties do expressly and irrevocably concede to the
          jurisdiction of courts located at Epalinges Switzerland with respect
          to any matter or claim, suit, action or proceeding arising under or
          related to these Terms.
        </p>
      </div>
      <div id="dispute-resolution">
        <h3>25 DISPUTE RESOLUTION</h3>
        <p>
          Any dispute concerning the subject matter of these Terms, or the
          breach, termination, or validity thereof (a “Dispute”) will be settled
          exclusively in accordance with the procedures set forth herein. The
          party seeking resolution of a Dispute will first give notice in
          writing of the Dispute to the other party, setting forth the nature of
          the Dispute and a concise statement of the issues to be resolved. If
          the Dispute has not been resolved through good faith efforts and
          negotiations of senior officers or representatives of the parties
          within fifteen (15) days of receipt by the relevant party of the
          notice of Dispute, such notice will be deemed to be a notice of
          arbitration and the parties agree to submit the Dispute to a single
          arbitrator mutually agreeable to both parties. The venue of such
          arbitration shall be Epalinges Switzerland. In the event that the
          Parties cannot agree on a sole arbitrator, the arbitrator will be
          appointed by a judge of the appropriate court on application by either
          party to the Dispute. All decisions and awards rendered by the
          arbitrator will be final and binding upon the parties for all
          questions submitted to such arbitrator, and the costs associated with
          such submission shall be shared equally by the parties involved in the
          Dispute unless the arbitrator decides otherwise. The parties waive all
          rights of appeal, therefore to any court or tribunal, and agree that
          the only recourse by any party to any court will be for the purpose of
          enforcing an arbitration award.
        </p>
      </div>
      <div id="modification">
        <h3>26 MODIFICATION</h3>
        <p>
          We shall have the right to make modifications or replace any of the
          Terms, or suspend, change, or discontinue the Platform (including but
          not limited to, the availability of any featured content, or
          database,) at any time or instance by posting a notice through the
          Platform. We may also do so by sending you a notice via email, via the
          Platform, or by any other means of communication. We reserve the right
          to impose limits on certain features and services. We may if required
          to do so restrict your access to parts or all of the Platform without
          notice or liability. We endeavor to try and provide notice of
          modifications to these Terms. However, you also agree that it is also
          your responsibility to make reasonable efforts to be aware of such
          modifications. When you continue to use the Platform after
          notification of any modifications to the Terms shall mean acceptance
          of those modifications, and those modifications shall apply to your
          continued use of the Platform going forward. Your use of the Platform
          is subject to the Terms in effect at the time of such use.
        </p>
      </div>
      <div id="entire-agreement-and-severability">
        <h3>27 ENTIRE AGREEMENT AND SEVERABILITY</h3>
        <p>
          These Terms are the entire agreement between you and us with regards
          to the Platform. These Terms supersede all prior, contemporaneous
          communications and proposals made (whether oral, written, or
          electronic) between you and us with regards to the Platform. If any
          provisions mentioned in these Terms are found to be unenforceable or
          invalid, that particular provision or provisions will be limited or
          eliminated to the minimum extent necessary so that these Terms will
          otherwise remain in full force and effect and enforceable. In the
          event of the failure of either Party to exercise in any respect, any
          right provided for herein shall not be deemed a waiver of any further
          rights hereunder.
        </p>
      </div>
      <div id="relationship-of-the-parties">
        <h3>28 RELATIONSHIP OF THE PARTIES</h3>
        <p>
          You and Siftlink SA are independent contractors. These Terms shall not
          and do not create a partnership, franchise, joint venture, agency,
          fiduciary, or employment relationship of any kind between the Parties.
          You shall not have any authority of any kind to bind us in any
          respect. Unless expressly stated otherwise in these Terms, there are
          no third-party beneficiaries to the Terms. We do not have any special
          relationship you nor any fiduciary duty.
        </p>
      </div>
      <div id="force-majeure">
        <h3>29 FORCE MAJEURE</h3>
        <p>
          We will not be liable in any case for any failure or delay in the
          performance of our obligations for any reason hereunder if such
          failure results from: (a) any cause beyond our reasonable control,
          including but not limited to, mechanical, electronic or communications
          failure or degradation, denial-ofservice attacks, (b) any failure by a
          third-party hosting provider or utility provider, (c) strikes,
          shortages, riots, fires, acts of God, war, terrorism, and governmental
          action.
        </p>
      </div>
      <div id="assignment">
        <h3>30 ASSIGNMENT</h3>
        <p>
          You agree that these Terms are personal to you, and are not
          assignable, transferable or sublicensable by you. We reserve the right
          to assign, transfer or delegate any of our rights and obligations
          hereunder without obtaining consent.
        </p>
      </div>
      <div id="notices">
        <h3>31 NOTICES</h3>
        <p>
          All notices under these Terms shall be in writing Unless otherwise
          specified in these Term of Service. Notices to us shall be sent by
          email to <a href="mailto: info@siftlink.com">info@siftlink.com</a>.
          You shall ensure written confirmation of receipt for notice to be
          effective. Notices to you shall be sent to your last known email
          address (or the email address of your successor, if any) and/or to any
          email address that would be reasonably likely to provide notice to
          you, and such notice shall be effective upon transmission.
        </p>
      </div>
      <div id="no-waiver">
        <h3>32 NO WAIVER</h3>
        <p>
          32.1 Our failure to enforce any part of these Terms shall not
          constitute a waiver of our right to later enforce that or any other
          part of these Terms. Waiver of compliance in any particular instance
          does not mean that we will waive compliance in the future.
        </p>
      </div>
      <div id="interpretation">
        <h3>33 INTERPRETATION</h3>
        <p>
          The headers are provided only to make these Terms easier to read and
          understand.
        </p>
      </div>
      <div id="contact">
        <h3>34 CONTACT</h3>
        <p>
          You may get in touch with us through our Platform or the address given
          below:
          <br />
          Siftlink SA
          <br />
          Biopôle, Phenyl Building
          <br />
          Route de la Corniche 3<br />
          1066 Epalinges
          <br />
          Switzerland
          <br />
          <a href="mailto: info@siftlink.com">info@siftlink.com</a>
        </p>
      </div>
    </Container>
  );
}

export default TermsOfService;
