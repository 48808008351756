import { Fragment, useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import styles from "./TextWithImage.module.css";

function TextWithImage({ compound }) {
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [loadDescription, setLoadDescription] = useState(false);
  const [loadImage, setLoadImage] = useState(false);

  useEffect(
    function () {
      fetch(`https://api-02.siftlink.com/compound-descriptions/${compound}`, {
        headers: {
          Authorization: process.env.REACT_APP_NOT_SECRET_CODE,
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (d) {
          setDescription(d.data.compound_description);
          setLoadImage(true);
        })
        .catch(function (error) {
          console.log(`Error: ${error}`);
        });
    },
    [description, compound]
  );

  useEffect(
    function () {
      fetch(`https://api-02.siftlink.com/compound-images/link/${compound}`, {
        headers: {
          Authorization: process.env.REACT_APP_NOT_SECRET_CODE,
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (imageUrl) {
          setImage(imageUrl.data.compound_image);
          setLoadDescription(true);
        });
    },
    [image, compound]
  );

  if (!loadDescription && !loadImage) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "70vh", textAlign: "center" }}
      >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    );
  } else {
    return (
      <Fragment>
        <div className="row mb-5" style={{ height: "15%" }}>
          <h1
            className="text-capitalize mt-4 ms-2"
            style={{ paddingBottom: "1rem" }}
          >
            {compound} general information
          </h1>
          <hr className={`${styles.titleHr}`} />
        </div>
        <div
          className="d-flex flex-column align-items-center"
          style={{ height: "85%" }}
        >
          <div className="col-10">
            <p>{description ? description : "No description found"}</p>
          </div>
          <div className="col-9 col-lg-6">
            {image ? (
              <img
                src={image}
                style={{ width: "100%", display: "block" }}
                alt="compound"
              />
            ) : (
              "No image found"
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TextWithImage;
