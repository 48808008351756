import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import magnify from "../../../assets/magnify.png";
import filter from "../../../assets/filter.png";
import user from "../../../assets/user.png";
import "./navbar.css";

function Navbar() {
  return (
    <div className="d-none d-lg-block">
      <div className="d-flex justify-content-between my-4">
        <div className="nav-search">
          {/* <input
            className="border-danger border-opacity-25 py-2 ps-5 rounded w-100 font-400 text-sm"
            type="text"
            name=""
            id=""
            placeholder="Search Document"
          /> */}
          {/* <img className="magnify" src={magnify} alt="" />
          <img className="filter" src={filter} alt="" /> */}
        </div>
        <div className="d-flex gap-5">
          <div
            style={{ paddingTop: "11px" }}
            className="d-flex gap-4 justify-content-between align-items-center"
          >
            <p className="font-400 text-sm my-auto">
              {/* <a
                className="link-underline link-underline-opacity-0 text-black"
                href="#"
              >
                Home
              </a> */}
              <Link
                to="/home"
                style={{ fontSize: "1.3rem" }}
                className="link-underline link-underline-opacity-0 text-black"
              >
                Home
              </Link>
            </p>
            <p className="font-400 text-sm my-auto">
              {/* <a
                className="link-underline link-underline-opacity-0 text-black"
                href="#"
              >
                Subscription
              </a> */}
              <Link
                to="/subscription"
                style={{ fontSize: "1.3rem" }}
                className="link-underline link-underline-opacity-0 text-black"
              >
                Subscription
              </Link>
            </p>
            <p className="font-400 text-sm my-auto">
              {/* <a
                className="link-underline link-underline-opacity-0 text-black"
                href="#"
              >
                Contact
              </a> */}
              <Nav.Link
                className="link-underline link-underline-opacity-0 text-black"
                style={{ fontSize: "1.3rem" }}
                href="mailto:sensi@siftlink.com"
              >
                Contact
              </Nav.Link>
            </p>
          </div>
          {/* <img src={user} alt="" /> */}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
