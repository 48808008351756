import { useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { collection, query, getDocs } from "firebase/firestore";
import { getDatabase, ref, child, get } from "firebase/database";
import { useAuth } from "../contexts/AuthContext";
import { firestore } from "../firebase";
import LoadingAnimation from "./compoundOverview/LoadingAnimation";
import CustomNavigate from "./CustomNavigate";

function PrivateRoute() {
  let { currentUser } = useAuth();
  let [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  let [trialExpiredAt, setTrialExpiredAt] = useState(null);
  const dbRef = ref(getDatabase());
  const navigate = useNavigate();

  useEffect(
    function getSubscriptionStatus() {
      if (!currentUser) {
        navigate("/landing");
        return;
      }
      async function getUserSubscription() {
        let q = query(
          collection(firestore, "customers", currentUser.uid, "subscriptions")
        );

        let querySnapshot = await getDocs(q);
        querySnapshot.forEach(function (document) {
          if (
            document.data().status === "trialing" ||
            document.data().status === "active"
          ) {
            setSubscriptionStatus(true);
          }
        });

        get(child(dbRef, `users/${currentUser.uid}`))
          .then((snapshot) => {
            if (snapshot.exists()) {
              setTrialExpiredAt(snapshot.val().trialExpireAt);
              setLoading(false);
            } else {
              console.log("No data available");
              setLoading(false);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }

      getUserSubscription();
    },
    [subscriptionStatus, currentUser, dbRef]
  );

  function trialExpired() {
    return Date.now() > trialExpiredAt;
  }

  if (loading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "70vh", textAlign: "center" }}
      >
        <LoadingAnimation />
      </div>
    );
  }
  if (currentUser && currentUser.emailVerified) {
    if (!subscriptionStatus) {
      if (!trialExpired()) {
        return <Outlet />;
      }
      return <Navigate to="/landing" />;
    }
    return <Outlet />;
  } else {
    return <CustomNavigate to="/" />;
  }
}

export default PrivateRoute;
