import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import MaterialReactTable from "material-react-table";
import { Container, Button } from "react-bootstrap";
import { ExportToCsv } from "export-to-csv";
import * as XLSX from "xlsx/xlsx.mjs";

function Table() {
  const { uniprot } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "Compound",
        header: "Compound",
      },
      {
        accessorKey: "Inchi key",
        header: "Inchi-key",
      },
      {
        accessorKey: "Score",
        header: "Score",
      },
      {
        accessorKey: "Chemical class",
        header: "Chemical class",
      },
      {
        accessorKey: "Positive tox tests",
        header: "Positive tox tests",
      },
      {
        accessorKey: "Structural alerts",
        header: "Structural alerts",
      },
      {
        accessorKey: "Sweetness",
        header: "Sweetness",
      },
      {
        accessorKey: "Bitterness",
        header: "Bitterness",
      },

      {
        accessorKey: "Bioavailability",
        header: "Bioavailability",
      },
      {
        accessorKey: "Blood brain barrier",
        header: "Blood brain barrier",
      },

      {
        accessorKey: "Nr of organisms",
        header: "Nr of organisms",
      },
    ],
    []
  );

  let csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  let csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    // csvExporter.generateCsv(data);
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "data");

    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, `Sensi_export_${uniprot}.xlsx`);
  };

  useEffect(
    function () {
      fetch(`https://api-02.siftlink.com/uniprot/${uniprot}`, {
        headers: {
          Authorization: process.env.REACT_APP_NOT_SECRET_CODE,
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (responseData) {
          if (responseData === "No data found") {
            setData("The uniprot not found");
            setIsLoading(false);
          } else {
            setData(responseData.data);
            console.log(responseData.data);
            setIsLoading(false);
          }
        });
    },
    [uniprot]
  );

  if (isLoading) {
    return (
      <Container
        style={{ height: "70vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div style={{ textAlign: "center" }}>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      </Container>
    );
  } else {
    if (typeof data === "string")
      return (
        <div>
          <p
            style={{ textAlign: "center", height: "70vh" }}
            className="d-flex align-items-center justify-content-center"
          >
            {data}
          </p>
        </div>
      );
    return (
      <Container
        style={{ minHeight: "100%", marginBottom: "1rem" }}
        className="mt-5"
      >
        <MaterialReactTable
          columns={columns}
          data={data}
          enableRowSelection
          enableColumnOrdering
          renderTopToolbarCustomActions={({ table }) => (
            <Button onClick={handleExportData}>Export table</Button>
          )}
          muiTableHeadCellProps={{
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
              fontWeight: "bold",
              fontSize: "14px",
              // color: "red",
            },
          }}
          muiTableBodyProps={{
            sx: (theme) => ({
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#f0f0f0",
              },
            }),
          }}
        />
      </Container>
    );
  }
}

export default Table;
