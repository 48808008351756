import { Link } from "react-router-dom";

function BackToHome() {
  return (
    <div className="d-none d-md-block">
      <Link to="/">
        <i
          style={{ fontSize: "23px", color: "#282c33" }}
          className="bi bi-chevron-left"
        ></i>
      </Link>
    </div>
  );
}

export default BackToHome;
