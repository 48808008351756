import { useEffect } from "react";
import { Headings } from "../../components";
import { data } from "../../constants";
import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import "./Pricing.css";
import { Navigate, Link } from "react-router-dom";

import {
  getProducts,
  createCheckoutSession,
} from "@stripe/firestore-stripe-payments";
import { payments } from "../../../../firebase";
import { firestore } from "../../../../firebase";
import { useAuth } from "../../../../contexts/AuthContext";
import { collection, query, getDocs } from "firebase/firestore";
import { redirect, useNavigate } from "react-router-dom";

const Pricing = () => {
  const [key, setKey] = useState("weekly");
  let { currentUser } = useAuth();
  let [productPrices, setProductPrices] = useState(undefined);
  let [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  let [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(function getProductPrices() {
    async function getPrices() {
      const products = await getProducts(payments, {
        includePrices: true,
        activeOnly: true,
      });
      // console.log("PRODUCTS");
      // console.log(products);
      setProductPrices(products);
    }

    getPrices();
  }, []);

  useEffect(
    function getSubscriptionStatus() {
      async function getUserSubscription() {
        if (!currentUser) {
          return;
        }
        let q = query(
          collection(firestore, "customers", currentUser.uid, "subscriptions")
        );

        let querySnapshot = await getDocs(q);
        querySnapshot.forEach(function (document) {
          if (
            document.data().status === "trialing" ||
            document.data().status === "active"
          ) {
            setSubscriptionStatus(true);
          }
        });
        setLoading(false);
      }

      getUserSubscription();
    },
    [subscriptionStatus, currentUser]
  );

  async function checkout(priceId) {
    if (!currentUser) {
      navigate("/sign-up");
      return;
    }
    if (subscriptionStatus) {
      alert("Already subscribed");
      return;
    }
    setIsLoading(true);
    const session = await createCheckoutSession(payments, {
      price: priceId,
      success_url: `${window.location.origin}/`,
      cancel_url: `${window.location.origin}/landing`,
      allow_promotion_codes: true,
    });
    window.location.assign(session.url);
  }

  return (
    <div id="pricing" className="d-block py-5">
      <div className="container pb-5">
        <Headings title="Flexible Pricing Plans" />
        <div style={{ textAlign: "center", color: "white" }}>
          No credit card during free trial, cancel anytime
        </div>
        <Tabs
          id="uncontrolled-tab-example"
          className="mb-3"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="weekly" title="Weekly">
            <div className="row justify-content-center">
              {data.weeklyPricingData.map(
                (
                  {
                    title,
                    title2,
                    text0,
                    text1,
                    text2,
                    text3,
                    text4,
                    text5,
                    text6,
                    itemclass,
                    btnTxt,
                  },
                  index
                ) => (
                  <div
                    style={{ margin: "1rem 0" }}
                    className="col-lg-4 col-12"
                    key={index}
                  >
                    <div className={`row blight`}>
                      <div className="box">
                        <div>
                          <span>{title2}</span>
                          {title === "Customized Plan" ? (
                            <span style={{ fontSize: "28px" }}>{title}</span>
                          ) : (
                            <span>{title}</span>
                          )}
                          <ul className="list">
                            {text0 ? <li>{text0}</li> : ""}
                            <li>{text1}</li>
                            <li>{text2}</li>
                            {text3 ? <li>{text3}</li> : ""}
                            {text4 ? <li>{text4}</li> : ""}
                            {text5 ? <li>{text5}</li> : ""}
                            {text6 ? <li>{text6}</li> : ""}
                          </ul>
                          {/* {index === 2 ? (
                            <Button
                              href="mailto:sensi@siftlink.com"
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                            >
                              Talk to us
                            </Button>
                          ) : (
                            <Button
                              href="/sign-up"
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                            >
                              Start now your free 3-day trial
                            </Button>
                          )} */}
                          {index === 0 && (
                            <Button
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                              onClick={() =>
                                checkout(productPrices[7].prices[1].id)
                              }
                            >
                              Start now &gt;
                            </Button>
                          )}
                          {index === 2 && (
                            <Button
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                              onClick={() =>
                                checkout(productPrices[4].prices[0].id)
                              }
                            >
                              Start now &gt;
                            </Button>
                          )}
                          {index === 1 && (
                            <Button
                              href="mailto:sensi@siftlink.com"
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                            >
                              Talk to us &gt;
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </Tab>
          <Tab eventKey="monthly" title="Monthly">
            <div className="row  justify-content-center">
              {data.montlyPricingData.map(
                (
                  {
                    title,
                    discountTitle,
                    title2,
                    text0,
                    text1,
                    text2,
                    text3,
                    text4,
                    text5,
                    text6,
                    itemclass,
                    btnTxt,
                  },
                  index
                ) => (
                  <div
                    style={{ margin: "1rem 0" }}
                    className="col-lg-4 col-12"
                    key={index}
                  >
                    <div className={`row blight`}>
                      <div className="box">
                        <div>
                          <span>{title2}</span>
                          {title === "Customized Plan" ? (
                            <span style={{ fontSize: "28px" }}>{title}</span>
                          ) : (
                            <span>
                              {title}
                              <div style={{ fontSize: "1rem" }}>
                                <span id="discount">{discountTitle}</span>
                              </div>
                            </span>
                          )}
                          <ul className="list">
                            {text0 ? <li>{text0}</li> : ""}
                            <li>{text1}</li>
                            <li>{text2}</li>
                            {text3 ? <li>{text3}</li> : ""}
                            {text4 ? <li>{text4}</li> : ""}
                            {text5 ? <li>{text5}</li> : ""}
                            {text6 ? <li>{text6}</li> : ""}
                          </ul>
                          {/* {index === 2 ? (
                            <Button
                              href="mailto:sensi@siftlink.com"
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                            >
                              Talk to us
                            </Button>
                          ) : (
                            <Button
                              href="/sign-up"
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                            >
                              Start now your free 3-day trial
                            </Button>
                          )} */}
                          {index === 0 && (
                            <Button
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                              onClick={() =>
                                checkout(productPrices[3].prices[2].id)
                              }
                            >
                              Start now &gt;
                            </Button>
                          )}
                          {index === 2 && (
                            <Button
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                              onClick={() =>
                                checkout(productPrices[4].prices[0].id)
                              }
                            >
                              Start now &gt;
                            </Button>
                          )}
                          {index === 1 && (
                            <Button
                              href="mailto:sensi@siftlink.com"
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                            >
                              Talk to us &gt;
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </Tab>
          <Tab eventKey="yearly" title="Yearly">
            <div className="row  justify-content-center">
              {data.yearlyPricingData.map(
                (
                  {
                    title,
                    discountTitle,
                    title2,
                    text0,
                    text1,
                    text2,
                    text3,
                    text4,
                    text5,
                    text6,
                    itemclass,
                    btnTxt,
                  },
                  index
                ) => (
                  <div
                    style={{ margin: "1rem 0" }}
                    className="col-lg-4 col-12"
                    key={index}
                  >
                    <div className={`row blight`}>
                      <div className="box">
                        <div>
                          <span>{title2}</span>
                          {title === "Customized Plan" ? (
                            <span style={{ fontSize: "28px" }}>{title}</span>
                          ) : (
                            <span>
                              {title}
                              <div style={{ fontSize: "1rem" }}>
                                <span id="discount">{discountTitle}</span>
                              </div>
                            </span>
                          )}
                          <ul className="list">
                            {text0 ? <li>{text0}</li> : ""}
                            <li>{text1}</li>
                            <li>{text2}</li>
                            {text3 ? <li>{text3}</li> : ""}
                            {text4 ? <li>{text4}</li> : ""}
                            {text5 ? <li>{text5}</li> : ""}
                            {text6 ? <li>{text6}</li> : ""}
                          </ul>
                          {/* {index === 2 ? (
                            <Button
                              href="mailto:sensi@siftlink.com"
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                            >
                              Talk to us
                            </Button>
                          ) : (
                            <Button
                              href="/sign-up"
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                            >
                              Start now your free 3-day trial
                            </Button>
                          )} */}
                          {index === 0 && (
                            <Button
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                              onClick={() =>
                                checkout(productPrices[5].prices[2].id)
                              }
                            >
                              Start now &gt;
                            </Button>
                          )}
                          {index === 2 && (
                            <Button
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                              onClick={() =>
                                checkout(productPrices[6].prices[1].id)
                              }
                            >
                              Start now &gt;
                            </Button>
                          )}
                          {index === 1 && (
                            <Button
                              href="mailto:sensi@siftlink.com"
                              className="btn-subs"
                              variant="primary"
                              size="lg"
                            >
                              Talk to us &gt;
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Pricing;
