import { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import MaterialReactTable from "material-react-table";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CompoundOverview from "./CompoundOverview";
import LoadingAnimation from "./LoadingAnimation";
import ResultsTableButton from "./ResultsTableButton";
import AccessDenied from "./AccessDenied";
import { useAuth } from "../../contexts/AuthContext";

function ProjectReport() {
  let {currentUser} = useAuth()
  const { projectId } = useParams();
  let [defaultIndex, setDefaultIndex] = useState(0);
  let [data, setData] = useState(undefined);
  let [data1, setData1] = useState(undefined);
  let [cid, setCid] = useState(undefined);
  let [isLoading, setIsLoading] = useState(true);
  let [rowSelection, setRowSelection] = useState({});
  let [hasAccess, setHasAccess] = useState(false)

  let columns = useMemo(
    () => [
      {
        accessorKey: "cid",
        header: "Identifier",
        Cell: ({ cell }) => (
          <a href={`https://pubchem.ncbi.nlm.nih.gov/compound/${cell.getValue()}`} target="_blank" rel="noreferrer">{cell.getValue()}</a>
        ),
        size: 100,
      },
      {
        accessorKey: "image_url",
        header: "Structure",
        Cell: ({ cell }) => (
          <img src={cell.getValue()} alt="compound" height={200} width={200} />
        ),
        size: 100,
      },
      {
        accessorKey: "score",
        header: "Docking score",
        size: 100,
      },
      {
        accessorKey: "molecular_weight",
        header: "Molecular weight",
        size: 100,
      },
    ],
    []
  );

  useEffect(function saveSelectedRows() {
    fetch("https://api-02.siftlink.com/report/add-selected-rows", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "email": "christos.goulas.siftlink@gmail.com",
        "id": 1746166377,
        "selected_rows": rowSelection
      }),
    })
  }, [rowSelection]);

  useEffect(
    function getProjectInfo() {
      fetch(`https://api-02.siftlink.com/report/${projectId}`)
        .then(function (response) {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then(function (responseData) {
          if (responseData) {
            setData(responseData.data.data);
            checkIfUserExist(responseData.data.clients)
            setIsLoading(false);
          }
        });

        function checkIfUserExist(clients) {
          for(let client of clients) {
            if(client["user"] === currentUser.email) {
              setRowSelection(client["selectedRows"])
              setHasAccess(true)
            }
          }
        }
    },
    [currentUser.email, projectId]
  );

  if (isLoading) {
    return <LoadingAnimation />;
  }
  
  if(!hasAccess) {
    return <AccessDenied />
  }

  return (
    <Container
      style={{ minHeight: "70vh", marginBottom: "1rem" }}
      className="mt-5"
    >
      <Tabs
        selectedIndex={defaultIndex}
        onSelect={(index) => setDefaultIndex(index)}
      >
        <TabList>
          <Tab>Results table</Tab>
          <Tab>Compound overview</Tab>
        </TabList>

        <TabPanel>
            <MaterialReactTable 
              columns={columns} 
              data={data} 
              enableRowActions 
              enableSelectAll={false}
              enableRowSelection
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
              renderRowActions={({ row }) => (
                <ResultsTableButton
                  row={row}
                  setCid={setCid}
                  setData1={setData1}
                  setDefaultIndex={setDefaultIndex}
                />
              )}
              muiTableHeadCellProps={
                {
                  sx: {
                    color: "white",
                    backgroundColor: "#5d20d3"
                  }
                }
              }
            />
        </TabPanel>
        <TabPanel>
          {data1 === undefined ? (
            <div>No data</div>
          ) : (
            <>
              <h2 className="text-center p-3">Chemical summary</h2>
              <CompoundOverview data1={data1} cid={cid} />
            </>
          )}
        </TabPanel>
      </Tabs>
    </Container>
  );
}

export default ProjectReport;
