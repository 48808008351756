import { Link } from "react-router-dom";
import logobw from "../../../assets/logo-bw.png";
import linkedin from "../../../assets/linkedin.png";
import "./footer.css";

function Footer() {
  return (
    <section style={{ backgroundColor: "#322B2B", color: "#FFFFFF" }}>
      <div className="footer mx-auto pt-5 pb-3">
        <div className="d-flex justify-content-between gap-3">
          <div className="d-none d-lg-block">
            <img src={logobw} alt="" />
          </div>
          <div>
            <p className="font-600 fs-5 fw-semibold text-light mb-0">
              Siftlink SA
            </p>
            <br />
            <p className="font-400 text-sm mb-2">Biopôle, Phenyl Building</p>
            <p className="font-400 text-sm mb-2">Route de la Corniche 3</p>
            <p className="font-400 text-sm mb-2">1066 Epalinges</p>
            <p className="font-400 text-sm mb-2">Switzerland</p>
            <p className="font-400 text-sm mb-2">info@siftlink.com</p>
          </div>
          <div>
            <p className="font-600 fs-5 fw-semibold text-light mb-0">
              Quick Links
            </p>
            <br />
            <p className="font-400 text-sm mb-2">
              <Link
                to="/privacy-policy"
                className="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-75-hover"
              >
                Privacy Policy
              </Link>
            </p>
            <p className="font-400 text-sm mb-2">
              <Link
                to="/terms-of-service"
                className="link-light link-offset-2 link-underline-opacity-0 link-underline-opacity-75-hover"
              >
                Terms of Service
              </Link>
            </p>
          </div>
          <div>
            <p className="font-600 fs-5 fw-semibold text-light mb-0">Find Us</p>
            <br />
            <a href="https://www.linkedin.com/company/siftlink/">
              <img src={linkedin} alt="Linkedin" />
            </a>
          </div>
        </div>
        <hr
          style={{
            backgroundColor: "#322B2B",
            marginTop: "40px",
            marginBottom: "15px",
          }}
        />
        <p className="font-400 text-sm text-light text-center">
          © Copyright {new Date().getFullYear()} | All rights reserved by
          Siftlink
        </p>
      </div>
    </section>
  );
}

export default Footer;
