import { ThreeDots } from "react-loader-spinner";

function LoadingAnimation() {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: "70vh", textAlign: "center" }}
    >
      <div>
        <img
          src="/log-siflink-03-yk-cropped.svg"
          width={170}
          alt="siftlink logo"
        />
      </div>
      <div>
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#7E7E7E"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    </div>
  );
}

export default LoadingAnimation;
