import React, { Fragment } from "react";
import testData from "./tableTestData.json";
import styles from "./TableTest.module.css"
function TableTest(props){
    return(
    <div className="container">
        {testData.map(function(dataItem){
            return(
                <Fragment>
                    <h1 className="py-3 fs-3"><a className="text-decoration-none" href="#">{dataItem.Title.replace(/[^a-zA-Z\d\s:]/g,"")}</a></h1>
        <div>
            <ul className={`d-flex ${styles.liStyles}`}>
            <li className="mx-3">{dataItem.Publication_number.replace(/[^a-zA-Z\d\s:]/g,"")}</li>
            <li className="mx-3">{dataItem.Legal_Status.replace(/[^a-zA-Z\d\s:]/g,"")}</li>
            <li className="mx-3">{dataItem.Publication_date.replace(/[^a-zA-Z\d\s:]/g,"")}</li>
            <li className="mx-3">{dataItem.Applicant.replace(/[^a-zA-Z\d\s:]/g,"")}</li>
            </ul>
            <p><strong>Abstract:</strong> {dataItem.Abstract.replace(/[^a-zA-Z\d\s:]/g,"")}</p>
        </div>
                </Fragment>
            )
        })}
        
    </div>
    )
}

export default TableTest;