import { useState, useEffect, Fragment } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { ThreeDots } from "react-loader-spinner";
import styles from "./SingleBar.module.css";

function BarWithContent({ apiData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(function () {
    fetch(`https://api-02.siftlink.com/${apiData.url}`, {
      headers: {
        Authorization: process.env.REACT_APP_NOT_SECRET_CODE,
      },
    })
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then(function (responseData) {
        if (responseData) {
          setData(responseData);
        }
        setIsLoading(false);
      });
  }, []);

  // function constructData(responseData) {
  //   const chartData = [];
  //   for (let i = 0; i < responseData.data[`${apiData.xAxis}`].length; i++) {
  //     chartData.push({
  //       [apiData.xAxis]: responseData.data[`${apiData.xAxis}`][i],
  //       [apiData.yAxis]: responseData.data[`${apiData.yAxis}`][i],
  //     });
  //   }
  //   return {
  //     chartData,
  //     title: responseData.data["slide_title"],
  //   };
  // }

  if (isLoading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "70vh", textAlign: "center" }}
      >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    );
  } else {
    if (Object.keys(data["data"]["data"]).length) {
      return (
        <Fragment>
          <div className="row" style={{ height: "15%" }}>
            <h1 className="text-capitalize mt-4 ms-2">
              {data.data.slide_title}
            </h1>
            <hr className={`${styles.titleHr}`} />
          </div>
          <div
            className="row"
            style={{ height: "85%", width: "95%", margin: "0 auto" }}
          >
            <ResponsiveBar
              data={data.data.data}
              keys={data.data.key}
              indexBy={data.data.indexBy}
              theme={{ fontSize: "14px" }}
              margin={{ top: 10, right: 40, bottom: 140, left: 40 }}
              padding={0.3}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={({ data }) => data.color}
              borderRadius={5}
              ariaLabel="Nivo bar chart demo"
              axisBottom={{
                tickRotation: 25,
              }}
            />
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="row" style={{ height: "15%" }}>
            <h1 className="text-capitalize mt-4 ms-2">
              {data.data.slide_title}
            </h1>
            <hr className={`${styles.titleHr}`} />
          </div>

          <div className="d-flex align-items-center justify-content-center h-75">
            <p className="mb-4 fs-3">No data found</p>
          </div>
        </Fragment>
      );
    }
  }
}

export default BarWithContent;
