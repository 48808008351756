import { Modal, Button } from "react-bootstrap";

function DeleteNoteModal({
  showModal,
  setShowModal,
  setIsDeletingSession,
  uid,
  timestamp,
  title,
}) {
  function handleClose() {
    setShowModal(false);
  }

  function handleDeleteSession(uid, timestamp) {
    setIsDeletingSession(true);
    fetch(`https://sensi-api.siftlink.com/sessions`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: uid,
        timestamp: parseInt(timestamp),
      }),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (res) {
        setIsDeletingSession(false);
        setShowModal(false);
      })
      .catch(function (error) {
        console.error("Delete error:", error);
      });
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You are going to delete: <b>{title}</b>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => handleDeleteSession(uid, timestamp)}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteNoteModal;
