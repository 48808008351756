import React from "react";
import "./Sensi.css";
import { images } from "../../constants";
import Button from "react-bootstrap/Button";

const Sensi = () => {
  return (
    <div className="section-padding">
      <div className="container">
        <div className="row sensi">
          <div className="col-lg-7 d-lg-flex order-2 order-lg-1">
            <img src={images.sensi} alt="" />
          </div>
          <div className="col-lg-5 pt-3 order-lg-2">
            <h2 style={{ fontSize: "48px", fontWeight: "800" }}>
              More than a report
            </h2>
            <p className="pt-2 pb-4" style={{ fontSize: "18px" }}>
              Your document specific assistant gathers knowledge on your report
              topic and answers your questions with specific information. A
              report might be one or two pages but is backed by hundreds of
              research documents. Ask the assistant to tap into this vast
              knowledge, not just the report, for real-time, precise answers
              during meetings, presentations or while preparing your due
              diligence.
            </p>
            <Button
              href="/sign-up"
              className="flex-btn mt-4"
              variant="primary"
              size="lg"
            >
              Start now &gt;
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sensi;
