import { Fragment, useState } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";

function SpecificAssistant() {
  let [isEditorVisible, setIsEditorVisible] = useState(true);
  let [isChatVisible, setIsChatVisible] = useState(false);

  function handleOnClick() {
    setIsEditorVisible(function (previousValue) {
      return !previousValue;
    });
    setIsChatVisible(function (previousValue) {
      return !previousValue;
    });
  }

  return (
    <Fragment>
      <Container>
        <Button className="d-lg-none" onClick={handleOnClick}>
          Change
        </Button>
        <Row>
          <Col
            className={`${isEditorVisible ? "d-block" : "d-none"} d-lg-block`}
            xs={12}
            lg={6}
            style={{ border: "1px solid black" }}
          >
            first col
          </Col>
          <Col
            className={`${isChatVisible ? "d-block" : "d-none"} d-lg-block`}
            xs={12}
            lg={6}
            style={{ border: "1px solid red" }}
          >
            second col
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default SpecificAssistant;
