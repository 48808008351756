// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PatentSummaryTemp_descriptionDiv__d9Sux span {\n  color: #990099;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/components/PatentSummaryTemp.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".descriptionDiv span {\n  color: #990099;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionDiv": "PatentSummaryTemp_descriptionDiv__d9Sux"
};
export default ___CSS_LOADER_EXPORT___;
