import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../../assets/logo.png";
import magnify from "../../../assets/magnify.png";
import filter from "../../../assets/filter.png";
import user from "../../../assets/user.png";

function BSnav() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary d-md-block d-lg-none"
    >
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#features">
              <div className="nav-search">
                <input
                  className="border-danger border-opacity-25 py-2 ps-5 rounded w-100 font-400 text-sm"
                  type="text"
                  name=""
                  id=""
                  placeholder="Search Document"
                />
                <img className="magnify" src={magnify} alt="" />
                <img className="filter" src={filter} alt="" />
              </div>
            </Nav.Link>
            <Nav.Link href="#pricing">Home</Nav.Link>
            <Nav.Link href="#pricing">Subscription</Nav.Link>
            <Nav.Link href="#pricing">Contacts</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BSnav;
