import { useState } from "react";
import { Form, Button, Card, Container, Alert } from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { database } from "../firebase";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { ref, set } from "firebase/database";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import sLogo from "../assets/bgWhiteLogo.png";
import { Fragment } from "react";

function LogIn() {
  const [isLoginError, setIsLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  let navigate = useNavigate();
  const { login, authWithGoogle } = useAuth();
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Enter a valid email").required("Email required"),
    password: Yup.string()
      .min(8, "Password should be 8 characters minimum.")
      .required("Password required"),
  });

  function onSubmit(values, props) {
    props.setSubmitting(true);
    login(auth, values.email, values.password)
      .then(function (user) {
        if (user.user.emailVerified) {
          window.location.href = `/`;
        } else {
          setIsLoginError(true);
          setErrorMessage("Please verify your email and log in again");
        }
      })
      .catch(function (error) {
        setIsLoginError(true);
        setErrorMessage(
          "An error occurred: " +
            error.code.replace("auth/", "").replaceAll("-", " ")
        );
      });
    props.setSubmitting(false);
    props.resetForm();
  }

  function handleAuthWithGoogle() {
    authWithGoogle(auth, provider)
      .then((user) => {
        set(ref(database, "users/" + user.user.auth.currentUser.uid), {
          username: user.user.auth.currentUser.displayName,
          email: user.user.auth.currentUser.email,
        });
        navigate("/");
      })
      .catch((error) => {
        setIsLoginError(true);
        setErrorMessage(
          "An error occurred: " +
            error.code.replace("auth/", "").replaceAll("-", " ")
        );
      });
  }

  return (
    <Fragment>
      {isLoginError ? (
        <Alert
          variant="danger"
          style={{
            textAlign: "center",
            borderRadius: "0",
            maxWidth: "400px",
            margin: "0 auto",
          }}
        >
          {errorMessage}
        </Alert>
      ) : (
        <></>
      )}
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="w-100" style={{ maxWidth: "400px" }}>
          <Card>
            <Card.Body>
              <div
                className="mb-4"
                style={{
                  borderRadius: "5px",
                  height: "65px",
                }}
              >
                <img
                  style={{
                    width: "45%",
                    display: "block",
                    margin: "0 auto",
                    height: "100%",
                  }}
                  src={sLogo}
                  alt="logo"
                />
              </div>
              <h2 className=" mb-4 text-center">Sign in to Sensi</h2>
              <div className="d-flex flex-column mt-5">
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "2px solid #4285f4",
                  }}
                  onClick={handleAuthWithGoogle}
                >
                  <i
                    className="bi bi-google"
                    style={{ color: "#4285f4", marginRight: "0.5rem" }}
                  ></i>{" "}
                  Continue with Google
                </Button>
              </div>

              <div className="d-flex mt-5 mb-4">
                <hr style={{ width: "40%" }} />
                <div style={{ margin: "0 10%" }}>OR</div>
                <hr style={{ width: "40%" }} />
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <FormikForm>
                    <Form.Group id="email">
                      <Form.Label>Email</Form.Label>
                      <Field
                        style={{
                          padding: ".375rem .75rem",
                          borderRadius: ".375rem",
                        }}
                        as={Form.Control}
                        type="email"
                        name="email"
                        required
                      />
                      <Form.Text
                        style={{
                          color: "red",
                          padding: "8px",
                          display: "block",
                        }}
                      >
                        {<ErrorMessage name="email" />}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group id="password">
                      <Form.Label>Password</Form.Label>
                      <Field
                        as={Form.Control}
                        type="password"
                        name="password"
                        required
                      />
                      <Form.Text
                        style={{
                          color: "red",
                          padding: "8px",
                          display: "block",
                        }}
                      >
                        {<ErrorMessage name="password" />}
                      </Form.Text>
                    </Form.Group>
                    <Button
                      style={{
                        textTransform: "none",
                        backgroundColor: "#f62929",
                        border: "none",
                      }}
                      className="w-100 mt-2"
                      type="submit"
                      disabled={props.isSubmitting}
                    >
                      {props.isSubmitting ? "Loading" : "Log In"}
                    </Button>
                  </FormikForm>
                )}
              </Formik>
            </Card.Body>
          </Card>
          <div className="w-100 text-center mt-2">
            Do not have an account? <Link to="/sign-up">sign up</Link>
          </div>
          <div className="w-100 text-center mt-2">
            <Link style={{ textDecoration: "none" }} to="/reset-password">
              Forgot your password?{" "}
            </Link>
          </div>
        </div>
      </Container>
    </Fragment>
  );
}

export default LogIn;
