import { useState, useEffect } from "react";
import BackToHome from "./BackToHome";
import DisplayDate from "./DisplayDate";
import convertTimestamp from "../../utils/convertTimestamp";
import { Image } from "react-bootstrap";

function SessionTitle({ session }) {
  let [titleValue, setTitleValue] = useState(
    session ? session.sessionTitle : "New notedsa"
  );

  useEffect(() => {
    document.title = titleValue;

    return () => {
      document.title = "Sensi";
    };
  }, [titleValue]);

  function handleOnChange(event) {
    setTitleValue(event.target.value);
    fetch(`https://sensi-api.siftlink.com/sessions/update-title`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: session.uid,
        timestamp: parseInt(session.timestamp),
        sessionTitle: event.target.value,
        modifiedAt: convertTimestamp(Date.now()).toDateAndHour,
      }),
    })
      .then(function (res) {
        // console.log(res);
      })
      .catch(function (res) {
        // console.log(res);
      });
  }

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ height: "5vh" }}
    >
      <div>
        <div className="d-none d-md-block p-3">
          <Image width={100} height={31} src="/log-siflink-03-yk-cropped.svg" />
        </div>
      </div>
      <div className="d-flex align-items-center" style={{ height: "36px" }}>
        <BackToHome />
        <div>
          <input
            style={{
              padding: 0,
              marginLeft: "0.5rem",
              resize: "none",
              border: "0px",
              borderBottom: "1px solid black",
              outline: "none",
              textAlign: "center",
              fontSize: "15px",
              maxWidth: "400px",
              // backgroundColor: "#edebe9",
            }}
            value={titleValue}
            onChange={handleOnChange}
            maxLength={60}
            name="sessionTitle"
          />
          {/* <DisplayDate timestamp={session.timestamp} /> */}
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default SessionTitle;
