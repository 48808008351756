import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import Slider from "./components/Slider";
import Table from "./components/Table";
import LogIn from "./components/LogIn";
import SignUp from "./components/SignUp";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import NotFound from "./components/NotFound";
import NavAndFooter from "./components/NavAndFooter";
import TableTest from "./components/TableTest";
import PatentSummaryTemp from "./components/PatentSummaryTemp";
import Survey from "./components/Survey";
import ResetPassword from "./components/ResetPassword";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
// import Subscription from "./Subscription/components/Subscription";
import Subscription from "./components/Subscription/Subscription";
import AccoutnManagment from "./components/AccountManagment";
import ProjectReport from "./components/projectReport/ProjectReport";
import Editor from "./components/Editor/Editor";
import HomePageTemp from "./components/homePage/HomepageTemp";
import CompoundOverview from "./components/compoundOverview/CompoundOverview";
import MyEditor from "./components/EditorTemp/Editor";
import NewEditor from "./components/EditorTemp/NewEditor";
// import Landing from "./components/Landing/Landing";
import NewHomePage from "./components/NewHomePage/NewHomePage";
import Landing from "./components/Landing/Landing";
import SpecificAssistant from "./components/specificAssistant/SpecificAssistant";
import TempChat from "./components/TempChat/TempChat";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<HomePageTemp />} exact />
          <Route element={<NavAndFooter />}>
            <Route
              path="/patent/:publicationNumber"
              element={<PatentSummaryTemp />}
            />
            <Route path="/slider/:compound" element={<Slider />}></Route>
            <Route path="/table/:uniprot" element={<Table />}></Route>
            <Route path="/table-test" element={<TableTest />}></Route>
            <Route path="/feedback" element={<Survey />}></Route>
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/account" element={<AccoutnManagment />} />
            <Route
              path="/compound-overview/:cid"
              element={<CompoundOverview />}
            />
            <Route
              path="/project-report/:projectId"
              element={<ProjectReport />}
            />
            <Route path="/temp" element={<HomePageTemp />} />
            <Route path="/test" element={<MyEditor />} />
            <Route path="/new-editor" element={<NewEditor />} />
          </Route>
          <Route path="/editor/:timestamp" element={<Editor />} />
        </Route>
        <Route path="/new-home-page" element={<NewHomePage />} />
        {/* <Route path="/" element={<Landing />} /> */}
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/sign-in" element={<LogIn />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/specific" element={<SpecificAssistant />} />
        <Route path="/chat" element={<TempChat />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
