import { Container } from "react-bootstrap"

function AccessDenied() {
    return (
        <Container>
            <div className="d-flex align-items-center justify-content-center" style={{ height: "70vh", textAlign: "center" }}>
                <h3>Access denied</h3>
            </div>
        </Container>
    )
}

export default AccessDenied