// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LandingPage_description__QIMpL {\n  margin-bottom: 2rem;\n}\n\n.LandingPage_cards__VJQye {\n  background-color: #ecf1f2;\n  box-shadow: 20px 20px 33px rgb(0 0 0 / 30%);\n  min-height: 250px;\n  padding: 1rem;\n  border-radius: 5px;\n}\n\n@media all and (min-width: 768px) {\n  .LandingPage_cards__VJQye {\n    min-height: 200px;\n  }\n}\n\n@media all and (min-width: 992px) {\n  .LandingPage_description__QIMpL {\n    max-width: 30%;\n  }\n  .LandingPage_cards__VJQye {\n    min-height: 400px;\n  }\n}\n\n@media all and (min-width: 1200px) {\n  .LandingPage_cards__VJQye {\n    min-height: 350px;\n  }\n}\n\n@media all and (min-width: 1400px) {\n  .LandingPage_cards__VJQye {\n    min-height: 285px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/LandingPage.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,2CAA2C;EAC3C,iBAAiB;EACjB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,cAAc;EAChB;EACA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".description {\n  margin-bottom: 2rem;\n}\n\n.cards {\n  background-color: #ecf1f2;\n  box-shadow: 20px 20px 33px rgb(0 0 0 / 30%);\n  min-height: 250px;\n  padding: 1rem;\n  border-radius: 5px;\n}\n\n@media all and (min-width: 768px) {\n  .cards {\n    min-height: 200px;\n  }\n}\n\n@media all and (min-width: 992px) {\n  .description {\n    max-width: 30%;\n  }\n  .cards {\n    min-height: 400px;\n  }\n}\n\n@media all and (min-width: 1200px) {\n  .cards {\n    min-height: 350px;\n  }\n}\n\n@media all and (min-width: 1400px) {\n  .cards {\n    min-height: 285px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "LandingPage_description__QIMpL",
	"cards": "LandingPage_cards__VJQye"
};
export default ___CSS_LOADER_EXPORT___;
