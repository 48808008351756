import images from "./images";

const Menu = [
  {
    text: "How it works",
    link: "#works",
  },
  {
    text: "Pricing",
    link: "#pricing",
  },
];
const ServicesData = [
  {
    titleone: "Start",
    text: "Paste your text, URLs, or ask Sensi to create content.",
    itemclass: "blight",
    imgURL: images.work01,
  },
  {
    titleone: "Build",
    text: 'Click the "Generate Assistant" button to build your domain specific assistant.',
    itemclass: "bgreen",
    imgURL: images.work02,
  },
  {
    titleone: "Use",
    text: "Create fact-based reports or real-time responses with your specialized assistant.",
    itemclass: "bdark",
    imgURL: images.work03,
  },
];
const weeklyPricingData = [
  {
    title: "$8.00",
    title2: "Basic",
    itemclass: "blight",
    text0: "Unlimited documents, reports, interactions and references.",
    text1: "Unlimited custom assistants, with up to 100 documents.",
    text2: "Access to GPT-4 level of generic assistant.",
    // text3: "Unlimited scientific and patent references.",
    // text4: "Unlimited access to GPT-4 level general purpose assistant.",
    btnTxt: "Start Now",
  },
  // {
  //   title: "$29.00",
  //   title2: "Professional account",
  //   itemclass: "bgreen",
  //   text1: "Everything in Basic, plus:",
  //   text2: "Report specific assistant.",
  //   text3: "Automated monitoring.",
  //   text4: "up to 100 documents per assistant.",
  //   btnTxt: "Start Now",
  // },
  {
    title: "Customized Plan",
    title2: "Creator",
    itemclass: "bdark",
    text1: "Custom assistants, with up to 1 million documents.",
    text2:
      "integration with proprietrary Siftlink databases, pipelines and predicitve models.",
    text3: "On premises deployment.",
    text4: "Monetization options.",
    // text5: "Integration with Siftlink proprietrary databases.",
    // text6: "Integration with Siftlink proprietrary predictive models.",
    btnTxt: "Get in Touch",
  },
];
const montlyPricingData = [
  {
    title: "$20.00",
    discountTitle: "save 38%",
    title2: "Basic",
    itemclass: "blight",
    text0: "Unlimited documents, reports, interactions and references.",
    text1: "Unlimited custom assistants, with up to 100 documents.",
    text2: "Access to GPT-4 level of generic assistant.",
    // text3: "Unlimited scientific and patent references.",
    // text4: "Unlimited access to GPT-4 level general purpose assistant.",
    btnTxt: "Start Now",
  },
  // {
  //   title: "$29.00",
  //   title2: "Professional account",
  //   itemclass: "bgreen",
  //   text1: "Everything in Basic, plus:",
  //   text2: "Report specific assistant.",
  //   text3: "Automated monitoring.",
  //   text4: "up to 100 documents per assistant.",
  //   btnTxt: "Start Now",
  // },
  {
    title: "Customized Plan",
    title2: "Creator",
    itemclass: "bdark",
    text1: "Custom assistants, with up to 1 million documents.",
    text2:
      "integration with proprietrary Siftlink databases, pipelines and predicitve models.",
    text3: "On premises deployment.",
    text4: "Monetization options.",
    // text5: "Integration with Siftlink proprietrary databases.",
    // text6: "Integration with Siftlink proprietrary predictive models.",
    btnTxt: "Get in Touch",
  },
];
const yearlyPricingData = [
  {
    title: "$220.00",
    discountTitle: "save 56%",
    title2: "Basic",
    itemclass: "blight",
    text0: "Unlimited documents, reports, interactions and references.",
    text1: "Unlimited custom assistants, with up to 100 documents.",
    text2: "Access to GPT-4 level of generic assistant.",
    // text3: "Unlimited scientific and patent references.",
    // text4: "Unlimited access to GPT-4 level general purpose assistant.",
    btnTxt: "Start Now",
  },
  // {
  //   title: "$299.00",
  //   title2: "Professional account",
  //   itemclass: "bgreen",
  //   text1: "Everything in Basic, plus:",
  //   text2: "Report specific assistant.",
  //   text3: "Automated monitoring.",
  //   text4: "up to 100 documents per assistant.",
  //   btnTxt: "Start Now",
  // },
  {
    title: "Customized Plan",
    title2: "Creator",
    itemclass: "bdark",
    text1: "Custom assistants, with up to 1 million documents.",
    text2:
      "integration with proprietrary Siftlink databases, pipelines and predicitve models.",
    text3: "On premises deployment.",
    text4: "Monetization options.",
    // text5: "Integration with Siftlink proprietrary databases.",
    // text6: "Integration with Siftlink proprietrary predictive models.",
    btnTxt: "Get in Touch",
  },
];
export default {
  Menu,
  ServicesData,
  montlyPricingData,
  weeklyPricingData,
  yearlyPricingData,
};
