// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* .container {\n  height: 80vh;\n  min-height: 80vh;\n} */\n\n.Subscription_subscription-card__L3YML {\n  box-shadow: 10px 10px 23px rgb(0 0 0 / 30%);\n  margin: 4rem 2rem 2rem 2rem;\n  /* margin-bottom: 2rem; */\n  border-radius: 5px;\n  padding: 1rem;\n  text-align: center;\n  width: 90%;\n  background-color: #f2f2f2;\n}\n\n.Subscription_subscription-cost__3d0m9 {\n  font-size: 3rem;\n  color: #5136a9;\n  font-weight: bold;\n}\n\n.Subscription_subscription-button__6Abp7 {\n  width: 100%;\n  margin-bottom: 1rem;\n  background-color: #5136a9;\n}\n\nul {\n  padding: 0;\n}\n\n/* li {\n  padding: 0.2rem;\n  list-style-type: none;\n  text-align: left;\n} */\n\n@media only screen and (min-width: 1200px) {\n  .Subscription_subscription-card__L3YML {\n    height: 550px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Subscription/Subscription.module.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;EACE,2CAA2C;EAC3C,2BAA2B;EAC3B,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ;;AAEA;;;;GAIG;;AAEH;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["/* .container {\n  height: 80vh;\n  min-height: 80vh;\n} */\n\n.subscription-card {\n  box-shadow: 10px 10px 23px rgb(0 0 0 / 30%);\n  margin: 4rem 2rem 2rem 2rem;\n  /* margin-bottom: 2rem; */\n  border-radius: 5px;\n  padding: 1rem;\n  text-align: center;\n  width: 90%;\n  background-color: #f2f2f2;\n}\n\n.subscription-cost {\n  font-size: 3rem;\n  color: #5136a9;\n  font-weight: bold;\n}\n\n.subscription-button {\n  width: 100%;\n  margin-bottom: 1rem;\n  background-color: #5136a9;\n}\n\nul {\n  padding: 0;\n}\n\n/* li {\n  padding: 0.2rem;\n  list-style-type: none;\n  text-align: left;\n} */\n\n@media only screen and (min-width: 1200px) {\n  .subscription-card {\n    height: 550px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subscription-card": "Subscription_subscription-card__L3YML",
	"subscription-cost": "Subscription_subscription-cost__3d0m9",
	"subscription-button": "Subscription_subscription-button__6Abp7"
};
export default ___CSS_LOADER_EXPORT___;
