import { useState, useEffect, Fragment } from "react";
import { useAuth } from "../contexts/AuthContext";
import { updateEmail, updatePassword } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { Form, Button, Card, Container, Alert } from "react-bootstrap";
import * as Yup from "yup";
import { collection, query, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";
import style from "./AccountManagment.module.css";

function AccoutnManagment() {
  const { currentUser } = useAuth();
  let [subscriptionStatus, setSubscriptionStatus] = useState(undefined);
  const [loading, setLoading] = useState(true);
  let [isEmailError, setIsEmailError] = useState(false);
  let [UpdateEmailError, setUpdateEmailError] = useState(undefined);
  let [isUnsubscribed, setIsUnsubscribed] = useState(false);
  let [numberOfDocuments, setNumberOfDocuments] = useState(0);
  const uid = currentUser.uid;
  let functions = getFunctions();

  useEffect(
    function loadSessions() {
      fetch(`https://sensi-api.siftlink.com/sessions/${uid}`)
        .then(function (response) {
          if (response.status !== 200) {
            return { error: "An error occur " + response.status };
          }
          return response.json();
        })
        .then(function (responseData) {
          setNumberOfDocuments(responseData.data.length);
          // setLoading(false);
        })
        .catch(function (error) {
          console.log("An error occur " + error);
        });
    },
    [uid]
  );

  const initialValues = {
    email: "",
    password: "",
  };

  useEffect(function () {
    const getPaymentMethods = httpsCallable(functions, "getPaymentMethods");
    getPaymentMethods()
      .then(function (result) {})
      .catch(function (error) {
        console.log("Error: " + error);
      });
  }, []);

  useEffect(
    function getSubscriptionStatus() {
      async function getUserSubscription() {
        if (!currentUser) {
          return;
        }
        let q = query(
          collection(firestore, "customers", currentUser.uid, "subscriptions")
        );

        let querySnapshot = await getDocs(q);
        let temp = [];
        querySnapshot.forEach(function (document) {
          temp.push(document.data());
        });
        setSubscriptionStatus([temp[temp.length - 1]]);
        setLoading(false);
      }

      getUserSubscription();
    },
    [currentUser]
  );
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Enter a valid email").required("Email required"),
    password: Yup.string()
      .min(8, "Password should be 8 characters minimum.")
      .required("Password required"),
  });

  async function onSubmit(values, props) {
    props.setSubmitting(true);
    if (values.email) {
      try {
        await updateEmail(currentUser, values.email);
      } catch (error) {
        console.log("Error updating email: " + error);
        setUpdateEmailError(error.message.slice(15).replace("auth/", ""));
        setIsEmailError(true);
      }
    }

    if (values.password) {
      try {
        await updatePassword(currentUser, values.password);
      } catch (error) {
        console.log("Error updating email: " + error);
        setUpdateEmailError(error.message.slice(15).replace("auth/", ""));
        setIsEmailError(true);
      }
    }

    props.setSubmitting(false);
    props.resetForm();
  }

  function getCurrentTime(timestamp) {
    const time = new Date(timestamp);
    return time.toLocaleString("en-US");
  }

  // const functions = getFunctions();
  // const test = httpsCallable(functions, "getPaymentMethods");
  // test().then(function (result) {
  //   console.log("result");
  //   console.log(result);
  // });

  async function handleUnsubscribe(subscriptionId) {
    // alert(subscriptionId);
    // const test = httpsCallable(functions, "unsbscribe");
    // try {
    //   await test({
    //     subscriptionId,
    //   });
    // } catch (error) {
    //   console.log("ERROR");
    //   console.log(error);
    // }

    const getPaymentMethods = httpsCallable(functions, "testOnApp");
    getPaymentMethods({ subscriptionId: subscriptionId })
      .then(function (result) {
        setIsUnsubscribed(true);
        // window.location.reload(true);
        console.log("result.data unsubscribe");
        console.log(result.data);
      })
      .then(function (result) {
        // window.location.reload(true);
        setTimeout(window.location.reload(true), 2000);
      })
      .catch(function (error) {
        console.log("Error: " + error);
      });
  }
  return (
    <Fragment>
      <div
        className={`d-flex justify-content-center align-items-center ${style.heroBackground}`}
        style={{ width: "100%", height: "221px" }}
      >
        <h1
          className={`text-center p-2 text-white`}
          style={{
            fontWeight: "700",
            fontSize: "40px",
            lineHeight: "50px",
            width: "100%",
          }}
        >
          Account
        </h1>
      </div>
      <Container fluid className={`${style.mainBackground}`}>
        <Container style={{ minHeight: "80vh", padding: "6rem 0" }}>
          {isEmailError ? (
            <Alert
              variant="danger"
              style={{
                textAlign: "center",
                borderRadius: "0",
                maxWidth: "400px",
                margin: "0 auto",
              }}
            >
              {UpdateEmailError}
            </Alert>
          ) : (
            <></>
          )}

          <div className="p-2">
            <h2
              className="pb-2"
              style={{
                fontWeight: "600",
                fontSize: "36px",
                lineHeight: "54px",
              }}
            >
              User Managment
            </h2>
          </div>
          <div
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0,0,0, 0.2)",
              padding: "2rem",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                maxWidth: "1120px",
                margin: "0 auto",
              }}
            >
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <FormikForm>
                    <div className="row">
                      <div className="col-xl-6">
                        <Form.Group id="email">
                          <Form.Label
                            style={{
                              fontWeight: "600",
                              fontSize: "20px",
                              lineHeight: "30px",
                            }}
                          >
                            Change email
                          </Form.Label>
                          <Field
                            as={Form.Control}
                            type="email"
                            name="email"
                            placeholder={currentUser.email}
                            required
                          />
                          <Form.Text
                            style={{
                              color: "red",
                              padding: "8px",
                              display: "block",
                            }}
                          >
                            {<ErrorMessage name="email" />}
                          </Form.Text>
                        </Form.Group>
                      </div>
                      <div className="col-xl-6">
                        <Form.Group id="change-email">
                          <Form.Label
                            style={{
                              fontWeight: "600",
                              fontSize: "20px",
                              lineHeight: "30px",
                            }}
                          >
                            Confirm Email
                          </Form.Label>
                          <Field
                            as={Form.Control}
                            type="email"
                            name="change-email"
                            // placeholder={currentUser.email}
                            required
                          />
                          <Form.Text
                            style={{
                              color: "red",
                              padding: "8px",
                              display: "block",
                            }}
                          >
                            {<ErrorMessage name="email" />}
                          </Form.Text>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6">
                        {" "}
                        <Form.Group id="change-firstname">
                          <Form.Label
                            style={{
                              fontWeight: "600",
                              fontSize: "20px",
                              lineHeight: "30px",
                            }}
                          >
                            Change First Name
                          </Form.Label>
                          <Field
                            as={Form.Control}
                            type="text"
                            name="firstname"
                            // placeholder={currentUser.email}
                            required
                          />
                          <Form.Text
                            style={{
                              color: "red",
                              padding: "8px",
                              display: "block",
                            }}
                          >
                            {<ErrorMessage name="firstname" />}
                          </Form.Text>
                        </Form.Group>
                      </div>
                      <div className="col-xl-6">
                        {" "}
                        <Form.Group id="change-lastname">
                          <Form.Label
                            style={{
                              fontWeight: "600",
                              fontSize: "20px",
                              lineHeight: "30px",
                            }}
                          >
                            Change Last Name
                          </Form.Label>
                          <Field
                            as={Form.Control}
                            type="text"
                            name="lastname"
                            // placeholder={currentUser.email}
                            required
                          />
                          <Form.Text
                            style={{
                              color: "red",
                              padding: "8px",
                              display: "block",
                            }}
                          >
                            {<ErrorMessage name="lastname" />}
                          </Form.Text>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-6">
                        <Form.Group id="password">
                          <Form.Label
                            style={{
                              fontWeight: "600",
                              fontSize: "20px",
                              lineHeight: "30px",
                            }}
                          >
                            Change password
                          </Form.Label>
                          <Field
                            as={Form.Control}
                            type="password"
                            name="password"
                            required
                          />
                          <Form.Text
                            style={{
                              color: "red",
                              padding: "8px",
                              display: "block",
                            }}
                          >
                            {<ErrorMessage name="password" />}
                          </Form.Text>
                        </Form.Group>
                      </div>
                      <div className="col-xl-6">
                        <Form.Group id="confirm-password">
                          <Form.Label
                            style={{
                              fontWeight: "600",
                              fontSize: "20px",
                              lineHeight: "30px",
                            }}
                          >
                            Confirm Password
                          </Form.Label>
                          <Field
                            as={Form.Control}
                            type="password"
                            name="confirm-password"
                            required
                          />
                          <Form.Text
                            style={{
                              color: "red",
                              padding: "8px",
                              display: "block",
                            }}
                          >
                            {<ErrorMessage name="confirm-password" />}
                          </Form.Text>
                        </Form.Group>
                      </div>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <Button
                        style={{
                          textTransform: "none",
                          border: "none",
                          width: "348px",
                          height: "56px",
                          borderRadius: "3px",
                          backgroundColor: "#C8102E",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                        className=" mt-4"
                        type="submit"
                        disabled={props.isSubmitting}
                      >
                        {props.isSubmitting ? "Loading" : "Update"}
                      </Button>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </div>
          </div>
          <div className="p-2" style={{ marginTop: "6rem" }}>
            <h2
              className="pb-2"
              style={{ fontSize: "36px", fontWeight: "600" }}
            >
              Subscriptions
            </h2>
            {!loading &&
              subscriptionStatus.map(function (subscription, id) {
                return (
                  <div
                    style={{
                      border: "1px solid rgba(0,0,0, 0.2)",
                      padding: "2rem",
                      borderRadius: "20px",
                      backgroundColor: "white",
                    }}
                    // className="d-flex align-items-center"
                    key={id}
                  >
                    <div className="row">
                      <div className="col-xl-4">
                        <div
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                          }}
                        >
                          Status:
                        </div>
                        <div style={{ fontWeight: "400", fontSize: "16px" }}>
                          {subscription?.status}
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                          }}
                        >
                          Start Period:
                        </div>
                        <div style={{ fontWeight: "400", fontSize: "16px" }}>
                          {getCurrentTime(
                            subscription?.current_period_start.seconds * 1000
                          )}
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                          }}
                        >
                          End Period:
                        </div>
                        <div style={{ fontWeight: "400", fontSize: "16px" }}>
                          {getCurrentTime(
                            subscription?.current_period_end.seconds * 1000
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-xl-4">
                        <div
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                          }}
                        >
                          Plan:
                        </div>
                        <div style={{ fontWeight: "400", fontSize: "16px" }}>
                          {subscription?.items[0].price.product.name}
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                          }}
                        >
                          Documents Created:
                        </div>
                        <div style={{ fontWeight: "400", fontSize: "16px" }}>
                          {numberOfDocuments}
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                          }}
                        >
                          Interactions:
                        </div>
                        <div style={{ fontWeight: "400", fontSize: "16px" }}>
                          -
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "2rem" }}>
                      {subscription?.status === "active" ||
                      subscription?.status === "trialing" ? (
                        <Button
                          style={{
                            backgroundColor: "#E8C9CE",
                            border: "none",
                            borderRadius: "3px",
                            width: "138px",
                            height: "36px",
                          }}
                          onClick={() =>
                            handleUnsubscribe(
                              subscription?.items[0].subscription
                            )
                          }
                        >
                          Unsbscribe
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </Container>
      </Container>
    </Fragment>
  );
}

export default AccoutnManagment;
