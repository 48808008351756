import React from "react";
import "./CTA.css";
import { images } from "../../constants";
import Button from "react-bootstrap/Button";

const CTA = () => {
  return (
    <div className="section-padding">
      <div className="container">
        <div className="row cta">
          <div className="col-lg-6 align-self-center">
            <h2 style={{ fontSize: "48px", fontWeight: "800" }}>
              Precise, trusted content
            </h2>

            <p className="pt-2 pb-4" style={{ fontSize: "18px" }}>
              Decisions need timely, precise insights. Sensi generates reports
              using various science, patent and technical databases always
              citing sources. You can always find references for any report
              section or input text with just one click.
            </p>
            <Button
              href="/sign-up"
              className="hq-btn mt-4"
              variant="primary"
              size="lg"
            >
              Start now &gt;
            </Button>
          </div>
          <div className="col-lg-6 d-lg-flex">
            <img src={images.create} alt="thingshappen" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
