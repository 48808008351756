import { useState, useEffect, useMemo, Fragment } from "react";
import { Link } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import { Container, Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import LoadingAnimation from "../compoundOverview/LoadingAnimation";
import NewSeesionButton from "./NewSeesionButton";
import SessionBoxTemp from "./SessionBoxTemp";
import DeleteNoteModal from "./DeleteNoteModal";
import { useAuth } from "../../contexts/AuthContext";
import styles from "./HomepageTemp.module.css";
import NavBar from "../NavBar";
import Footer from "../NewHomePage/footer/Footer";
// import ChatWidget from "../ChatWidget";
// import { SocketIoProvider } from "../../contexts/SocketIoContext";
import TrialPeriodBanner from "../TrialPeriodBanner";

function HomePageTemp() {
  let { currentUser } = useAuth();
  let [sessions, setSessions] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  let [isDeletingSession, setIsDeletingSession] = useState(false);
  let [tableData, setTableData] = useState([]);
  let [showModal, setShowModal] = useState(false);
  let [rowTimestamp, setRowTimestamp] = useState(undefined);
  let [rowSessionTitle, setRowSessionTitle] = useState(undefined);
  let [isPreparingReport, setIsePreparingReport] = useState(false);
  let [numberOfDocuments, setNumberOfDocuments] = useState(0);
  const uid = currentUser.uid;

  let columns = useMemo(function () {
    return [
      {
        accessorKey: "sessionTitle",
        header: "Title",
        Cell: ({ cell }) => (
          <Link
            className="p-2"
            style={{ color: "#282c33" }}
            to={`/editor/${cell.row.original.timestamp}`}
          >
            <i
              className="bi bi-journal-richtext"
              style={{
                color: "#800080",
                marginRight: "20px",
                fontSize: "20px",
              }}
            ></i>
            {cell.getValue()}
          </Link>
        ),
        size: 150,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        size: 200,
      },
      {
        accessorKey: "modifiedAt",
        header: "Last modified",
        size: 200,
      },
    ];
  });

  useEffect(
    function loadSessions() {
      fetch(`https://sensi-api.siftlink.com/sessions/${uid}`)
        .then(function (response) {
          if (response.status !== 200) {
            return { error: "An error occur " + response.status };
          }
          return response.json();
        })
        .then(function (responseData) {
          setSessions(responseData.data);
          setNumberOfDocuments(responseData.data.length);
          setTableData(responseData.data.reverse());
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log("An error occur " + error);
        });
    },
    [uid, isDeletingSession]
  );

  if (isLoading) {
    return <LoadingAnimation />;
  }

  function handleDelete(row) {
    setShowModal(true);
    setRowTimestamp(row.original.timestamp);
    setRowSessionTitle(row.original.sessionTitle);
  }

  return (
    <Fragment>
      {/* <SocketIoProvider>
        <ChatWidget />
      </SocketIoProvider> */}
      <DeleteNoteModal
        showModal={showModal}
        setShowModal={setShowModal}
        setIsDeletingSession={setIsDeletingSession}
        uid={uid}
        timestamp={rowTimestamp}
        title={rowSessionTitle}
      />
      <div className={`${styles.bgImage} pb-5`} style={{ minHeight: "100vh" }}>
        <NavBar />
        <TrialPeriodBanner />
        <Container
          style={{
            padding: "0",
            minHeight: "75vh",
          }}
        >
          <NewSeesionButton />
          <h5 style={{ color: "rgba(0,0,0, 0.7)" }}>Feed</h5>
          <Swiper
            style={{ padding: "10px" }}
            spaceBetween={30}
            slidesPerView={3}
            modules={[Pagination, EffectFade]}
            pagination={true}
            effect
            speed={1000}
            loop
            breakpoints={{
              1: {
                slidesPerView: 1,
              },
              800: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
          >
            {sessions.slice(0, 7).map(function (session) {
              return (
                <SwiperSlide key={session.timestamp}>
                  <SessionBoxTemp
                    session={session}
                    setIsDeletingSession={setIsDeletingSession}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <hr className="border-danger" />
          <h5 className="mt-5" style={{ color: "rgba(0,0,0, 0.7)" }}>
            Documents
          </h5>
          <MaterialReactTable
            style={{ borderRadious: "10px" }}
            columns={columns}
            data={tableData}
            initialState={{ density: "compact" }}
            enableColumnFilters={false}
            enableDensityToggle={false}
            enableHiding={false}
            enableEditing
            enableRowActions
            state={{
              isLoading: isPreparingReport,
              showProgressBars: isPreparingReport,
            }}
            renderRowActions={({ row }) => (
              <Button variant="link" onClick={() => handleDelete(row)}>
                <i
                  className="bi bi-x-circle"
                  style={{ fontSize: "1.3rem", color: "#404951" }}
                ></i>
              </Button>
            )}
            muiTablePaperProps={{
              sx: {
                borderRadius: "10px",
              },
            }}
            muiTopToolbarProps={{
              sx: {
                borderRadius: "10px",
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                borderRadius: "10px",
              },
            }}
          />
        </Container>
      </div>
      <Footer />
    </Fragment>
  );
}
// box-shadow: 10px 10px;

export default HomePageTemp;
