import { Button } from "react-bootstrap";
import axios from "axios";

function ResultsTableButton({ row, setCid, setData1, setDefaultIndex }) {
  async function handleFetchChemicalInfo(row) {
    setCid(row.original.cid);
    let results = await axios.get(
      `https://api-02.siftlink.com/slc/compound/${row.original.cid}`,
      {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiZmY4MzVmM2M2MGFlY2MzYjNmYWVhNjZjNGJmNTVhNjFjYTljZWI2MmYzMDQ3MzBkM2YyNzBkMzE0ZGI1NTg0OGVmYjc1ZTljN2Y0ZjBmZDk4MWIyMjdjNTg2MTg0YTg4OTFlOGY3YzY0ZTgyZTU3OTVlMzdkYzY5NWM3M2Y2ZDAiLCJleHBpcmVzIjozMTcyNDUzMDUwMjIuOTU4OH0.78TIGmQt4XTDsMVbhfKxo6VLazsdw13pqrTUDGD46K4",
        },
      }
    );
    setData1(results.data.data);
    setDefaultIndex(1);
  }
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "nowrap",
        gap: "0.5rem",
      }}
    >
      <Button
        style={{ background: "white", color: "#0d6efd" }}
        onClick={() => handleFetchChemicalInfo(row)}
      >
        Details
      </Button>
    </div>
  );
}

export default ResultsTableButton;
