import { Fragment } from "react";
import { Container } from "react-bootstrap";
import convertTimestamp from "../utils/convertTimestamp";

function TrialPeriodBanner({
  currentUser,
  subscriptionStatus,
  trialExpired,
  trialExpiredAt,
}) {
  if (currentUser && subscriptionStatus?.length && subscriptionStatus[0]) {
    return;
  }

  if (!currentUser) {
    return;
  }

  if (currentUser && !subscriptionStatus) {
    return;
  }

  if (!trialExpired()) {
    return (
      <Container
        style={{
          backgroundColor: "orange",
          padding: "0.5rem",
          textAlign: "center",
        }}
      >
        Your trial period ends at
        <span> {convertTimestamp(trialExpiredAt).toDateAndHour}</span>
      </Container>
    );
  }
  return (
    <Fragment>
      {/* {!currentUser && ""}
      {subscriptionStatus && ""}
      {!trialExpired() && (
        <Container
          style={{
            backgroundColor: "orange",
            padding: "0.5rem",
            textAlign: "center",
          }}
        >
          Your trial period ends at
          <span> {convertTimestamp(trialExpiredAt).toDateAndHour}</span>
        </Container>
      )} */}
      {trialExpired() && (
        <Container
          style={{
            backgroundColor: "orange",
            padding: "0.5rem",
            textAlign: "center",
          }}
        >
          Your trial period has expired. Please subscribe{" "}
          <a style={{ color: "black", fontWeight: "bold" }} href="#pricing">
            here
          </a>
        </Container>
      )}
    </Fragment>
  );
}

export default TrialPeriodBanner;
