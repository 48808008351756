import { useNavigate } from "react-router-dom";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { Card, Container, Form, Button, Alert } from "react-bootstrap";
import * as Yup from "yup";
import { useAuth } from "../contexts/AuthContext";
import { useState } from "react";
import { Fragment } from "react";
import sLogo from "../assets/bgWhiteLogo.png";

function ResetPassword() {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { resetPassword } = useAuth();

  let navigate = useNavigate();

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Enter a valid email").required("Email required"),
  });

  function onSubmit(values, props) {
    props.setSubmitting(true);
    resetPassword(values.email)
      .then(function () {
        setAlertMessage("Please visit your email account");
        setIsAlertVisible(true);
        setIsSubmitted(true);
        setTimeout(function () {
          navigate("/");
        }, 3000);
      })
      .catch(function (error) {
        setAlertMessage("User not found");
        setIsAlertVisible(true);
        console.log(error);
      });
  }

  return (
    <Fragment>
      {isAlertVisible && (
        <Alert
          variant="danger"
          style={{ textAlign: "center", maxWidth: "400px", margin: "0 auto" }}
        >
          {alertMessage}
        </Alert>
      )}
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="w-100" style={{ maxWidth: "400px" }}>
          <Card>
            <Card.Body>
              <div
                className="mb-4"
                style={{
                  borderRadius: "5px",
                  height: "70px",
                }}
              >
                <img
                  style={{
                    width: "45%",
                    display: "block",
                    margin: "0 auto",
                    height: "100%",
                  }}
                  src={sLogo}
                  alt="logo"
                />
              </div>
              <h2 className="mb-3 text-center">Reset your password</h2>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <FormikForm>
                    <Form.Group id="email">
                      <Form.Label>Email</Form.Label>
                      <Field
                        as={Form.Control}
                        type="email"
                        name="email"
                        required
                      />
                      <Form.Text>{<ErrorMessage name="email" />}</Form.Text>
                    </Form.Group>
                    <Button
                      style={{
                        textTransform: "none",
                        backgroundColor: "#f62929",
                        border: "none",
                      }}
                      className="w-100 mt-4"
                      type="submit"
                      disabled={isSubmitted}
                    >
                      Reset password
                    </Button>
                  </FormikForm>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </Fragment>
  );
}

export default ResetPassword;
