import styles from "./SectionTitle.module.css";

function SectionTitle({ text }) {
  return (
    <div className="container center" style={{ height: "70vh" }}>
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ height: "100%" }}
      >
        <h1 className="w-75 text-center">{text}</h1>
        <hr className={styles.titleHr} />
      </div>
    </div>
  );
}

export default SectionTitle;
