import { Fragment, useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import BSnav from "./navbar/BSnav";
import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";
import Sidebar from "./sidebar/Sidebar";
import { useAuth } from "../../contexts/AuthContext";
import LoadingAnimation from "../compoundOverview/LoadingAnimation";
import DeleteNoteModal from "../homePage/DeleteNoteModal";
import MaterialReactTable from "material-react-table";

function NewHomePage() {
  let { currentUser } = useAuth();
  let [sessions, setSessions] = useState([]);
  let [tableData, setTableData] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  let [isDeletingSession, setIsDeletingSession] = useState(false);
  let [showModal, setShowModal] = useState(false);
  let [rowTimestamp, setRowTimestamp] = useState(undefined);
  let [rowSessionTitle, setRowSessionTitle] = useState(undefined);
  let [isPreparingReport, setIsePreparingReport] = useState(false);
  const uid = currentUser.uid;

  let columns = useMemo(function () {
    return [
      {
        accessorKey: "sessionTitle",
        header: "Title",
        Cell: ({ cell }) => (
          <Link
            className="p-2"
            style={{ color: "#282c33" }}
            to={`/editor/${cell.row.original.timestamp}`}
          >
            <i
              class="bi bi-journal-richtext"
              style={{
                color: "#800080",
                marginRight: "20px",
                fontSize: "20px",
              }}
            ></i>
            {cell.getValue()}
          </Link>
        ),
        size: 150,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        size: 200,
      },
      {
        accessorKey: "modifiedAt",
        header: "Last modified",
        size: 200,
      },
    ];
  });

  useEffect(
    function loadSessions() {
      fetch(`https://sensi-api.siftlink.com/sessions/${uid}`)
        .then(function (response) {
          if (response.status !== 200) {
            return { error: "An error occur " + response.status };
          }
          return response.json();
        })
        .then(function (responseData) {
          setSessions(responseData.data);
          setTableData(responseData.data.reverse());
          console.log(responseData.data);
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log("An error occur " + error);
        });
    },
    [uid, isDeletingSession]
  );

  if (isLoading) {
    return <LoadingAnimation />;
  }

  function handleDelete(row) {
    setShowModal(true);
    setRowTimestamp(row.original.timestamp);
    setRowSessionTitle(row.original.sessionTitle);
  }

  return (
    <Fragment>
      <DeleteNoteModal
        showModal={showModal}
        setShowModal={setShowModal}
        setIsDeletingSession={setIsDeletingSession}
        uid={uid}
        timestamp={rowTimestamp}
        title={rowSessionTitle}
      />
      <BSnav />
      <div className="grid-container" style={{ minHeight: "80vh" }}>
        <div className="row gap-4 p-0 m-0">
          <div className="col-lg sidebar p-0 m-0">
            <Sidebar />
          </div>
          <div className="col-lg main-layout p-0 m-0">
            <Navbar />
            <hr className="border-danger" />
            {/* <Slider />
            <hr className="border-danger" />
            <Table/> */}
            <MaterialReactTable
              columns={columns}
              data={tableData}
              initialState={{ density: "compact" }}
              enableColumnFilters={false}
              enableDensityToggle={false}
              enableHiding={false}
              enableEditing
              enableRowActions
              state={{
                isLoading: isPreparingReport,
                showProgressBars: isPreparingReport,
              }}
              renderRowActions={({ row }) => (
                <Button variant="link" onClick={() => handleDelete(row)}>
                  <i
                    className="bi bi-x-circle"
                    style={{ fontSize: "1.3rem", color: "#404951" }}
                  ></i>
                </Button>
              )}
              muiTableHeadCellProps={{
                sx: {
                  backgroundColor: "rgba(40, 44, 51, 0.08)",
                },
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}

export default NewHomePage;
