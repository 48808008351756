import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function NewSeesionButton() {
  return (
    <Row className="mb-5 mt-5">
      <Col>
        <div className="d-flex align-items-start justify-content-start">
          <Link to={`/editor/${Date.now()}`}>
            <Button
              variant="danger"
              style={{
                width: "200px",
                backgroundColor: "#C8102E",
                borderRadius: "3px",
              }}
            >
              <div>
                <i className="bi bi-plus-lg"></i> New{" "}
              </div>
            </Button>
          </Link>
        </div>
      </Col>
    </Row>
  );
}

export default NewSeesionButton;
