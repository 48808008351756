import { useState } from "react";
import { GoogleAuthProvider } from "firebase/auth";
import { ref, set } from "firebase/database";
import { Form, Button, Card, Container, Alert } from "react-bootstrap";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom";
import { database } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import sLogo from "../assets/bgWhiteLogo.png";
import { Fragment } from "react";

function SignUp() {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  let [isChecked, setIsChecked] = useState(false);
  let [isSignUpDisabled, setIsSignUpDisabled] = useState(true);
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const { signup, emailVerification, authWithGoogle } = useAuth();
  const initialValues = {
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Enter a valid email").required("Email required"),
    password: Yup.string()
      .min(8, "Password should be 8 characters minimum.")
      .required("Password required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  function onSubmit(values, props) {
    props.setSubmitting(true);
    signup(auth, values.email, values.password)
      .then(function (user) {
        set(ref(database, "users/" + user.user.uid), {
          username: values.username,
          email: values.email,
          trialExpireAt: Date.now() + 7 * 24 * 60 * 60 * 1000,
        });
        emailVerification(auth);
        setIsAlertVisible(true);
        setAlertMessage(
          "email verification has been sent. Please verify your e-mail."
        );
      })
      .catch(function (error) {
        setIsAlertVisible(true);
        setAlertMessage(
          "An error occurred: " +
            error.code.replace("auth/", "").replaceAll("-", " ")
        );
      });
    props.setSubmitting(false);
    props.resetForm();
  }

  function handleAuthWithGoogle() {
    authWithGoogle(auth, provider)
      .then((user) => {
        set(ref(database, "users/" + user.user.auth.currentUser.uid), {
          username: user.user.auth.currentUser.displayName,
          email: user.user.auth.currentUser.email,
          trialExpireAt: Date.now() + 7 * 24 * 60 * 60 * 1000,
        });
      })
      .catch((error) => {
        setIsAlertVisible(true);
        setAlertMessage(
          "An error occurred: " +
            error.code.replace("auth/", "").replaceAll("-", " ")
        );
      });
  }

  function handleIsChecked(event) {
    setIsChecked(event.target.checked);
    setIsSignUpDisabled(!event.target.checked);
  }

  return (
    <Fragment>
      {isAlertVisible ? (
        <Alert
          variant="danger"
          style={{
            textAlign: "center",
            borderRadius: "0",
            maxWidth: "400px",
            margin: "0 auto",
          }}
        >
          {alertMessage}
        </Alert>
      ) : (
        <></>
      )}
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="w-100" style={{ maxWidth: "400px" }}>
          <Card>
            <Card.Body>
              <div
                className="mb-4"
                style={{
                  borderRadius: "5px",
                  height: "65px",
                }}
              >
                <img
                  style={{
                    width: "45%",
                    display: "block",
                    margin: "0 auto",
                    height: "100%",
                  }}
                  src={sLogo}
                  alt="logo"
                />
              </div>
              <h2 className=" mb-4 text-center">Sign up to Sensi</h2>
              <div className="d-flex flex-column mt-5">
                <Button
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "2px solid #4285f4",
                  }}
                  onClick={handleAuthWithGoogle}
                  disabled={isSignUpDisabled}
                >
                  <i
                    className="bi bi-google"
                    style={{ color: "#4285f4", marginRight: "0.5rem" }}
                  ></i>{" "}
                  Continue with Google
                </Button>
              </div>

              <div className="d-flex mt-5">
                <hr style={{ width: "40%" }} />
                <div style={{ margin: "0 10%" }}>OR</div>
                <hr style={{ width: "40%" }} />
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <FormikForm>
                    <Form.Group id="username">
                      <Form.Label>Username</Form.Label>
                      <Field
                        style={{
                          padding: ".375rem .75rem",
                          borderRadius: ".375rem",
                        }}
                        as={Form.Control}
                        type="text"
                        name="username"
                        required
                      />
                      <Form.Text
                        style={{
                          color: "red",
                          padding: "8px",
                          display: "block",
                        }}
                      >
                        {<ErrorMessage name="username" />}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group id="email">
                      <Form.Label>Email</Form.Label>
                      <Field
                        style={{
                          padding: ".375rem .75rem",
                          borderRadius: ".375rem",
                        }}
                        as={Form.Control}
                        type="email"
                        name="email"
                        required
                      />
                      <Form.Text
                        style={{
                          color: "red",
                          padding: "8px",
                          display: "block",
                        }}
                      >
                        {<ErrorMessage name="email" />}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group id="password">
                      <Form.Label>Password</Form.Label>
                      <Field
                        as={Form.Control}
                        type="password"
                        name="password"
                        required
                      />
                      <Form.Text
                        style={{
                          color: "red",
                          padding: "8px",
                          display: "block",
                        }}
                      >
                        {<ErrorMessage name="password" />}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group id="confirmPassword">
                      <Form.Label>Confirm password</Form.Label>
                      <Field
                        as={Form.Control}
                        type="password"
                        name="confirmPassword"
                        required
                      />
                      <Form.Text
                        style={{
                          color: "red",
                          padding: "8px",
                          display: "block",
                        }}
                      >
                        {<ErrorMessage name="confirmPassword" />}
                      </Form.Text>
                    </Form.Group>
                    <Button
                      style={{
                        textTransform: "none",
                        backgroundColor: "#f62929",
                        border: "none",
                      }}
                      className="w-100 mt-2"
                      type="submit"
                      disabled={props.isSubmitting || isSignUpDisabled}
                    >
                      {props.isSubmitting ? "Loading" : "Sign up"}
                    </Button>
                  </FormikForm>
                )}
              </Formik>
            </Card.Body>
          </Card>
          <div className="w-100 text-center mt-2">
            <label>
              <input
                type="checkbox"
                className="me-2"
                checked={isChecked}
                onChange={handleIsChecked}
                required
              />
              I have read and agree to the{" "}
              <Link to="/terms-of-service">Terms of service</Link> and{" "}
              <Link to="/privacy-policy">Privacy policy</Link>
            </label>
          </div>
          <div className="w-100 text-center mt-2">
            You already have an account? <Link to="/">log in</Link>
          </div>
        </div>
      </Container>
    </Fragment>
  );
}

export default SignUp;
