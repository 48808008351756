// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* extra style to prevent materialize */\n.TableTest_liStyles__3FhR5 li{\n    list-style: disc !important;\n}", "",{"version":3,"sources":["webpack://./src/components/TableTest.module.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;IACI,2BAA2B;AAC/B","sourcesContent":["/* extra style to prevent materialize */\n.liStyles li{\n    list-style: disc !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"liStyles": "TableTest_liStyles__3FhR5"
};
export default ___CSS_LOADER_EXPORT___;
