import { createContext, useContext, useState, useEffect } from "react";
import {
  ActionCodeSettings,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { app } from "../firebase";

const authContext = createContext();
const auth = getAuth(app);

export function useAuth() {
  return useContext(authContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const actionCodeSettings = {
    url: "https://sensi.dev.siftlink.com/",
    handleCodeInApp: true,
  };

  useEffect(function () {
    const unsubscribe = auth.onAuthStateChanged(function (user) {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  function login(auth, email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function signup(auth, email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function emailVerification(auth) {
    return sendEmailVerification(auth.currentUser, actionCodeSettings);
  }

  function authWithGoogle(auth, googleProvider) {
    return signInWithPopup(auth, googleProvider);
  }

  function logout(auth) {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  return (
    <authContext.Provider
      value={{
        currentUser,
        login,
        logout,
        resetPassword,
        signup,
        emailVerification,
        authWithGoogle,
      }}
    >
      {!loading && children}
    </authContext.Provider>
  );
}
