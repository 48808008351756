import { ThreeDots } from "react-loader-spinner";

function LoadingAnimation() {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "70vh", textAlign: "center" }}
    >
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#4fa94d"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
}

export default LoadingAnimation;
