function NoData() {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "70vh", textAlign: "center" }}
    >
      No data found
    </div>
  );
}

export default NoData;
