import React from "react";
import "./Empower.css";
import { images } from "../../constants";
import Button from "react-bootstrap/Button";

const Empower = () => {
  return (
    <div className="section-padding">
      <div className="container">
        <div className="row empower">
          <div className="col-lg-8 d-lg-flex order-2 order-lg-1">
            <img src={images.empower} alt="" />
          </div>
          <div className="col-lg-4 align-self-center order-lg-2">
            <h2 style={{ fontSize: "48px", fontWeight: "800" }}>
              Fast & simple
            </h2>
            <p className="pt-2 pb-4" style={{ fontSize: "18px" }}>
              You only need to describe the topic of the report you need. Sensi
              will collect the information and compile it for you. You can then
              ask Sensi to do additions or modifications to make sure that the
              content your are looking is there. You will build a highly
              specific report in minutes. Save more than 90% of time needed to
              research, summarize and synthesize documents and reports.
            </p>
            <Button
              href="/sign-up"
              className="fast-btn"
              variant="primary"
              size="lg"
            >
              Start now &gt;
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Empower;
