// import React, { useState, useEffect } from "react";
// import { Editor } from "@tinymce/tinymce-react";
// import axios from "axios";
// import convertTimestamp from "../../utils/convertTimestamp";
// // import ErrorAlert from "./ErrorAlert";

// const MyEditor = () => {
//   const [titleValue, setTitleValue] = useState("");
//   const [tempText, setTempText] = useState("");
//   const [apiResponse, setApiResponse] = useState("");
//   const [editorContent, setEditorContent] = useState("");
//   const [triggerUpdate, setTriggerUpdate] = useState(false);

//   useEffect(
//     function () {
//       fetchData();
//     },
//     [triggerUpdate]
//   );

//   const fetchData = async () => {
//     try {
//       // Fetch data from your API endpoint
//       const response = await axios.get(
//         "https://sensi-api.siftlink.com/sessions/session/?uid=b9W40D58bcZDazNFOPPFVwr6LM92&timestamp=1707243568992"
//       );
//       // console.log("RE");
//       // console.log(response.data.data[0].sessionText);
//       // Update the editor's content with the fetched data
//       // console.log(response.data.data[0].sessionText);
//       setEditorContent(response.data.data[0].sessionText);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   const handleEditorChange = (content, editor) => {
//     // Handle content change if needed
//     setTitleValue(content);
//     saveContentToDatabase(content);
//   };

//   const saveContentToDatabase = async (content) => {
//     try {
//       // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint for saving/updating content
//       await axios.patch(
//         "https://sensi-api.siftlink.com/sessions",
//         {
//           uid: "b9W40D58bcZDazNFOPPFVwr6LM92",
//           timestamp: 1707243568992,
//           sessionText: content,
//           modifiedAt: convertTimestamp(Date.now()).toDateAndHour,
//         },
//         {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         }
//       );
//       // console.log("Content saved/updated successfully!");
//     } catch (error) {
//       console.error("Error saving/updating content:", error);
//     }
//   };

//   const handleKeyPress = (e) => {
//     // Check for the desired symbols '<' and '>'
//     if (e.key === "<") {
//       // Reset temporary text when starting a new '<'
//       setTempText("");
//     } else if (e.key === ">" && tempText) {
//       // console.log("TEMP TEXT TO API");
//       // console.log(tempText);
//       // Make API call when '>' is pressed and there is text between '<' and '>'
//       makeApiCall(tempText);
//     } else if (e.key === "Backspace") {
//       // Remove the last character from tempText when backspace is pressed
//       setTempText(tempText.slice(0, -1));
//       // console.log("BACKSPACE PRESSED");
//       // console.log(tempText);
//     } else {
//       // Update temporary text as the user types
//       setTempText((prevTempText) => prevTempText + e.key);
//       // console.log("NEW TEMP TEXT");
//       // console.log(tempText);
//     }
//   };

//   const handlePaste = (e) => {
//     // console.log("FIRED");
//     // console.log(e);
//     const pastedText = e.content;

//     // Check if pasted text is enclosed in < and > symbols
//     const regex = /^&lt;.*?&gt;$/;
//     const matches = pastedText.match(regex);
//     // console.log("MATCHES");
//     // console.log(matches);

//     if (matches) {
//       const textToApi = matches[0].replace("&lt;", "").replace("&gt;", "");
//       makeApiCall(textToApi);
//     }
//   };

//   const handleKeyDown = (e) => {
//     // For the backspace key, use keydown event
//     if (e.key === "Backspace") {
//       // console.log("BACKSPACE pressed");
//       // e.preventDefault(); // Prevent the default backspace behavior (e.g., navigation)
//       setTempText((prevTempText) => prevTempText.slice(0, -1));
//     }
//   };

//   const makeApiCall = async (textToApi) => {
//     try {
//       // Replace this with your actual API endpoint and request logic
//       const response = await axios.post(
//         "https://sensi-api.siftlink.com/editor/answer",
//         {
//           uid: "b9W40D58bcZDazNFOPPFVwr6LM92",
//           timestamp: parseInt(1707243568992),
//           question: textToApi,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       setTriggerUpdate((prevState) => !prevState);
//       // console.log("RESPONSE");
//       // console.log(response.data);

//       // Handle the API response and update state
//       // console.log("RESPONSE");
//       // console.log(response.data);
//       setApiResponse(response.data);
//       // Fetch additional data using useEffect
//       // setAdditionalData(""); // Reset previous additional data
//     } catch (error) {
//       console.error("Error making API call:", error);
//     }
//   };

//   useEffect(() => {
//     // Fetch additional data based on the API response
//     const fetchData = async () => {
//       console.log("UPDATE DATA");
//       //   try {
//       //     // Replace this with your actual endpoint for fetching additional data
//       //     const additionalResponse = await axios.get(
//       //       "YOUR_ADDITIONAL_DATA_ENDPOINT"
//       //     );

//       //     // Update state with additional data
//       //     setAdditionalData(additionalResponse.data);
//       //   } catch (error) {
//       //     console.error("Error fetching additional data:", error);
//       //   }
//     };

//     // Check if there's an API response before fetching additional data
//     if (apiResponse) {
//       fetchData();
//     }
//   }, [apiResponse]);

//   return (
//     <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
//       <Editor
//         apiKey="17gl4ov1yjqkji4ojh0c1yf7cwbqz9uxiqhfctpth1siojp7"
//         initialValue={editorContent}
//         init={{
//           height: 800,
//           // plugins: ["paste", "pagebreak"],
//           paste_data_images: false,
//           paste_preprocess: (plugin, args) => {
//             handlePaste(args);
//           },
//           // toolbar: "pagebreak",
//           // Other TinyMCE initialization options
//           skin: "fabric",
//           content_css: "fabric",
//           toolbar_mode: "floating",
//           // height: "80%",
//           // width: "95%",
//           browser_spellcheck: true,
//           contextmenu: "false",
//           statusbar: false,
//           sidebar_show: "showcomments",
//           formats: {
//             hilitecolor: {
//               inline: "span",
//               classes: "hilitecolor",
//               styles: { backgroundColor: "yellow" },
//             },
//           },
//           mobile: {
//             content_css: "default",
//             toolbar: false,
//             menubar: "file edit view insert format tools tc",
//             menu: {
//               tc: {
//                 title: "Assistant",
//                 items: "addcomment showcomments deleteallconversations",
//               },
//             },
//             sidebar_show: "",
//           },
//           plugins: [
//             // "tinycomments",
//             "advlist",
//             "autolink",
//             "lists",
//             "link",
//             "image",
//             "charmap",
//             "preview",
//             "anchor",
//             "searchreplace",
//             "visualblocks",
//             "code",
//             "fullscreen",
//             "insertdatetime",
//             "media",
//             "table",
//             "code",
//             "help",
//             "wordcount",
//           ],
//           menubar: "file edit view insert format tools tc",
//           menu: {
//             tc: {
//               title: "Assistant",
//               items: "addcomment showcomments deleteallconversations",
//             },
//           },
//           toolbar:
//             "undo redo | fontsizeinput fontfamily | blocks | customButton |" +
//             "bold italic forecolor backcolor | alignleft aligncenter " +
//             "alignright alignjustify | bullist numlist outdent indent | " +
//             "removeformat | addcomment showcomments",
//           content_style:
//             "body { font-family:Helvetica,Arial,sans-serif; font-size:16px; line-height: 2.5rem; background-color: white; }" +
//             ".tox-comments-visible span.tox-comment  { background-color: #cef9ce59!important; }.tox-comments-visible span.tox-comment--active { background-color: black; }",
//         }}
//         onEditorChange={handleEditorChange}
//         onKeyPress={handleKeyPress}
//         onKeyDown={handleKeyDown}
//       />
//       <div>
//         <p>
//           <b>Question</b>: {apiResponse.question}
//         </p>
//         <hr />
//         <p>
//           <b>Answer</b>: {apiResponse.answer}
//         </p>
//       </div>
//       {/* <ErrorAlert /> */}
//     </div>
//   );
// };

// export default MyEditor;

import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import convertTimestamp from "../../utils/convertTimestamp";
// import ErrorAlert from "./ErrorAlert";

const MyEditor = () => {
  const editorref = useRef(null);
  const [titleValue, setTitleValue] = useState("");
  const [tempText, setTempText] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [additionalData, setAdditionalData] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  let [session, setSession] = useState({});
  // const { timestamp } = useParams();
  const uid = "b9W40D58bcZDazNFOPPFVwr6LM92";

  useEffect(
    function () {
      fetchData();
    },
    [triggerUpdate]
  );

  const fetchData = async () => {
    try {
      // Fetch data from your API endpoint
      const response = await axios.get(
        "https://sensi-api.siftlink.com/sessions/session/?uid=b9W40D58bcZDazNFOPPFVwr6LM92&timestamp=1708088813060"
      );
      // console.log("RE");
      // console.log(response.data.data[0].sessionText);
      // Update the editor's content with the fetched data
      // console.log(response.data.data[0].sessionText);
      setEditorContent(response.data.data[0].sessionText);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditorChange = (newValue, editor) => {
    // console.log(newValue);
    // const caretPosition = editor.selection.getRng().startOffset;
    // console.log("caret");
    // console.log(caretPosition);
    // Handle content change if needed
    // setTitleValue(content);
    // saveContentToDatabase(content);
    setSession({ ...session, sessionText: newValue });
    fetch(`https://sensi-api.siftlink.com/sessions`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid,
        // timestamp: parseInt(timestamp),
        timestamp: 1708088813060,
        sessionText: newValue,
        // plainText: plainText,
        modifiedAt: convertTimestamp(Date.now()).toDateAndHour,
      }),
    })
      .then(function (res) {
        // console.log(res);
      })
      .catch(function (res) {
        console.log(res);
      });
  };

  // const saveContentToDatabase = async (content) => {
  //   try {
  //     // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint for saving/updating content
  //     await axios.patch(
  //       "https://sensi-api.siftlink.com/sessions",
  //       {
  //         uid: "b9W40D58bcZDazNFOPPFVwr6LM92",
  //         timestamp: 1707243568992,
  //         sessionText: content,
  //         modifiedAt: convertTimestamp(Date.now()).toDateAndHour,
  //       },
  //       {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       }
  //     );
  //     console.log("Content saved/updated successfully!");
  //   } catch (error) {
  //     console.error("Error saving/updating content:", error);
  //   }
  // };

  const handleKeyPress = (e) => {
    // console.log("KEY PRESSED");
    // console.log(e.key);
    // console.log("editorref");
    // console.log(editorref.current);
    const editor = e.target;
    // Check for the desired symbols '<' and '>'
    if (e.key === "<") {
      // Reset temporary text when starting a new '<'
      setTempText("");
    } else if (e.key === ">" && tempText) {
      console.log("QUESTION TO API");
      console.log(tempText);
      // Make API call when '>' is pressed and there is text between '<' and '>'
      makeApiCall(tempText);
    } else {
      // Update temporary text as the user types
      // const selectionStart = editor.selectionStart; // Get cursor position
      // console.log("SELECTION START");
      // console.log(selectionStart);
      // const newTempText =
      //   tempText.slice(0, selectionStart) +
      //   e.key +
      //   tempText.slice(selectionStart);
      // console.log("NEW TEMP TEXT");
      // console.log(newTempText);
      setTempText((prevTempText) => prevTempText + e.key);
      // console.log("NEW TEXT");
      // console.log(tempText);
    }
  };

  // const handlePaste = (e) => {
  //   console.log("FIRED");
  //   console.log(e);
  //   const pastedText = e.content;

  //   // Check if pasted text is enclosed in < and > symbols
  //   const regex = /^&lt;.*?&gt;$/;
  //   const matches = pastedText.match(regex);
  //   console.log("MATCHES");
  //   console.log(matches);

  //   // if (matches) {
  //   //   const textToApi = matches[0].replace("&lt;", "").replace("&gt;", "");
  //   //   makeApiCall(textToApi);
  //   // }
  // };

  const handleKeyDown = (e) => {
    // const editor = e.target;
    // console.log(editor.selectionStart);
    // For the backspace key, use keydown event
    if (e.key === "Backspace") {
      // console.log("BACKSPACE pressed");
      // e.preventDefault(); // Prevent the default backspace behavior (e.g., navigation)
      setTempText((prevTempText) => prevTempText.slice(0, -1));
    }
    // else if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
    //   console.log("ARROW");
    //   // Handle arrow key presses (optional)
    //   // You can add custom behavior for arrow key presses if needed
    // }
  };

  const makeApiCall = async (textToApi) => {
    try {
      // Replace this with your actual API endpoint and request logic
      const response = await axios.post(
        "https://sensi-api.siftlink.com/editor/answer",
        {
          uid: "b9W40D58bcZDazNFOPPFVwr6LM92",
          timestamp: parseInt(1707243568992),
          question: textToApi,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setTriggerUpdate((prevState) => !prevState);
      console.log("RESPONSE");
      console.log(response.data);

      // Handle the API response and update state
      setApiResponse(response.data);

      // Fetch additional data using useEffect
      // setAdditionalData(""); // Reset previous additional data
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  // useEffect(() => {
  //   // Fetch additional data based on the API response
  //   const fetchData = async () => {
  //     console.log("UPDATE DATA");
  //     //   try {
  //     //     // Replace this with your actual endpoint for fetching additional data
  //     //     const additionalResponse = await axios.get(
  //     //       "YOUR_ADDITIONAL_DATA_ENDPOINT"
  //     //     );

  //     //     // Update state with additional data
  //     //     setAdditionalData(additionalResponse.data);
  //     //   } catch (error) {
  //     //     console.error("Error fetching additional data:", error);
  //     //   }
  //   };

  //   // Check if there's an API response before fetching additional data
  //   if (apiResponse) {
  //     fetchData();
  //   }
  // }, [apiResponse]);

  function handleCustomButtonClick() {
    alert("Clicked");
  }

  return (
    <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
      <Editor
        apiKey="17gl4ov1yjqkji4ojh0c1yf7cwbqz9uxiqhfctpth1siojp7"
        initialValue={editorContent}
        init={{
          setup: function (editor) {
            editor.on("init", function (e) {
              // console.log("The Editor has initialized.");
              // console.log(editor);
              editorref.current = editor;
            });
            editor.ui.registry.addButton("customButton", {
              text: "Custom Button",
              onAction: handleCustomButtonClick,
            });
          },
          height: 800,
          // plugins: ["paste", "pagebreak"],
          paste_data_images: false,
          paste_preprocess: (plugin, args) => {
            // handlePaste(args);
          },
          // toolbar: "pagebreak",
          // Other TinyMCE initialization options
          skin: "fabric",
          content_css: "fabric",
          toolbar_mode: "floating",
          // height: "80%",
          // width: "95%",
          browser_spellcheck: true,
          contextmenu: "false",
          statusbar: false,
          sidebar_show: "showcomments",
          formats: {
            hilitecolor: {
              inline: "span",
              classes: "hilitecolor",
              styles: { backgroundColor: "yellow" },
            },
          },
          mobile: {
            content_css: "default",
            toolbar: false,
            menubar: "file edit view insert format tools tc",
            menu: {
              tc: {
                title: "Assistant",
                items: "addcomment showcomments deleteallconversations",
              },
            },
            sidebar_show: "",
          },
          plugins: [
            // "tinycomments",
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          menubar: "file edit view insert format tools tc",
          menu: {
            tc: {
              title: "Assistant",
              items: "addcomment showcomments deleteallconversations",
            },
          },
          toolbar:
            "undo redo | fontsizeinput fontfamily | blocks | customButton |" +
            "bold italic forecolor backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | addcomment showcomments",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:16px; line-height: 2.5rem; background-color: white; }" +
            ".tox-comments-visible span.tox-comment  { background-color: #cef9ce59!important; }.tox-comments-visible span.tox-comment--active { background-color: black; }",
        }}
        onEditorChange={handleEditorChange}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
      />
      <div>
        <p>API Response</p>
        <p>
          {" "}
          <b>QUESTION: </b>
          {apiResponse.question}
        </p>
        <p>
          {" "}
          <b>ANSWER: </b>
          {apiResponse.answer}
        </p>
      </div>
      {/* <ErrorAlert /> */}
    </div>
  );
};

export default MyEditor;
