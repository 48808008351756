import { Fragment, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSocket } from "../contexts/SocketIoContext";
import styles from "./ChatWidget.module.css";
import TypingAnimation from "./TypingAnimation";
import convertTimestamp from "../utils/convertTimestamp";

function ChatWidget() {
  const [showChat, setShowChat] = useState(
    window.innerWidth > 576 ? false : true
  );
  const [showChatIcon, setShowChatIcon] = useState(
    window.innerWidth > 576 ? true : false
  );
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState();
  const [isBotTyping, setIsBotTyping] = useState(false);
  let [isMinimized, setIsMinimized] = useState(true);
  const inputRef = useRef();
  const messagesRef = useRef();
  const { socket, uid } = useSocket();

  function getCurrentTime() {
    const time = new Date();
    return time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  useEffect(function () {
    fetch(`https://sensi-api.siftlink.com/history/${uid}`)
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then(function (responseData) {
        setMessages(responseData);
      });
  }, []);

  socket.on("connect_error", (error) => {
    setMessages([
      ...messages,
      "There is a connection error, try to refresh or contact us through email.",
    ]);
  });

  useEffect(
    function () {
      socket.on("bot message", function (response) {
        if (response) {
          setTimeout(function () {
            setIsBotTyping(false);
            setMessages(function (previousMessage) {
              return [
                ...previousMessage,
                { message: response, timestamp: Date.now(), sendByUser: false },
              ];
            });
          }, 1000);
        } else {
          setIsBotTyping(false);
          setMessages(function (previousMessage) {
            return [
              ...previousMessage,
              "I did not understand the question, please try again or write help",
            ];
          });
        }
      });
    },
    [socket]
  );

  useEffect(() => {
    if (showChat && messagesRef.current) {
      const target = messagesRef.current;

      const scrollToBottom = () => {
        target.scroll({ top: target.scrollHeight, behavior: "instant" });
      };

      const observer = new MutationObserver(scrollToBottom);
      const config = { childList: true };
      observer.observe(target, config);
      scrollToBottom();

      return () => {
        observer.disconnect();
      };
    }
  }, [showChat, messagesRef]);

  function handleCloseButton() {
    setShowChat(!showChat);
    setShowChatIcon(!showChatIcon);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!e.target[0].value) {
      setMessages(function (previousMessage) {
        return [...previousMessage, { message: "" }];
      });
      return;
    }
    setIsBotTyping(true);
    if (currentMessage) {
      setMessages(function (previousMessage) {
        if (currentMessage.startsWith("@caroline")) {
          return [
            ...previousMessage,
            { message: currentMessage, timestamp: Date.now() },
            { message: "wait, preparing my response" },
          ];
        }
        return [
          ...previousMessage,
          { message: currentMessage, timestamp: Date.now(), sendByUser: true },
        ];
      });
      socket.emit("chat message", currentMessage);
      inputRef.current.value = "";
    }
  }

  function handleUpButtonPressed(event) {
    if (event.key === "ArrowUp") {
      let message = "";
      for (let i = messages.length - 1; i >= 0; i--) {
        console.log(messages[i]);
        if (
          "user_uttered" in messages[i] &&
          messages[i]["user_uttered"] !== ""
        ) {
          message = messages[i]["user_uttered"];
          break;
        }
      }
      inputRef.current.value = message;
    }
    if (event.key === "ArrowDown") {
      inputRef.current.value = "";
    }
  }

  function handleChange(event) {
    setCurrentMessage(event.target.value);
  }

  function handleToggleChat() {
    setShowChat(!showChat);
    setShowChatIcon(!showChatIcon);
    window.scrollTo(0, 0);
  }

  function handleIsMinimized() {
    setIsMinimized(!isMinimized);
  }

  return (
    <Fragment>
      {showChat && (
        <div
          className={`${styles.fullScreenChat} ${
            isMinimized ? styles.fullScreenChatMinimized : ""
          }`}
        >
          <div
            className={`${styles.chatContainer} ${
              isMinimized ? styles.minimizedChatBody : ""
            }`}
          >
            <header
              style={{ backgroundColor: "white" }}
              className={`${styles.chatHeader}  d-flex justify-content-between align-items-center`}
            >
              <div>
                <button
                  onClick={handleIsMinimized}
                  style={{ border: 0, backgroundColor: "inherit" }}
                >
                  {isMinimized ? (
                    <i class="bi bi-arrows-angle-expand"></i>
                  ) : (
                    <i class="bi bi-arrows-angle-contract"></i>
                  )}
                </button>
              </div>
              <div>
                <img
                  style={{ width: "50%", height: "50px" }}
                  src="/static/img/sensibot-round.png"
                  alt="Sensi"
                />
                <span style={{ marginLeft: "0.5rem" }}>Sensi</span>
              </div>
              <div>
                <button
                  style={{ border: 0, backgroundColor: "inherit" }}
                  onClick={handleCloseButton}
                >
                  <i style={{ color: "black" }} className="bi bi-x-lg"></i>
                </button>
              </div>
            </header>
            <div
              className={`${styles.chatBody} d-flex flex-column`}
              ref={messagesRef}
            >
              {messages.map(function (message, index) {
                if (message.sendByUser) {
                  if (!message.sendByUser) {
                    return (
                      <div key={index} style={{ visibility: "hidden" }}>
                        hi
                      </div>
                    );
                  }
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f1f1f1",
                          maxWidth: "70%",
                          borderRadius: "10px",
                          margin: "1rem 0.5rem 0 0.5rem",
                          padding: "5px 15px",
                        }}
                      >
                        {message.message}
                      </div>
                      <div
                        style={{ marginRight: "0.5rem", fontSize: "0.7rem" }}
                      >
                        {convertTimestamp(message.timestamp).toDateAndHour}
                      </div>
                    </div>
                  );
                } else {
                  if (message.message.startsWith("/")) {
                    return (
                      <div
                        key={index}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div
                          style={{
                            backgroundColor: "#E4E6EB",
                            maxWidth: "70%",
                            borderRadius: "10px",
                            margin: "1rem 0.5rem 0 0.5rem",
                            padding: "5px 15px",
                          }}
                        >
                          <Link onClick={handleToggleChat} to={message.message}>
                            here is your summary
                          </Link>
                        </div>
                        <div
                          style={{ marginLeft: "0.5rem", fontSize: "0.7rem" }}
                        >
                          {convertTimestamp(message.timestamp).toDateAndHour}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: message.message,
                          }}
                          style={{
                            backgroundColor: "#faf1ce",
                            maxWidth: "70%",
                            borderRadius: "10px",
                            margin: "1rem 0.5rem 0 0.5rem",
                            padding: "5px 15px",
                          }}
                        ></div>
                        <div
                          style={{ marginLeft: "0.5rem", fontSize: "0.7rem" }}
                        >
                          {convertTimestamp(message.timestamp).toDateAndHour}
                        </div>
                      </div>
                    );
                  }
                }
              })}
              {isBotTyping && <TypingAnimation />}
            </div>
            <div className={`${styles.chatFooter}`}>
              <form
                className={`${styles.chatForm} row m-0 p-1`}
                onSubmit={handleSubmit}
              >
                <input
                  className="col-11 rounded"
                  placeholder="Aa"
                  onChange={handleChange}
                  ref={inputRef}
                  onKeyDown={handleUpButtonPressed}
                />
                <button
                  className={`${styles.sendButton} col-1 p-1`}
                  type="submit"
                >
                  <i className="bi bi-send"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

      {showChatIcon && (
        <div
          onClick={handleCloseButton}
          className={`${styles.chatIconContainer}`}
        >
          <img src="/static/img/sensibot-round.png" alt="bot" />
        </div>
      )}
    </Fragment>
  );
}

export default ChatWidget;
