import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStripePayments } from "@stripe/firestore-stripe-payments";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDQjMHfwP2rACe2evTekV21whv_Kz-Zayo",
  authDomain: "sensi-02.firebaseapp.com",
  projectId: "sensi-02",
  storageBucket: "sensi-02.appspot.com",
  messagingSenderId: "536619109608",
  appId: "1:536619109608:web:59c15f8ab9ad1a8df34d5d",
  measurementId: "G-4L1KJ10967",
  databaseURL:
    "https://sensi-02-default-rtdb.europe-west1.firebasedatabase.app/",
};

export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const firestore = getFirestore(app);
export const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});
// const analytics = getAnalytics(app);
