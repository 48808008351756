// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomepageTemp_bgImage__qXyCI {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n", "",{"version":3,"sources":["webpack://./src/components/homePage/HomepageTemp.module.css"],"names":[],"mappings":"AAAA;EACE,yDAAoD;EACpD,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;AACxB","sourcesContent":[".bgImage {\n  background-image: url(\"../../assets/background.png\");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgImage": "HomepageTemp_bgImage__qXyCI"
};
export default ___CSS_LOADER_EXPORT___;
