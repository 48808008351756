import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Card, ListGroup } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import { ResponsiveLine } from "@nivo/line";
import styles from "./PatentSummaryTemp.module.css";

function PatentSummaryTemp() {
  const { publicationNumber } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(function () {
    fetch(`https://api-02.siftlink.com/patent-summary/${publicationNumber}`, {
      headers: {
        Authorization: process.env.REACT_APP_NOT_SECRET_CODE,
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (responseData) {
        if (responseData === "No summary found") {
          setData("The publication number not found");
          setIsLoading(false);
        } else {
          const chartData = [];
          for (let i = 0; i < responseData["recent trends"].length; i++) {
            chartData.push({
              x: responseData["recent trends"][i]["key"],
              y: responseData["recent trends"][i]["count"],
            });
          }

          setData({
            abstract: responseData.abstract,
            applicant: responseData.applicant,
            application_date: responseData["application date"],
            publication_date: responseData["publication date"],
            main_claim: responseData["main claim"],
            status: responseData.status,
            description: responseData.description,
            title: responseData.title,
            chartData: chartData,
          });
          setIsLoading(false);
        }
      });
  }, []);

  if (isLoading) {
    return (
      <Container
        style={{ height: "70vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div style={{ textAlign: "center" }}>
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      </Container>
    );
  } else {
    {
      if (typeof data === "string")
        return (
          <div>
            <p
              style={{ textAlign: "center", height: "70vh" }}
              className="d-flex align-items-center justify-content-center"
            >
              {data}
            </p>
          </div>
        );
    }
    return (
      <Container
        className="mt-4"
        style={{ minHeight: "100%", marginBottom: "1rem" }}
      >
        <Card className="p-4">
          <Card.Header className="text-center ps-0 fs-2">
            Patent summary for {publicationNumber}
          </Card.Header>
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Title</Card.Title>
              <Card.Text>{data.title}</Card.Text>
            </Card.Body>
          </Card>
          <Card className="">
            <Card.Body>
              <Card.Title>Abstract</Card.Title>
              <Card.Text>{data.abstract}</Card.Text>
            </Card.Body>
          </Card>
          <Card className="">
            <Card.Body>
              <Card.Title>Applicant</Card.Title>
              <Card.Text>{data.applicant}</Card.Text>
            </Card.Body>
          </Card>
          <Card className="">
            <Card.Body>
              <Card.Title>Status</Card.Title>
              <Card.Text>{data.status}</Card.Text>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Application date</Card.Title>
              <Card.Text>{data.application_date}</Card.Text>
            </Card.Body>
          </Card>
          <Card className=" mb-4">
            <Card.Body>
              <Card.Title>Publication date</Card.Title>
              <Card.Text>{data.publication_date}</Card.Text>
            </Card.Body>
          </Card>
          <Card className="mt-4 mb-4">
            <Card.Body>
              <Card.Title>Main claim</Card.Title>
              <Card.Text>{data.main_claim}</Card.Text>
            </Card.Body>
          </Card>
          <Card className="mt-4 mt-0">
            <Card.Body>
              <Card.Title>Description</Card.Title>

              <div
                className={styles.descriptionDiv}
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            </Card.Body>
          </Card>
          <div style={{ width: "100%", height: "600px", marginTop: "2rem" }}>
            <div>
              <h3>Filing trends in the innovation area</h3>
            </div>
            <ResponsiveLine
              data={[
                {
                  id: "0",
                  color: "hsl(171, 70%, 50%)",
                  data: data.chartData,
                },
              ]}
              axisBottom={{
                tickRotation: 45,
              }}
              margin={{ top: 50, right: 50, bottom: 150, left: 30 }}
              curve="cardinal"
              enablePoints={false}
            />
          </div>
        </Card>
      </Container>
    );
  }
}

export default PatentSummaryTemp;
