import React from "react";
import { data, images } from "../../constants";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import styles from "./Menu.module.css";

const Menu = () => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="ligth"
      variant="ligth"
      sticky="top"
      className={`${styles.navbar}`}
    >
      <Container>
        <Navbar.Brand href="https://siftlink.com/" target="_blank">
          <img src={images.logo} alt="logo" width="150" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="m-auto" style={{ backgroundColor: "white" }}>
            {data.Menu.map((item, index) => (
              <Nav.Link
                className={`${styles.navlink}`}
                href={item.link}
                key={index}
              >
                {item.text}
              </Nav.Link>
            ))}
          </Nav>
          <Nav style={{ backgroundColor: "white" }}>
            <Nav.Link className={`${styles.navlink}`} href="/sign-in">
              Login
            </Nav.Link>
            <Nav.Link
              className={`${styles.join} ${styles.navlink}`}
              href="/sign-up"
            >
              Join now
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
