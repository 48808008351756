import create from "../assets/secondimage.png";
import logo from "../assets/logosvgpng.png";
import work01 from "../assets/1.png";
import work02 from "../assets/2.png";
import work03 from "../assets/3.png";
import empower from "../assets/firstimage.png";
import sensi from "../assets/sensi.png";
import hero from "../assets/hero.png";

export default {
  create,
  logo,
  work01,
  work02,
  work03,
  empower,
  sensi,
  hero,
};
