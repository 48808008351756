import { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import axios from "axios";
import styles from "./SpecificAssistant.module.css";
import SessionTitle from "../Editor/SessionTitle";
import { Image } from "react-bootstrap";

const ChatBox = ({
  editorRef,
  showAlertComponent,
  uid,
  timestamp,
  label,
  setLabel,
  documentData,
}) => {
  const initialMessage = `
    <div>Hi, welcome! Here is what you can do:</div>
    <ol>
      <li>
        <b>Create a Report:</b> Ask the assistant to generate a report for you on any topic of your choice. Use the term @reporter in your request
      </li>
      <li>
        <b>Document-Specific Assistant:</b> Copy and paste a paper or document, then click on "Create Assistant." This will generate an assistant tailored to the topic of your document, gathering relevant information so you can start asking questions immediately.
      </li>
      <li>
        <b>Build an Assistant from a Document:</b> You can create an assistant from any paper or document you have.
      </li>
      <li>
        <b>Assistant from URLs:</b> Copy and paste a list of URLs into the document to build an assistant based on those sources.
      </li>
      <li>
        <b>Drafting Assistance:</b> Start drafting your document and use the assistant to help generate sections of it. Choose whether the assistant’s output goes directly into the editor or the chat by clicking the button to the left of the text input box.
      </li>
      <li>
        <b>Request Edits:</b> Select any part of your document to request changes, modifications, or additions directly in the document, or ask for explanations in the chat window.
      </li>
      <li>
        <b>Find References:</b> Highlight any part of your document and click the "Find References" button to retrieve relevant references.
      </li>

    </ol>
  `;
  const [messages, setMessages] = useState([
    { text: initialMessage, sender: "bot" },
  ]);
  const [newMessage, setNewMessage] = useState("");
  // const [label, setLabel] = useState("Chat");
  let [loading, setLoading] = useState(false);
  let [createAssistantloading, setcreateAssistantLoading] = useState(false);
  const messagesRef = useRef();
  let inputRef = useRef(null);
  const bottomRef = useRef();

  const handleClick = () => {
    setLabel(label === "Editor" ? "Chat" : "Editor");
  };

  useEffect(function setFocusOnInput() {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      bottomRef.current.scrollIntoView({
        behavior: "instant",
        block: "end",
      });
    }
  }, [messages]);

  function handleReferncizerButtonClick() {
    setLoading(true);
    const editor = editorRef.current;

    if (!editor) {
      showAlertComponent("An error occur. No editor found");
      setLoading(false);
      return;
    }

    const selectedText = editor.selection.getContent({ format: "text" });
    // const bookmark = editor.selection.getBookmark(2, true);

    // if (!selectedText) {
    //   showAlertComponent("Please highlight a text.");
    //   return;
    // }

    axios
      .post(
        `https://sensi-api.siftlink.com/editor/references/`,
        {
          highlightedText: selectedText,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          timeout: 300000, // Timeout in milliseconds
        }
      )
      .then((response) => {
        // setIsWaitAnswer(false);
        if (!response.status === 200) {
          throw new Error(
            "An error occurred. Response status: " + response.status
          );
        }
        return response.data;
      })
      .then((req2) => {
        if (req2.error) {
          showAlertComponent(req2.error);
          setLoading(false);
          return;
        }
        // editor.selection.moveToBookmark(bookmark);
        // editor.selection.setContent(`${req2.references}`);
        setLoading(false);
        setMessages(function (previousMessages) {
          return [
            ...previousMessages,
            { text: req2.references, sender: "bot" },
          ];
        });
      })
      .catch((error) => {
        setLoading(false);
        if (axios.isCancel(error)) {
          showAlertComponent("Request timed out");
        } else {
          showAlertComponent("An error occurred: " + error.message);
        }
        // setIsWaitAnswer(false);
      });
  }

  const handleMessageSend = (event) => {
    event.preventDefault();
    if (newMessage.trim() !== "") {
      setMessages(function (previousMessages) {
        return [...previousMessages, { text: newMessage, sender: "user" }];
      });
      const editor = editorRef.current;
      const editorText = editor
        .getContent({ format: "text" })
        .replaceAll("\n", "");
      const selectedText = editor.selection.getContent({ format: "text" });
      const bookmark = editor.selection.getBookmark(2, true);
      if (label === "Editor") {
        axios
          .post(
            `https://sensi-api.siftlink.com/editor/manipulate-content/`,
            {
              highlightedText: selectedText,
              userQuestion: newMessage,
              editorText: editorText,
              userId: uid,
              documentId: timestamp,
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              timeout: 300000, // Timeout in milliseconds
            }
          )
          .then((response) => {
            // setIsWaitAnswer(false);
            // setIsFormDisabled(false);
            // setShowModal(false);
            // setModalText("");
            if (!response.status === 200) {
              throw new Error(
                "An error occurred. Response status: " + response.status
              );
            }
            return response.data;
          })
          .then((req2) => {
            editor.selection.moveToBookmark(bookmark);
            // console.log("classification + answer");
            // console.log(req2);
            if (req2.error) {
              showAlertComponent(req2.error);
              return;
            }
            setNewMessage("");
            if (req2.classification === "replacement") {
              editor.selection.setContent(`${req2.answer}`);
            } else {
              editor.selection.setContent(selectedText + `${req2.answer}`);
            }
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              showAlertComponent("Request timed out");
            } else {
              showAlertComponent("An error occurred: " + error.message);
            }
            // setIsWaitAnswer(false);
            // setIsFormDisabled(false);
          });
      } else {
        axios
          .post(
            `https://sensi-api.siftlink.com/editor/manipulate-content/`,
            {
              highlightedText: selectedText,
              userQuestion: newMessage,
              editorText: editorText,
              userId: uid,
              documentId: timestamp,
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              timeout: 300000, // Timeout in milliseconds
            }
          )
          .then((response) => {
            // setIsWaitAnswer(false);
            // setIsFormDisabled(false);
            // setShowModal(false);
            // setModalText("");
            if (!response.status === 200) {
              throw new Error(
                "An error occurred. Response status: " + response.status
              );
            }
            return response.data;
          })
          .then((req2) => {
            if (req2.error) {
              showAlertComponent(req2.error);
              return;
            }

            setMessages(function (previousMessages) {
              return [
                ...previousMessages,
                { text: req2.answer, sender: "bot" },
              ];
            });
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              showAlertComponent("Request timed out");
            } else {
              showAlertComponent("An error occurred: " + error.message);
            }
            // setIsWaitAnswer(false);
            // setIsFormDisabled(false);
          });
      }
      setNewMessage("");
    }
  };

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      handleMessageSend(event);
    }
  }

  async function handleCreateAssistant(event) {
    event.preventDefault();
    const editor = editorRef.current;
    const plainText = editor.getContent({ format: "text" });
    const htmlText = editor.getContent();

    setcreateAssistantLoading(true);
    setMessages(function (previousMessages) {
      return [
        ...previousMessages,
        { text: "Please wait, building your assistant...", sender: "bot" },
      ];
    });
    setTimeout(function () {
      setMessages(function (previousMessages) {
        return [
          ...previousMessages,
          {
            text: "You can keep sending questions in the meantime",
            sender: "bot",
          },
        ];
      });
    }, 1500);
    try {
      await axios.post(
        `https://sensi-api.siftlink.com/editor/create-assistant`,
        JSON.stringify({
          plainText,
          htmlText,
          userId: uid,
          documentId: timestamp,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setcreateAssistantLoading(false);
      setTimeout(function () {
        setMessages(function (previousMessages) {
          return [
            ...previousMessages,
            {
              text: "Your assistant is now ready",
              sender: "bot",
            },
          ];
        });
      }, 1500);
    } catch (error) {
      setcreateAssistantLoading(false);
      showAlertComponent("Error creating assistant");
    }
  }

  return (
    <Container
      fluid
      className=" d-flex flex-column w-100 p-0"
      style={{ height: "98dvh" }}
    >
      {/* <div style={{ padding: "20px" }}>
        <Image width={100} height={31} src="/log-siflink-03-yk-cropped.svg" />
      </div> */}
      <Row style={{ height: "100%" }}>
        <Col className="h-100 w-100 p-0">
          <Container className="h-100 d-flex flex-column w-100">
            <Row className="flex-grow-1 overflow-auto">
              <Col style={{ width: "100%" }}>
                <div ref={messagesRef} style={{ width: "100%" }}>
                  {messages.map((message, index) => (
                    <div
                      className={
                        message.sender === "user"
                          ? styles.userMessage
                          : styles.botMessage
                      }
                    >
                      <div
                        className={
                          message.sender === "user"
                            ? styles.userMessageCh
                            : styles.botMessageCh
                        }
                      >
                        <div style={{ fontWeight: "600", fontSize: "1.2rem" }}>
                          {message.sender === "user" ? "You" : "Sensi"}
                        </div>
                        <div
                          className={
                            message.sender === "user" ? styles.user : styles.bot
                          }
                          dangerouslySetInnerHTML={{ __html: message.text }}
                        />
                      </div>
                    </div>
                  ))}
                  <div ref={bottomRef} />
                </div>
              </Col>
            </Row>
            <Row className="mt-auto">
              <Col>
                <Form>
                  <Form.Group
                    controlId="formMessage"
                    className={`d-flex align-items-center pt-1 ${styles.formGroup}`}
                  >
                    <InputGroup>
                      <Button
                        className={
                          label === "Chat"
                            ? styles.lavenderModeButtonv1
                            : styles.greenModeButtonv1
                        }
                        variant="outline-secondary"
                        onClick={handleClick}
                      >
                        {label}
                      </Button>
                      <Form.Control
                        className={styles.inputChat}
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        type="text"
                        placeholder="Type your message here..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={handleKeyPress}
                        ref={inputRef}
                      />
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={handleMessageSend}
                        style={{
                          border: "1px solid",
                          borderColor: "#dfdddd #dfdddd #dfdddd white",
                          borderRadius: "0 14px 14px 0",
                        }}
                      >
                        <i
                          className={`bi bi-send ${styles.sendIcon}`}
                          style={{ color: "black", fontSize: "1.5rem" }}
                        ></i>
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Form>
                <div className="d-flex align-items-center p-2">
                  <Button
                    className="me-2"
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleCreateAssistant}
                  >
                    Create assistant
                    {createAssistantloading && (
                      <span
                        class="ms-1 spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </Button>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={handleReferncizerButtonClick}
                  >
                    Find references
                    {loading && (
                      <span
                        class="ms-1 spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default ChatBox;
