import { useState, useEffect, Fragment } from "react";

import { ResponsivePie } from "@nivo/pie";
import { ThreeDots } from "react-loader-spinner";

function PieWithContent({ apiData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(function () {
    fetch(`https://api-02.siftlink.com/${apiData.url}`, {
      headers: {
        Authorization: process.env.REACT_APP_NOT_SECRET_CODE,
      },
    })
      .then(function (response) {
        if (response.status == 200) {
          return response.json();
        }
      })
      .then(function (responseData) {
        if (responseData) {
          setData(constructData(responseData));
        }
        setIsLoading(false);
      });
  }, []);

  function constructData(responseData) {
    const chartData = [];
    for (let i = 0; i < responseData.data[`${apiData.xAxis}`].length; i++) {
      chartData.push({
        id: responseData.data[`${apiData.xAxis}`][i],
        label: responseData.data[`${apiData.xAxis}`][i],
        value: responseData.data[`${apiData.yAxis}`][i],
      });
    }
    return {
      chartData,
      title: responseData.data["slide_title"],
      paragraph_title: responseData.data["paragraph_title"],
      html_text: responseData.data["html_text"],
    };
  }

  if (isLoading) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "70vh", textAlign: "center" }}
      >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    );
  } else {
    if (Object.keys(data).length) {
      return (
        <Fragment>
          <div className="row" style={{ height: "10%" }}>
            <h1 className="text-capitalize mt-4 ms-2">{data.title}</h1>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-between">
            <div className="col-11 mt-5" style={{ height: "40vh" }}>
              <ResponsivePie
                data={data.chartData}
                margin={{ top: 30, right: 80, bottom: 0, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.2]],
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: "color",
                  modifiers: [["darker", 2]],
                }}
              />
            </div>
            <div className="col-10 ">
              <p className="text-danger">{data.paragraph_title}</p>
              <div dangerouslySetInnerHTML={{ __html: data.html_text }} />
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="row" style={{ height: "10%" }}>
            <h1 className="text-capitalize mt-4 ms-2">{data.title}</h1>
          </div>

          <div className="d-flex align-items-center justify-content-center h-75">
            <p className="mb-4 fs-3">No data found</p>
          </div>
        </Fragment>
      );
    }
  }
}

export default PieWithContent;
