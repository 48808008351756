import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
// import Footer from "./Footer";
import Footer from "./NewHomePage/footer/Footer";
import ChatWidget from "./ChatWidget";
import { SocketIoProvider } from "../contexts/SocketIoContext";

function NavAndFooter() {
  return (
    <Fragment>
      <NavBar />
      <SocketIoProvider>
        <ChatWidget />
      </SocketIoProvider>
      <Outlet />
      <Footer />
    </Fragment>
  );
}

export default NavAndFooter;
